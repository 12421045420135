import React, { useEffect, useState } from 'react';
import './DGVMoneyLos.css';
import DGV_Money_Los_Image_Desk from '../../../../assets/images/DGVMoneyLosImageDesk.png';
import DGV_Money_Los_Image_Mob from '../../../../assets/images/DGVMoneyLosImageMob.png';
import DGV_Money_Los_Logo from '../../../../assets/images/DGVMoneyLosLogo.png';
import DGV_Money_Los_feature_1 from '../../../../assets/images/money-los-feature-1.png';
import DGV_Money_Los_feature_2 from '../../../../assets/images/money-los-feature-2.png';
import DGV_Money_Los_feature_3 from '../../../../assets/images/money-los-feature-3.png';
import DGV_Money_Los_feature_mob_1 from '../../../../assets/images/money-los-feature-mob-1.png';
import DGV_Money_Los_feature_mob_2 from '../../../../assets/images/money-los-feature-mob-2.png';
import DGV_Money_Los_feature_mob_3 from '../../../../assets/images/money-los-feature-mob-3.png';
import { useInView } from 'react-intersection-observer';

function DGVMoneyLos() {
  const [desktopView, setDesktopView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  return (
    <div className="money-los-main-container">
      <div className="money-los-image-box-section">
        <div className="money-los-logo animate__animated animate__fadeIn">
          <img src={DGV_Money_Los_Logo} alt="DGV_Money_Los-logo" />
        </div>
        <div className="money-los-image animate__animated animate__fadeIn animate__delay-1s">
          <img src={desktopView ? DGV_Money_Los_Image_Desk : DGV_Money_Los_Image_Mob} alt="imge" />
        </div>
      </div>
      <div ref={ref} className="money-los-feature-section">
        <div className="money-los-feature-text">Features</div>
        <div className="money-los-feature-list">
          <div className={inView ? 'money-los-feature animate__animated animate__fadeInLeft' : 'money-los-feature'}>
            <img src={desktopView ? DGV_Money_Los_feature_1 : DGV_Money_Los_feature_mob_1} alt="feature-1" />
          </div>
          <div
            className={
              inView
                ? 'money-los-feature animate__animated animate__fadeInRight animate__delay-1s'
                : 'money-los-feature'
            }
          >
            <img src={desktopView ? DGV_Money_Los_feature_2 : DGV_Money_Los_feature_mob_2} alt="feature-2" />
          </div>
          <div
            className={
              inView ? 'money-los-feature animate__animated animate__fadeInLeft animate__delay-2s' : 'money-los-feature'
            }
          >
            <img src={desktopView ? DGV_Money_Los_feature_3 : DGV_Money_Los_feature_mob_3} alt="feature-3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DGVMoneyLos;
