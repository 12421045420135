import React, { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { ROUTES_BREADCRUMB, ROUTES_PATH } from '../../utils/constants';
import './BreadCrumb.css';

const BreadCrumb = () => {
  let location = useLocation();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [searchParams] = useSearchParams();
  const activeState = searchParams.get('activeState');
  useEffect(() => {
    let links = [
      {
        name: 'Home Page',
        linkTo: ROUTES_PATH.HOME,
      },
    ];

    const paths = ROUTES_BREADCRUMB[location.pathname.substring(1).replace(/-/g, '')];
    if (paths) {
      links = [...links, ...paths];
    }
    if (activeState !== null) {
      setBreadCrumbs([
        {
          name: 'Home Page',
          linkTo: '/',
        },
        {
          name: 'Our Network',
          className: 'menu-text',
          linkTo: '/our-network',
        },
        {
          name: 'Statewise',
        },
      ]);
    } else {
      setBreadCrumbs(links);
    }
  }, [location, activeState]);

  return (
    <div className="breadCrumb">
      {breadCrumbs.map((breadCrumb, index) =>
        breadCrumb.linkTo ? (
          <Link className="text-link" to={breadCrumb.linkTo} key={breadCrumb.name}>
            {breadCrumb.name}
            {index !== breadCrumbs.length - 1 && <span>\</span>}
          </Link>
        ) : (
          <div className={`text-link ${breadCrumb.className}`} key={breadCrumb.name}>
            {breadCrumb.name}
            {index !== breadCrumbs.length - 1 && <span>\</span>}
          </div>
        )
      )}
    </div>
  );
};

export default BreadCrumb;
