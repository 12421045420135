export const geoData = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        st_nm: 'Andaman and Nicobar Islands',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [93.84830850762702, 7.240277614583517],
              [93.9270514776929, 7.028074224242808],
              [93.8348097127586, 6.757042171233387],
              [93.65482578117948, 7.126822336579574],
              [93.84830850762702, 7.240277614583517],
            ],
          ],
          [
            [
              [93.7133205589427, 7.444076910257268],
              [93.75606674269274, 7.374743129254858],
              [93.63007799058735, 7.269691945917873],
              [93.7133205589427, 7.444076910257268],
            ],
          ],
          [
            [
              [93.38260008466608, 8.023959442277423],
              [93.37810048637661, 7.883190856605864],
              [93.31060651203444, 7.963029755941973],
              [93.38260008466608, 8.023959442277423],
            ],
          ],
          [
            [
              [93.52658722992938, 8.231960785284654],
              [93.50858883677145, 8.034464560611122],
              [93.45459365729772, 8.173132122615943],
              [93.52658722992938, 8.231960785284654],
            ],
          ],
          [
            [
              [92.79540250788924, 9.238351121652968],
              [92.83139929420506, 9.16481529331708],
              [92.73240813183654, 9.122794819982285],
              [92.7234089352576, 9.21944190865231],
              [92.79540250788924, 9.238351121652968],
            ],
          ],
          [
            [
              [92.51867721308635, 10.898159818377328],
              [92.59742018315221, 10.700663593703798],
              [92.4984290207837, 10.511571463697225],
              [92.41968605071784, 10.5472888660318],
              [92.38143946525727, 10.763694303705988],
              [92.51867721308635, 10.898159818377328],
            ],
          ],
          [
            [
              [92.69866114466546, 12.238612917757255],
              [92.75715592242867, 12.177683231421803],
              [92.73690773012602, 12.078935119085038],
              [92.77290451644184, 12.047419764083942],
              [92.71665953782338, 11.946570628080437],
              [92.78415351216555, 11.936065509746738],
              [92.72790853354707, 11.520062823732278],
              [92.64691576433647, 11.522163847399018],
              [92.51417761479686, 11.847822515743673],
              [92.60641937973116, 11.9234593677463],
              [92.69866114466546, 12.238612917757255],
            ],
          ],
          [
            [
              [92.82464989677084, 12.303744651426186],
              [92.88089487538932, 12.289037485759007],
              [92.86964587966561, 12.1986934680892],
              [92.76390531986289, 12.06422795341786],
              [92.76840491815236, 12.230208823090297],
              [92.82464989677084, 12.303744651426186],
            ],
          ],
          [
            [
              [92.89889326854723, 12.915142538447437],
              [92.96863704203412, 12.740757574108041],
              [92.94838884973147, 12.463422450098403],
              [92.88539447367879, 12.324754888093583],
              [92.75490612328393, 12.295340556759227],
              [92.7009109438102, 12.33315898276054],
              [92.7234089352576, 12.82689954444437],
              [92.89889326854723, 12.915142538447437],
            ],
          ],
          [
            [
              [92.69641134552072, 12.990779390450065],
              [92.71890933696811, 12.873122065112643],
              [92.66491415749438, 12.881526159779602],
              [92.69641134552072, 12.990779390450065],
            ],
          ],
          [
            [
              [93.02938161894207, 13.570661922470222],
              [93.06087880696842, 13.362660579462993],
              [92.95963784545518, 13.358458532129514],
              [93.04513021295526, 13.276518609126665],
              [93.03388121723155, 13.081123408119872],
              [92.80215190532346, 12.900435372780258],
              [92.87189567881035, 13.471913810133458],
              [93.02938161894207, 13.570661922470222],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Andaman and Nicobar Islands',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [93.84830850762702, 7.240277614583517],
              [93.9270514776929, 7.028074224242808],
              [93.8348097127586, 6.757042171233387],
              [93.65482578117948, 7.126822336579574],
              [93.84830850762702, 7.240277614583517],
            ],
          ],
          [
            [
              [93.7133205589427, 7.444076910257268],
              [93.75606674269274, 7.374743129254858],
              [93.63007799058735, 7.269691945917873],
              [93.7133205589427, 7.444076910257268],
            ],
          ],
          [
            [
              [93.38260008466608, 8.023959442277423],
              [93.37810048637661, 7.883190856605864],
              [93.31060651203444, 7.963029755941973],
              [93.38260008466608, 8.023959442277423],
            ],
          ],
          [
            [
              [93.52658722992938, 8.231960785284654],
              [93.50858883677145, 8.034464560611122],
              [93.45459365729772, 8.173132122615943],
              [93.52658722992938, 8.231960785284654],
            ],
          ],
          [
            [
              [92.79540250788924, 9.238351121652968],
              [92.83139929420506, 9.16481529331708],
              [92.73240813183654, 9.122794819982285],
              [92.7234089352576, 9.21944190865231],
              [92.79540250788924, 9.238351121652968],
            ],
          ],
          [
            [
              [92.51867721308635, 10.898159818377328],
              [92.59742018315221, 10.700663593703798],
              [92.4984290207837, 10.511571463697225],
              [92.41968605071784, 10.5472888660318],
              [92.38143946525727, 10.763694303705988],
              [92.51867721308635, 10.898159818377328],
            ],
          ],
          [
            [
              [92.69866114466546, 12.238612917757255],
              [92.75715592242867, 12.177683231421803],
              [92.73690773012602, 12.078935119085038],
              [92.77290451644184, 12.047419764083942],
              [92.71665953782338, 11.946570628080437],
              [92.78415351216555, 11.936065509746738],
              [92.72790853354707, 11.520062823732278],
              [92.64691576433647, 11.522163847399018],
              [92.51417761479686, 11.847822515743673],
              [92.60641937973116, 11.9234593677463],
              [92.69866114466546, 12.238612917757255],
            ],
          ],
          [
            [
              [92.82464989677084, 12.303744651426186],
              [92.88089487538932, 12.289037485759007],
              [92.86964587966561, 12.1986934680892],
              [92.76390531986289, 12.06422795341786],
              [92.76840491815236, 12.230208823090297],
              [92.82464989677084, 12.303744651426186],
            ],
          ],
          [
            [
              [92.89889326854723, 12.915142538447437],
              [92.96863704203412, 12.740757574108041],
              [92.94838884973147, 12.463422450098403],
              [92.88539447367879, 12.324754888093583],
              [92.75490612328393, 12.295340556759227],
              [92.7009109438102, 12.33315898276054],
              [92.7234089352576, 12.82689954444437],
              [92.89889326854723, 12.915142538447437],
            ],
          ],
          [
            [
              [92.69641134552072, 12.990779390450065],
              [92.71890933696811, 12.873122065112643],
              [92.66491415749438, 12.881526159779602],
              [92.69641134552072, 12.990779390450065],
            ],
          ],
          [
            [
              [93.02938161894207, 13.570661922470222],
              [93.06087880696842, 13.362660579462993],
              [92.95963784545518, 13.358458532129514],
              [93.04513021295526, 13.276518609126665],
              [93.03388121723155, 13.081123408119872],
              [92.80215190532346, 12.900435372780258],
              [92.87189567881035, 13.471913810133458],
              [93.02938161894207, 13.570661922470222],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Arunachal Pradesh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [95.23643457993093, 26.681049602925928],
            [95.19593819532562, 27.036122602604937],
            [95.45691489611534, 27.128567643941484],
            [95.5356578661812, 27.263033158612824],
            [95.58965304565493, 27.22311370894477],
            [96.01486508401058, 27.36808434194981],
            [95.88212693447099, 27.43741812295222],
            [95.75838798151035, 27.72525836529556],
            [95.97886829769476, 27.962674039637143],
            [95.60765143881284, 27.952168921303446],
            [95.51765947302329, 27.876532069300815],
            [94.88546591335167, 27.737864507295996],
            [94.50300005874605, 27.5739846612903],
            [94.29151893914059, 27.59079285062422],
            [93.67732377262688, 26.968889845269267],
            [93.02263222150786, 26.914263229934036],
            [92.66266435834964, 27.036122602604937],
            [92.58842098657325, 26.96048575060231],
            [92.1114635678886, 26.89325299326664],
            [92.03272059782275, 27.07814307593973],
            [92.07321698242805, 27.275639300613264],
            [92.02147160209906, 27.458428359619617],
            [91.65925393979609, 27.481539619953754],
            [91.5670121748618, 27.584489779623997],
            [91.67500253380926, 27.855521832633418],
            [91.83248847394098, 27.807198288298405],
            [91.88423385426998, 27.721056317962077],
            [92.25995031144137, 27.86182490363364],
            [92.31169569177037, 27.77778395696405],
            [92.56592299512586, 27.819804430298845],
            [92.74365712756024, 27.98788632363802],
            [92.68516234979703, 28.11604876730914],
            [93.02713181979733, 28.298837826315495],
            [93.1981165547975, 28.317747039316153],
            [93.3376041017713, 28.632900589327107],
            [93.94055027256132, 28.67702208632864],
            [94.2217751656537, 29.084620677676142],
            [94.43775588354862, 29.22749028701444],
            [94.563744635654, 29.22959131068118],
            [94.63348840914091, 29.347248636018602],
            [94.80447314414106, 29.16445957701225],
            [95.0092048663123, 29.16866162434573],
            [95.45916469526009, 29.03419610967439],
            [95.54915666104964, 29.214884145014004],
            [96.08460885749749, 29.460703914022545],
            [96.23759519933974, 29.24219745268162],
            [96.3973309386162, 29.252702571015316],
            [96.15435263098439, 29.048903275341566],
            [96.23084580190552, 28.927043902670665],
            [96.44007712236623, 29.03419610967439],
            [96.6110618573664, 28.792578387999324],
            [96.588563865919, 28.716941535996693],
            [96.47382410953732, 28.67912310999538],
            [96.37258314802406, 28.389181843985302],
            [96.66730683598486, 28.46061664865445],
            [96.77529719493234, 28.368171607317905],
            [96.89903614789297, 28.378676725651605],
            [96.99802731026148, 28.30934294464919],
            [97.12851566065633, 28.349262394317247],
            [97.37599356657762, 28.216897903312645],
            [97.37824336572235, 27.908047424301913],
            [97.25675421190645, 27.90174435330169],
            [96.89678634874824, 27.611803087291616],
            [96.91028514361666, 27.452125288619396],
            [97.16901204526164, 27.126466620274744],
            [97.06102168631418, 27.10125433627387],
            [96.86528916072189, 27.189497330276936],
            [96.8022947846692, 27.344973081615674],
            [96.23309560105025, 27.277740324280003],
            [96.03511327631324, 27.189497330276936],
            [95.95637030624738, 27.061334886605813],
            [95.54690686190489, 26.830222283264447],
            [95.42316790894427, 26.68945369759289],
            [95.23643457993093, 26.681049602925928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Assam',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [95.19593819532562, 27.036122602604937],
            [95.08794783637816, 26.94577858493513],
            [94.8877157124964, 26.926869371934472],
            [94.80222334499632, 26.800807951930093],
            [94.41075829381175, 26.611715821923518],
            [94.32526592631167, 26.475149283585438],
            [94.28251974256165, 26.559190230255027],
            [94.01254384519297, 26.18100597024188],
            [93.98104665716663, 25.9225800592329],
            [93.78081453328487, 25.84274115989679],
            [93.78531413157434, 25.954095414233993],
            [93.68857276835057, 25.90577186989898],
            [93.70657116150848, 25.849044230897007],
            [93.33310450348182, 25.546496822886493],
            [93.45684345644247, 25.437243592216028],
            [93.47484184960038, 25.304879101211426],
            [93.245362336837, 25.000230669534172],
            [93.19136715736327, 24.80273444486064],
            [93.11262418729741, 24.79853239752716],
            [93.09687559328424, 24.58002593618623],
            [93.00013423006047, 24.399337900846618],
            [92.83139929420506, 24.39723687717988],
            [92.76390531986289, 24.51909624985078],
            [92.61091897802063, 24.250165220508098],
            [92.46243223446788, 24.134608918837415],
            [92.42193584986258, 24.250165220508098],
            [92.29594709775719, 24.250165220508098],
            [92.21270452940186, 24.24806419684136],
            [92.27344910630981, 24.38673175884618],
            [92.2329527217045, 24.502288060516864],
            [92.25095111486242, 24.907785628197626],
            [92.4984290207837, 24.86786617852957],
            [92.43993424302049, 25.031746024535266],
            [92.79315270874449, 25.28176784087729],
            [92.6086691788759, 25.41623335554863],
            [92.58842098657325, 25.55069887021997],
            [92.4174362515731, 25.737689976559803],
            [92.17220814479656, 25.664154148223915],
            [92.16770854650709, 25.96249950890095],
            [92.25770051229664, 26.027631242569882],
            [92.17895754223078, 26.078055810571637],
            [91.88648365341471, 26.027631242569882],
            [91.8212394782173, 26.11797526023969],
            [91.72224831584879, 25.954095414233993],
            [91.63225635005922, 25.943590295900297],
            [91.63450614920396, 26.021328171569664],
            [91.57376157229601, 26.025530218903143],
            [91.47702020907224, 25.85534730189723],
            [91.47252061078277, 25.859549349230708],
            [91.33528286295369, 25.832236041563092],
            [91.22729250400623, 25.722982810892628],
            [91.15529893137457, 25.849044230897007],
            [91.0698065638745, 25.82383194689613],
            [90.88532303400592, 25.949893366900515],
            [90.74358568788736, 25.91417596456594],
            [90.48035918795291, 26.015025100569446],
            [90.11814152564995, 25.958297461567472],
            [89.89766120946554, 25.727184858226106],
            [90.00565156841301, 25.58431524888781],
            [89.88866201288658, 25.559102964886932],
            [89.8549150257155, 25.477163041884083],
            [89.87066361972867, 25.97510565090139],
            [89.72217687617591, 26.1662988045747],
            [89.72217687617591, 26.25874384591125],
            [89.85941462400498, 26.38270424224889],
            [89.86166442314972, 26.38480526591563],
            [89.86166442314972, 26.704160863260064],
            [90.19463469657107, 26.769292596928995],
            [90.23288128203163, 26.857535590932063],
            [90.41511501275548, 26.901657087933597],
            [90.65359372209781, 26.773494644262474],
            [91.33978246124317, 26.779797715262696],
            [91.41177603387482, 26.834424330597926],
            [91.73124751242773, 26.81131307026379],
            [91.89998244828315, 26.914263229934036],
            [92.05746838841488, 26.847030472598366],
            [92.1114635678886, 26.89325299326664],
            [92.58842098657325, 26.96048575060231],
            [92.66266435834964, 27.036122602604937],
            [93.02263222150786, 26.914263229934036],
            [93.67732377262688, 26.968889845269267],
            [94.29151893914059, 27.59079285062422],
            [94.50300005874605, 27.5739846612903],
            [94.88546591335167, 27.737864507295996],
            [95.51765947302329, 27.876532069300815],
            [95.60765143881284, 27.952168921303446],
            [95.97886829769476, 27.962674039637143],
            [95.75838798151035, 27.72525836529556],
            [95.88212693447099, 27.43741812295222],
            [96.01486508401058, 27.36808434194981],
            [95.58965304565493, 27.22311370894477],
            [95.5356578661812, 27.263033158612824],
            [95.45691489611534, 27.128567643941484],
            [95.19593819532562, 27.036122602604937],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Bihar',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.1135704876876, 26.54028101725437],
            [88.28005562439827, 26.376401171248673],
            [87.84809418860841, 26.0444394319038],
            [87.8300957954505, 25.872155491231144],
            [88.05507570992438, 25.68726540855805],
            [88.032577718477, 25.55279989388671],
            [87.79184920998993, 25.449849734216468],
            [87.78734961170046, 25.338495479879263],
            [87.85709338518737, 25.29857603021121],
            [87.79409900913467, 25.22083815454184],
            [87.57361869295026, 25.334293432545785],
            [87.47912712887123, 25.241848391209235],
            [87.33064038531846, 25.22293917820858],
            [87.29689339814738, 25.09057468720398],
            [87.1821536417657, 25.063261379536364],
            [87.0494154922261, 24.626248456854505],
            [86.94142513327864, 24.630450504187984],
            [86.86043236406803, 24.550611604851877],
            [86.79743798801535, 24.615743338520808],
            [86.67369903505471, 24.561116723185574],
            [86.60620506071254, 24.60103617285363],
            [86.46896731288346, 24.37832766417922],
            [86.30023237702805, 24.47707577651599],
            [86.30023237702805, 24.58632900718645],
            [86.13149744117263, 24.61364231485407],
            [86.05500427025152, 24.777522160859764],
            [85.95826290702774, 24.73129964019149],
            [85.8862693343961, 24.8006334211939],
            [85.78502837288285, 24.79853239752716],
            [85.66803881735643, 24.674572001189517],
            [85.6725384156459, 24.58212695985297],
            [84.90985650557941, 24.372024593179002],
            [84.82661393722408, 24.525399320851],
            [84.66687819794763, 24.3930348298464],
            [84.5791360313028, 24.411944042847058],
            [84.50039306123693, 24.285882622842674],
            [84.2664139501841, 24.538005462851437],
            [84.11792720663132, 24.481277823849467],
            [84.0009376511049, 24.636753575188205],
            [83.87494889899952, 24.53170239185122],
            [83.86594970242058, 24.535904439184698],
            [83.78945653149945, 24.52329829718426],
            [83.75570954432837, 24.508591131517083],
            [83.50598183926235, 24.525399320851],
            [83.526230031565, 24.548510581185138],
            [83.526230031565, 24.552712628518616],
            [83.47448465123601, 24.73340066385823],
            [83.46998505294653, 24.73340066385823],
            [83.39574168117014, 24.783825231859982],
            [83.32824770682798, 25.01283681153461],
            [83.35749509570958, 25.1977268942077],
            [83.78945653149945, 25.397324142547973],
            [84.08643001860499, 25.63473981688956],
            [84.07743082202603, 25.69356847955827],
            [84.32715852709205, 25.670457219224133],
            [84.387903104, 25.733487929226325],
            [84.40140189886843, 25.727184858226106],
            [84.52514085182906, 25.676760290224355],
            [84.63088141163179, 25.790215568228298],
            [84.05718262972337, 26.09696502357229],
            [84.00993684768386, 26.1873090412421],
            [84.16517298867085, 26.25874384591125],
            [84.18092158268402, 26.372199123915195],
            [83.91094568531535, 26.447835975917823],
            [83.91544528360483, 26.504563614919793],
            [84.16067339038136, 26.636928105924394],
            [84.42389989031582, 26.624321963923958],
            [84.23941636044722, 26.86593968559902],
            [84.06168222801286, 26.889050945933157],
            [83.95144206992065, 27.115961501941047],
            [83.99418825367069, 27.178992211943235],
            [83.86594970242058, 27.344973081615674],
            [83.84570151011792, 27.4416201702857],
            [84.02568544169704, 27.43531709928548],
            [84.11567740748659, 27.521459069621805],
            [84.27766294590779, 27.397498673284165],
            [84.62638181334232, 27.336568986948713],
            [84.69162598853974, 27.22311370894477],
            [84.64663000564497, 27.046627720938634],
            [84.96160188590841, 26.96258677426905],
            [85.03359545854005, 26.87434378026598],
            [85.19558099696125, 26.868040709265763],
            [85.2090797918297, 26.760888502262038],
            [85.3373183430798, 26.74197928926138],
            [85.60954403959322, 26.857535590932063],
            [85.73553279169859, 26.79450488092987],
            [85.74228218913281, 26.645332200591355],
            [86.34522835992283, 26.618018892923736],
            [86.73444361196266, 26.424724715583686],
            [87.01566850505502, 26.53397794625415],
            [87.34188938104217, 26.349087863581058],
            [87.38913516308168, 26.418421644583468],
            [87.76710141939782, 26.407916526249767],
            [87.79634880827942, 26.46884621258522],
            [88.0100797270296, 26.355390934581276],
            [88.1135704876876, 26.54028101725437],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Chandigarh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [76.84207677254575, 30.76123756373442],
            [76.83757717425628, 30.725520161399846],
            [76.84207677254575, 30.70240890106571],
            [76.83082777682206, 30.68770173539853],
            [76.8330775759668, 30.681398664398312],
            [76.7588342041904, 30.68770173539853],
            [76.74533540932198, 30.69400480639875],
            [76.74308561017725, 30.69400480639875],
            [76.73858601188776, 30.700307877398966],
            [76.73858601188776, 30.706610948399188],
            [76.72733701616407, 30.708711972065927],
            [76.72733701616407, 30.710812995732667],
            [76.7295868153088, 30.717116066732885],
            [76.70483902471668, 30.75703551640094],
            [76.73183661445356, 30.765439611067897],
            [76.74308561017725, 30.778045753068337],
            [76.7520848067562, 30.782247800401816],
            [76.75433460590094, 30.786449847735295],
            [76.7588342041904, 30.790651895068773],
            [76.78808159307202, 30.784348824068555],
            [76.79033139221676, 30.780146776735076],
            [76.78583179392729, 30.780146776735076],
            [76.80158038794045, 30.778045753068337],
            [76.81057958451942, 30.782247800401816],
            [76.80607998622993, 30.763338587401158],
            [76.82632817853258, 30.763338587401158],
            [76.84207677254575, 30.76123756373442],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Chhattisgarh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [83.94694247163117, 23.62195914415293],
            [83.95594166821013, 23.62406016781967],
            [84.00768704853913, 23.63246426248663],
            [84.01443644597335, 23.63246426248663],
            [83.97394006136804, 23.376139375144387],
            [84.07743082202603, 23.332017878142853],
            [84.03693443742073, 23.155531890136718],
            [84.22366776643406, 22.976944878463843],
            [84.3766541082763, 22.976944878463843],
            [84.38340350571052, 22.87609574246034],
            [84.00993684768386, 22.52312376644807],
            [84.00768704853913, 22.37185006244281],
            [83.65221878367038, 22.226879429437773],
            [83.56447661702556, 22.10291903310013],
            [83.54197862557817, 21.96635249476205],
            [83.6072228007756, 21.913826903093558],
            [83.3867424845912, 21.615481542416518],
            [83.38224288630171, 21.342348465740358],
            [83.27650232649898, 21.378065868074934],
            [83.20000915557786, 21.14275121740009],
            [82.64430876682735, 21.153256335733786],
            [82.56106619847202, 20.945254992726557],
            [82.3405858822876, 20.84440585672305],
            [82.4035802583403, 20.05231993436219],
            [82.71855213860374, 19.987188200693254],
            [82.7050533437353, 19.83171244935452],
            [82.48232322840616, 19.905248277690408],
            [82.34733527972182, 19.835914496687998],
            [82.2708421088007, 19.976683082359557],
            [82.02336420287942, 20.02500662669457],
            [81.94012163452408, 20.10484552603068],
            [81.86812806189243, 20.02710765036131],
            [81.8658782627477, 19.90314725402367],
            [82.05936098919524, 19.795995047019943],
            [82.05486139090577, 19.54387220701118],
            [82.1786003438664, 19.434618976340715],
            [82.24609431820858, 18.91776715432275],
            [82.1786003438664, 18.898857941322092],
            [82.15385255327428, 18.78120061598467],
            [81.96261962597147, 18.686654550981384],
            [81.95587022853725, 18.564795178310483],
            [81.75338830551075, 18.35259178796977],
            [81.53515778847108, 18.266449817633443],
            [81.39342044235254, 17.81472972928441],
            [81.10544615182596, 17.823133823951366],
            [81.09869675439174, 17.818931776617887],
            [81.06494976722065, 17.789517445283533],
            [81.05370077149696, 17.791618468950272],
            [80.98170719886531, 18.16560068162994],
            [80.98170719886531, 18.174004776296897],
            [80.74322848952299, 18.178206823630376],
            [80.79272407070725, 18.24964162829953],
            [80.72972969465457, 18.367298953636947],
            [80.7319794937993, 18.37360202463717],
            [80.639737728865, 18.524875728642428],
            [80.49575058360172, 18.63202793564615],
            [80.34951363919369, 18.592108485978095],
            [80.2752702674173, 18.722371953315957],
            [80.35401323748316, 18.814816994652503],
            [80.2752702674173, 18.991302982658638],
            [80.39450962208846, 19.245526846334144],
            [80.56549435708862, 19.40100259767288],
            [80.6104903399834, 19.31486062733655],
            [80.75672728439142, 19.289648343335678],
            [80.84671925018098, 19.358982124338084],
            [80.79497386985199, 19.430416929007237],
            [80.89846463050998, 19.468235355008552],
            [80.66898511774662, 19.61320598801359],
            [80.67348471603609, 19.69514591101644],
            [80.54749596393071, 19.777085834019285],
            [80.49575058360172, 19.896844183023447],
            [80.5294975707728, 20.125855762698073],
            [80.390010023799, 20.2435130880355],
            [80.62398913485183, 20.327554034705088],
            [80.54299636564123, 20.932648850726117],
            [80.4665031947201, 20.92844680339264],
            [80.43050640840428, 21.010386726395488],
            [80.46425339557537, 21.172165548734444],
            [80.64648712629922, 21.25410547173729],
            [80.66448551945713, 21.33184334740666],
            [80.73647909208877, 21.47261193307822],
            [80.71398110064139, 21.67220918141849],
            [80.83772005360203, 21.804573672423093],
            [80.83996985274676, 21.945342258094655],
            [80.92321242110211, 22.119727222434047],
            [81.00645498945744, 22.060898559765334],
            [81.12119474583912, 22.294112186773443],
            [81.11669514754965, 22.43908281977848],
            [81.19318831847077, 22.487406364113497],
            [81.19993771590498, 22.472699198446314],
            [81.33042606629984, 22.52522479011481],
            [81.48341240814209, 22.491608411446975],
            [81.48791200643157, 22.493709435113715],
            [81.7736364978134, 22.676498494120068],
            [81.77813609610288, 22.873994718793597],
            [81.94912083110304, 22.955934641796446],
            [81.9468710319583, 23.07779401446735],
            [82.15835215156375, 23.14082472446954],
            [82.19434893787958, 23.321512759809153],
            [81.98286781827412, 23.4139578011457],
            [81.98061801912938, 23.41815984847918],
            [81.92437304051091, 23.529514102816385],
            [81.73763971149758, 23.56313048148422],
            [81.61390075853694, 23.50850386614899],
            [81.62065015597116, 23.659777570154244],
            [81.69489352774754, 23.718606232822957],
            [81.60715136110272, 23.853071747494297],
            [81.67014573715541, 23.922405528496707],
            [81.81863248070817, 23.806849226826024],
            [81.95812002768199, 23.850970723827558],
            [81.96936902340569, 23.855172771161037],
            [82.55206700189306, 23.792142061158845],
            [82.81529350182751, 23.962324978164762],
            [82.96153044623554, 23.871980960494955],
            [83.13476498038044, 23.88878914982887],
            [83.33049750597272, 24.10099254016958],
            [83.51723083498604, 24.025355688166954],
            [83.78270713406523, 23.600948907485535],
            [83.94244287334169, 23.56313048148422],
            [83.94694247163117, 23.62195914415293],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Dadra and Nagar Haveli',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [73.20640135464771, 20.121653715364594],
            [73.20865115379245, 20.10694654969742],
            [73.20415155550296, 20.0901383603635],
            [73.1659049700424, 20.058623005362406],
            [73.15465597431871, 20.073330171029582],
            [73.13440778201605, 20.07753221836306],
            [73.1186591880029, 20.07753221836306],
            [73.10741019227919, 20.0796332420298],
            [73.09391139741076, 20.08803733669676],
            [73.07816280339759, 20.094340407696983],
            [73.04216601708177, 20.067027100029364],
            [73.02866722221333, 20.07753221836306],
            [73.00841902991068, 20.10484552603068],
            [73.0039194316212, 20.125855762698073],
            [73.00616923076595, 20.136360881031774],
            [72.99941983333173, 20.138461904698513],
            [72.99492023504224, 20.121653715364594],
            [72.99267043589751, 20.119552691697855],
            [72.96117324787116, 20.130057810031555],
            [72.9746720427396, 20.15737111769917],
            [72.9746720427396, 20.17628033069983],
            [72.96567284616064, 20.180482378033307],
            [72.95892344872642, 20.21409875670114],
            [72.96342304701591, 20.21619978036788],
            [72.96342304701591, 20.22670489870158],
            [72.92742626070009, 20.287634585037033],
            [72.93642545727904, 20.29393765603725],
            [72.94767445300273, 20.287634585037033],
            [72.97017244445013, 20.270826395703114],
            [73.01066882905542, 20.30234175070421],
            [73.00616923076595, 20.314947892704648],
            [73.00841902991068, 20.317048916371387],
            [73.01741822648964, 20.304442774370948],
            [73.03316682050281, 20.29183663237051],
            [73.03766641879228, 20.304442774370948],
            [73.03766641879228, 20.317048916371387],
            [73.06016441023968, 20.327554034705088],
            [73.0736632051081, 20.329655058371827],
            [73.08041260254232, 20.327554034705088],
            [73.08716199997654, 20.335958129372045],
            [73.09166159826603, 20.356968366039442],
            [73.10291059398972, 20.304442774370948],
            [73.1186591880029, 20.306543798037687],
            [73.12990818372658, 20.31074584537117],
            [73.15240617517398, 20.317048916371387],
            [73.15915557260819, 20.314947892704648],
            [73.16815476918714, 20.31074584537117],
            [73.1659049700424, 20.30024072703747],
            [73.16140537175292, 20.283432537703554],
            [73.15915557260819, 20.283432537703554],
            [73.15690577346345, 20.287634585037033],
            [73.14565677773976, 20.285533561370293],
            [73.14340697859501, 20.277129466703336],
            [73.1366575811608, 20.281331514036815],
            [73.13215798287132, 20.268725372036375],
            [73.12765838458185, 20.260321277369414],
            [73.1186591880029, 20.258220253702675],
            [73.10291059398972, 20.24561411170224],
            [73.09166159826603, 20.22880592236832],
            [73.07141340596337, 20.233007969701802],
            [73.0669138076739, 20.230906946035063],
            [73.05341501280546, 20.203593638367444],
            [73.06016441023968, 20.197290567367226],
            [73.06466400852915, 20.193088520033747],
            [73.06241420938441, 20.17628033069983],
            [73.07141340596337, 20.15737111769917],
            [73.09166159826603, 20.16367418869939],
            [73.09391139741076, 20.15947214136591],
            [73.10291059398972, 20.15947214136591],
            [73.11415958971341, 20.15737111769917],
            [73.13890738030554, 20.205694662034183],
            [73.15465597431871, 20.201492614700705],
            [73.16365517089767, 20.201492614700705],
            [73.17940376491084, 20.201492614700705],
            [73.18615316234505, 20.197290567367226],
            [73.21990014951614, 20.18468442536679],
            [73.2176503503714, 20.17628033069983],
            [73.18615316234505, 20.15737111769917],
            [73.18615316234505, 20.140562928365256],
            [73.20640135464771, 20.121653715364594],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Daman and Diu',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [72.8014375085947, 20.37377655537336],
            [72.84418369234474, 20.474625691376865],
            [72.88018047866056, 20.369574508039882],
            [72.8014375085947, 20.37377655537336],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Goa',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.1198198074117, 15.652776376209262],
            [74.24805835866181, 15.656978423542741],
            [74.31555233300398, 15.186349122193048],
            [74.23455956379338, 14.948933447851463],
            [74.08832261938535, 14.896407856182972],
            [73.67435957675339, 15.72841322821189],
            [73.85659330747724, 15.799848032881041],
            [73.99383105530632, 15.610755902874468],
            [74.1198198074117, 15.652776376209262],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Gujarat',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [68.3580841977354, 23.804748203159285],
              [68.41657897549861, 23.718606232822957],
              [68.3468352020117, 23.598847883818795],
              [68.22309624905107, 23.588342765485095],
              [68.3580841977354, 23.804748203159285],
            ],
          ],
          [
            [
              [68.3580841977354, 23.9749311201652],
              [68.335586206288, 23.83836458182712],
              [68.20959745418263, 23.811051274159503],
              [68.3580841977354, 23.9749311201652],
            ],
          ],
          [
            [
              [72.65745036333142, 21.47051090941148],
              [72.61245438043665, 21.680613276085452],
              [72.51796281635761, 21.705825560086325],
              [72.61245438043665, 21.861301311425066],
              [72.51571301721286, 21.88021052442572],
              [72.50221422234443, 21.953746352761613],
              [72.63720217102876, 22.285708092106482],
              [72.36272667537062, 22.201667145436893],
              [72.22098932925208, 21.955847376428352],
              [72.30648169675214, 21.628087684416958],
              [72.04775479510718, 21.159559406734004],
              [71.15008493635636, 20.772971052053904],
              [71.14558533806688, 20.756162862719982],
              [71.01284718852729, 20.739354673386067],
              [70.9903491970799, 20.73095057871911],
              [70.82161426122448, 20.691031129051055],
              [70.25691467589502, 20.974669324060912],
              [69.23550586418357, 21.939039187094433],
              [68.93628257793328, 22.3025162814404],
              [69.0172753471439, 22.44748691444544],
              [69.14776369753875, 22.403365417443908],
              [69.23325606503883, 22.258394784438867],
              [70.17142230839494, 22.556740145115903],
              [70.34465684253983, 22.93072235779557],
              [70.22541748786867, 22.960136689129925],
              [69.86544962471045, 22.871893695126857],
              [69.69446488971029, 22.743731251455735],
              [69.197259278723, 22.840378340125763],
              [68.59206330878824, 23.21436055280543],
              [68.6483082874067, 23.304704570475238],
              [68.49532194556447, 23.50640284248225],
              [68.48857254813025, 23.634565286153368],
              [68.67305607799884, 23.861475842161255],
              [68.5718151164856, 23.880385055161913],
              [68.55156692418294, 23.96862804916498],
              [68.75404884720945, 23.96862804916498],
              [68.76979744122261, 24.273276480842238],
              [68.85079021043322, 24.220750889173743],
              [68.9430319753675, 24.302690812176593],
              [69.00377655227545, 24.222851912840483],
              [69.58872432990756, 24.290084670176153],
              [69.73046167602612, 24.17242734483873],
              [70.01618616740795, 24.17032632117199],
              [70.10842793234225, 24.29428671750963],
              [70.5741363553032, 24.422449161180754],
              [70.5741363553032, 24.25436726784158],
              [70.72037329971123, 24.216548841840265],
              [70.8801090389877, 24.24596317317462],
              [70.87335964155348, 24.298488764843114],
              [71.10958855175106, 24.437156326847933],
              [70.99934839365885, 24.44556042151489],
              [70.9903491970799, 24.605238220187108],
              [71.09833955602737, 24.685077119523218],
              [71.28957248333018, 24.61364231485407],
              [71.48080541063298, 24.678774048522996],
              [72.22323912839681, 24.634652551521466],
              [72.4594680385944, 24.407741995513575],
              [72.53596120951552, 24.506490107850343],
              [72.72719413681833, 24.361519474845302],
              [72.97242224359486, 24.359418451178563],
              [72.97692184188433, 24.45186349251511],
              [73.09166159826603, 24.493883965849903],
              [73.08041260254232, 24.39513585351314],
              [73.18390336320032, 24.334206167177687],
              [73.07816280339759, 24.18503348683917],
              [73.24914753839775, 24.010648522499775],
              [73.41113307681894, 24.05056797216783],
              [73.35713789734521, 23.853071747494297],
              [73.65636118359548, 23.62195914415293],
              [73.62936359385861, 23.451776227147015],
              [73.82284632030616, 23.445473156146797],
              [73.89034029464833, 23.336219925476332],
              [74.00058045274054, 23.334118901809592],
              [74.02082864504318, 23.332017878142853],
              [74.13331860228013, 23.262684097140443],
              [74.12431940570117, 23.176542126804115],
              [74.24805835866181, 23.176542126804115],
              [74.32005193129346, 23.060985825133432],
              [74.37854670905666, 22.907611097461434],
              [74.46178927741201, 22.911813144794912],
              [74.46178927741201, 22.813065032458148],
              [74.3807965082014, 22.64078109178549],
              [74.27505594839867, 22.64498313911897],
              [74.08157322195113, 22.50631557711415],
              [74.09957161510904, 22.432779748778263],
              [74.2705563501092, 22.390759275443468],
              [74.18731378175386, 22.31932447077432],
              [74.0680744270827, 22.359243920442374],
              [74.14231779885908, 21.953746352761613],
              [73.82284632030616, 21.810876743423314],
              [73.88584069635885, 21.710027607419807],
              [73.81834672201668, 21.596572329415864],
              [73.8543435083325, 21.49572319341236],
              [74.28630494412238, 21.55665287974781],
              [74.30205353813554, 21.478915004078438],
              [74.07482382451691, 21.455803743744305],
              [73.95558446984576, 21.392773033742113],
              [73.94208567497732, 21.296125945072085],
              [73.8250961194509, 21.26671161373773],
              [73.81609692287195, 21.170064525067705],
              [73.62936359385861, 21.132246099066393],
              [73.79134913227982, 21.067114365397458],
              [73.9353362775431, 20.768769004720422],
              [73.73960375195082, 20.56707073271341],
              [73.44937966227951, 20.714142389385188],
              [73.41788247425316, 20.201492614700705],
              [73.30089291872675, 20.207795685700923],
              [73.20640135464771, 20.121653715364594],
              [73.18615316234505, 20.140562928365256],
              [73.18615316234505, 20.15737111769917],
              [73.2176503503714, 20.17628033069983],
              [73.21990014951614, 20.18468442536679],
              [73.18615316234505, 20.197290567367226],
              [73.17940376491084, 20.201492614700705],
              [73.16365517089767, 20.201492614700705],
              [73.15465597431871, 20.201492614700705],
              [73.13890738030554, 20.205694662034183],
              [73.11415958971341, 20.15737111769917],
              [73.10291059398972, 20.15947214136591],
              [73.09391139741076, 20.15947214136591],
              [73.09166159826603, 20.16367418869939],
              [73.07141340596337, 20.15737111769917],
              [73.06241420938441, 20.17628033069983],
              [73.06466400852915, 20.193088520033747],
              [73.06016441023968, 20.197290567367226],
              [73.05341501280546, 20.203593638367444],
              [73.0669138076739, 20.230906946035063],
              [73.07141340596337, 20.233007969701802],
              [73.09166159826603, 20.22880592236832],
              [73.10291059398972, 20.24561411170224],
              [73.1186591880029, 20.258220253702675],
              [73.12765838458185, 20.260321277369414],
              [73.13215798287132, 20.268725372036375],
              [73.1366575811608, 20.281331514036815],
              [73.14340697859501, 20.277129466703336],
              [73.14565677773976, 20.285533561370293],
              [73.15690577346345, 20.287634585037033],
              [73.15915557260819, 20.283432537703554],
              [73.16140537175292, 20.283432537703554],
              [73.1659049700424, 20.30024072703747],
              [73.16815476918714, 20.31074584537117],
              [73.15915557260819, 20.314947892704648],
              [73.15240617517398, 20.317048916371387],
              [73.12990818372658, 20.31074584537117],
              [73.1186591880029, 20.306543798037687],
              [73.10291059398972, 20.304442774370948],
              [73.09166159826603, 20.356968366039442],
              [73.08716199997654, 20.335958129372045],
              [73.08041260254232, 20.327554034705088],
              [73.0736632051081, 20.329655058371827],
              [73.06016441023968, 20.327554034705088],
              [73.03766641879228, 20.317048916371387],
              [73.03766641879228, 20.304442774370948],
              [73.03316682050281, 20.29183663237051],
              [73.01741822648964, 20.304442774370948],
              [73.00841902991068, 20.317048916371387],
              [73.00616923076595, 20.314947892704648],
              [73.01066882905542, 20.30234175070421],
              [72.97017244445013, 20.270826395703114],
              [72.94767445300273, 20.287634585037033],
              [72.93642545727904, 20.29393765603725],
              [72.92742626070009, 20.287634585037033],
              [72.96342304701591, 20.22670489870158],
              [72.96342304701591, 20.21619978036788],
              [72.95892344872642, 20.21409875670114],
              [72.72944393596306, 20.136360881031774],
              [72.8014375085947, 20.37377655537336],
              [72.88018047866056, 20.369574508039882],
              [72.84418369234474, 20.474625691376865],
              [72.8824302778053, 20.611192229714945],
              [72.59445598727872, 21.312934134406007],
              [72.65745036333142, 21.47051090941148],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Haryana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.12555146478286, 30.563741339060886],
            [77.21329363142767, 30.502811652725438],
            [77.21329363142767, 30.494407558058477],
            [77.58901008859907, 30.380952280054533],
            [77.42702455017786, 30.105718179711634],
            [77.28303740491458, 30.042687469709442],
            [77.09630407590124, 29.595169428693886],
            [77.1975450374145, 28.861912169001734],
            [76.95231693063796, 28.81568964833346],
            [76.95681652892743, 28.67912310999538],
            [76.84207677254575, 28.578273973991873],
            [77.1165522682039, 28.487929956322066],
            [77.1750470459671, 28.40178798598574],
            [77.348281580112, 28.502637121989245],
            [77.54626390484903, 28.18538254831155],
            [77.48326952879634, 28.097139554308484],
            [77.54401410570429, 27.98788632363802],
            [77.28528720405932, 27.800895217298187],
            [77.046808494717, 27.813501359298623],
            [76.97931452037483, 27.651722536959667],
            [76.89607195201948, 27.71475324696186],
            [76.97031532379587, 28.13916002764328],
            [76.84207677254575, 28.218998926979385],
            [76.65759324267717, 28.086634435974787],
            [76.66884223840086, 28.013098607638895],
            [76.538353888006, 27.973179157970844],
            [76.48210890938753, 28.145463098643496],
            [76.36736915300585, 28.13916002764328],
            [76.34487116155846, 28.025704749639335],
            [76.185135422282, 28.05511908097369],
            [76.18063582399252, 27.802996240964926],
            [75.98265349925549, 27.859723879966896],
            [76.06139646932135, 28.218998926979385],
            [76.01640048642658, 28.248413258313743],
            [76.02764948215028, 28.277827589648098],
            [75.89716113175542, 28.389181843985302],
            [75.56419085833406, 28.61189035265971],
            [75.51919487543928, 29.00688280200677],
            [75.4449515036629, 29.013185873006993],
            [75.37070813188652, 29.139247293011373],
            [75.38870652504443, 29.261106665682277],
            [75.08723343964942, 29.22959131068118],
            [74.85775392688605, 29.401875251353836],
            [74.7857603542544, 29.357753754352302],
            [74.57877883293843, 29.406077298687315],
            [74.61477561925425, 29.752746203699363],
            [74.48203746971465, 29.742241085365666],
            [74.56303023892525, 29.864100458036567],
            [74.52928325175418, 29.943939357372678],
            [74.81050814484654, 29.99226290170769],
            [74.99949127300461, 29.85359533970287],
            [75.07598444392572, 29.876706600037007],
            [75.11198123024154, 29.80106974803438],
            [75.19297399945215, 29.836787150368952],
            [75.2312205849127, 29.605674547027586],
            [75.45395070024186, 29.78426155870046],
            [75.82966715741325, 29.811574866368076],
            [75.94440691379494, 29.729634943365227],
            [76.2143828111636, 29.84309022136917],
            [76.22113220859782, 29.83888817403569],
            [76.26387839234786, 30.116223298045334],
            [76.43711292649276, 30.14353660571295],
            [76.54510328544022, 30.07840487204402],
            [76.63959484951926, 30.151940700379907],
            [76.60359806320344, 30.25068881271667],
            [76.92981893919057, 30.494407558058477],
            [76.9320687383353, 30.502811652725438],
            [76.8330775759668, 30.681398664398312],
            [76.83082777682206, 30.68770173539853],
            [76.84207677254575, 30.70240890106571],
            [76.83757717425628, 30.725520161399846],
            [76.84207677254575, 30.76123756373442],
            [76.78358199478254, 30.904107173072717],
            [76.91632014432214, 30.89360205473902],
            [77.02656030241434, 30.738126303400282],
            [77.16604784938815, 30.68560071173179],
            [77.12555146478286, 30.563741339060886],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Himachal Pradesh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [78.92089118228449, 31.254978125418248],
            [79.01313294721878, 31.11210851607995],
            [78.89614339169236, 31.09950237407951],
            [78.81065102419228, 31.200351510083017],
            [78.48443014820515, 31.198250486416274],
            [78.38543898583663, 31.284392456752602],
            [77.91748076373095, 31.154128989414744],
            [77.70150004583601, 30.763338587401158],
            [77.81174020392821, 30.507013700058916],
            [77.57551129373063, 30.39986149305519],
            [77.58901008859907, 30.380952280054533],
            [77.21329363142767, 30.494407558058477],
            [77.21329363142767, 30.502811652725438],
            [77.12555146478286, 30.563741339060886],
            [77.16604784938815, 30.68560071173179],
            [77.02656030241434, 30.738126303400282],
            [76.91632014432214, 30.89360205473902],
            [76.78358199478254, 30.904107173072717],
            [76.73858601188776, 30.935622528073814],
            [76.73858601188776, 30.939824575407293],
            [76.62384625550608, 31.000754261742745],
            [76.65084384524295, 31.208755604749975],
            [76.44161252478223, 31.322210882753918],
            [76.21888240945307, 31.30330166975326],
            [75.90841012747912, 31.94831593544235],
            [75.62493543524201, 32.0995896394476],
            [75.63843423011045, 32.23405515411895],
            [75.9421571146502, 32.41054114212508],
            [75.88816193517647, 32.57652201179752],
            [75.93990731550545, 32.64375476913319],
            [75.92865831978176, 32.757210047137136],
            [75.82516755912377, 32.929493987809785],
            [75.95115631122916, 32.88537249080825],
            [76.03439887958449, 32.91688784580935],
            [76.11089205050561, 33.005130839812416],
            [76.25487919576891, 33.032444147480035],
            [76.40111614017692, 33.18371785148529],
            [76.81507918280889, 33.17321273315159],
            [76.93431853748005, 33.032444147480035],
            [77.15479885366446, 32.9778175321448],
            [77.33928238353305, 32.82024075713932],
            [77.73074743471761, 32.96941343747784],
            [77.99397393465206, 32.58912815379796],
            [78.31344541320499, 32.708886502802116],
            [78.40568717813929, 32.61854248513231],
            [78.31569521234972, 32.574420988130775],
            [78.32694420807343, 32.47147082846053],
            [78.4011875798498, 32.52609744379576],
            [78.53617552853414, 32.41474318945856],
            [78.47993054991566, 32.273974603786996],
            [78.7881530327449, 31.99663947977736],
            [78.70716026353429, 31.788638136770132],
            [78.84214821221863, 31.622657267097694],
            [78.73865745156064, 31.536515296761365],
            [78.92089118228449, 31.254978125418248],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Jharkhand',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [87.79409900913467, 25.22083815454184],
            [87.77835041512151, 25.101079805537676],
            [87.97183314156905, 24.88257334419675],
            [87.84584438946368, 24.73970373485845],
            [87.90658896637163, 24.72289554552453],
            [87.89309017150319, 24.563217746852313],
            [87.80084840656889, 24.563217746852313],
            [87.80084840656889, 24.3825297115127],
            [87.68610865018721, 24.24806419684136],
            [87.69510784676616, 24.151417108171334],
            [87.50162512031862, 24.1178007295035],
            [87.4431303425554, 23.979133167498677],
            [87.33738978275268, 24.031658759167172],
            [87.29914319729212, 23.890890173495613],
            [87.1416572571604, 23.869879936828216],
            [87.1529062528841, 23.796344108492324],
            [86.90092874867334, 23.882486078828652],
            [86.79743798801535, 23.689191901488602],
            [86.45096891972555, 23.634565286153368],
            [86.35647735564652, 23.466483392814194],
            [86.23048860354115, 23.43917008514658],
            [86.14499623604107, 23.571534576151176],
            [86.04375527452783, 23.586241741818355],
            [86.0482548728173, 23.49169567681507],
            [85.86602114209344, 23.451776227147015],
            [85.84127335150131, 23.193350316138034],
            [85.92226612071192, 23.13242062980258],
            [86.04375527452783, 23.145026771803018],
            [86.21698980867271, 22.993753067797762],
            [86.39922353939656, 22.976944878463843],
            [86.42172153084395, 22.77944865379031],
            [86.64220184702836, 22.65758928111941],
            [86.65795044104154, 22.579851405450043],
            [86.76144120169953, 22.577750381783304],
            [86.84918336834434, 22.39916337011043],
            [86.82443557775221, 22.26469785543909],
            [86.72769421452844, 22.218475334770815],
            [86.5049640991993, 22.344536754775195],
            [86.43072072742291, 22.31092037610736],
            [86.07525246255416, 22.546235026782206],
            [85.96051270617248, 22.460093056445878],
            [86.02800668051465, 22.38445620444325],
            [85.97626130018566, 22.247889666105166],
            [86.03250627880412, 22.189061003436457],
            [85.89976812926453, 21.981059660429228],
            [85.41606131314566, 22.159646672102102],
            [85.2158291892639, 22.0482924177649],
            [85.03134565939531, 22.15754564843536],
            [85.11233842860591, 22.294112186773443],
            [85.05834324913218, 22.483204316780014],
            [84.2956613390657, 22.340334707441716],
            [84.00993684768386, 22.52312376644807],
            [84.38340350571052, 22.87609574246034],
            [84.3766541082763, 22.976944878463843],
            [84.22366776643406, 22.976944878463843],
            [84.03693443742073, 23.155531890136718],
            [84.07743082202603, 23.332017878142853],
            [83.97394006136804, 23.376139375144387],
            [84.01443644597335, 23.63246426248663],
            [84.00768704853913, 23.63246426248663],
            [83.95594166821013, 23.62406016781967],
            [83.94694247163117, 23.62195914415293],
            [83.94244287334169, 23.56313048148422],
            [83.78270713406523, 23.600948907485535],
            [83.51723083498604, 24.025355688166954],
            [83.33049750597272, 24.10099254016958],
            [83.44298746320966, 24.346812309178127],
            [83.40024127945962, 24.500187036850125],
            [83.50598183926235, 24.525399320851],
            [83.75570954432837, 24.508591131517083],
            [83.78945653149945, 24.52329829718426],
            [83.86594970242058, 24.535904439184698],
            [83.87494889899952, 24.53170239185122],
            [84.0009376511049, 24.636753575188205],
            [84.11792720663132, 24.481277823849467],
            [84.2664139501841, 24.538005462851437],
            [84.50039306123693, 24.285882622842674],
            [84.5791360313028, 24.411944042847058],
            [84.66687819794763, 24.3930348298464],
            [84.82661393722408, 24.525399320851],
            [84.90985650557941, 24.372024593179002],
            [85.6725384156459, 24.58212695985297],
            [85.66803881735643, 24.674572001189517],
            [85.78502837288285, 24.79853239752716],
            [85.8862693343961, 24.8006334211939],
            [85.95826290702774, 24.73129964019149],
            [86.05500427025152, 24.777522160859764],
            [86.13149744117263, 24.61364231485407],
            [86.30023237702805, 24.58632900718645],
            [86.30023237702805, 24.47707577651599],
            [86.46896731288346, 24.37832766417922],
            [86.60620506071254, 24.60103617285363],
            [86.67369903505471, 24.561116723185574],
            [86.79743798801535, 24.615743338520808],
            [86.86043236406803, 24.550611604851877],
            [86.94142513327864, 24.630450504187984],
            [87.0494154922261, 24.626248456854505],
            [87.1821536417657, 25.063261379536364],
            [87.29689339814738, 25.09057468720398],
            [87.33064038531846, 25.22293917820858],
            [87.47912712887123, 25.241848391209235],
            [87.57361869295026, 25.334293432545785],
            [87.79409900913467, 25.22083815454184],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Karnataka',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.55301330228325, 18.293763125301062],
            [77.65650406294124, 17.972306504289886],
            [77.52826551169112, 17.829436894951588],
            [77.54176430655954, 17.72858775894808],
            [77.46077153734895, 17.701274451280465],
            [77.44277314419104, 17.583617125943043],
            [77.66100366123071, 17.524788463274334],
            [77.38427836642782, 17.222241055263815],
            [77.37977876813835, 17.140301132260966],
            [77.50351772109899, 17.010037664923104],
            [77.42027515274364, 16.665469783577795],
            [77.45852173820421, 16.610843168242564],
            [77.42027515274364, 16.522600174239496],
            [77.25603981517772, 16.47217560623774],
            [77.28753700320405, 16.407043872568813],
            [77.59575948603329, 16.316699854899007],
            [77.4922687253753, 16.253669144896815],
            [77.51476671682268, 15.925909452885422],
            [77.25379001603297, 15.961626855219997],
            [77.08055548188807, 15.915404334551724],
            [77.04230889642751, 15.364936133865923],
            [77.15254905451972, 15.291400305530034],
            [77.1682976485329, 15.17374298019261],
            [77.11430246905915, 15.035075418187791],
            [76.88032335800631, 15.032974394521052],
            [76.79933058879571, 15.096005104523242],
            [76.76783340076938, 14.97414573185234],
            [76.86907436228262, 14.96994368451886],
            [76.76558360162463, 14.602264542839414],
            [76.86907436228262, 14.480405170168511],
            [76.97931452037483, 14.48460721750199],
            [76.88932255458528, 14.396364223498924],
            [76.94331773405901, 14.245090519493665],
            [77.0535578921512, 14.245090519493665],
            [77.15929845195393, 14.34383863183043],
            [77.36627997326991, 14.27660587449476],
            [77.40227675958573, 14.335434537163472],
            [77.50576752024372, 14.266100756161062],
            [77.51701651596743, 14.179958785824734],
            [77.39777716129626, 14.173655714824516],
            [77.34603178096727, 14.060200436820573],
            [77.42477475103313, 13.902623661815095],
            [77.35503097754622, 13.902623661815095],
            [77.3190341912304, 14.032887129152956],
            [77.14130005879602, 14.005573821485338],
            [77.02881010155909, 14.060200436820573],
            [77.02656030241434, 14.179958785824734],
            [76.94781733234848, 14.119029099489282],
            [77.04230889642751, 13.93203799314945],
            [76.97481492208534, 13.816481691478767],
            [77.17279724682237, 13.761855076143535],
            [77.24704061859876, 13.900522638148356],
            [77.43152414846735, 13.833289880812686],
            [77.53501490912534, 13.715632555475262],
            [77.91973056287569, 13.906825709148574],
            [78.05021891327054, 13.894219567148136],
            [78.11546308846796, 13.86270421214704],
            [78.0884654987311, 13.65890491647329],
            [78.38993858412611, 13.59167215913762],
            [78.36519079353398, 13.364761603129732],
            [78.57667191313944, 13.282821680126883],
            [78.46868155419197, 12.973971201116148],
            [78.25270083629704, 12.856313875778726],
            [78.23245264399439, 12.761767810775439],
            [77.8342381953756, 12.868920017779164],
            [77.75999482359921, 12.696636077106508],
            [77.60700848175698, 12.629403319770837],
            [77.6137578791912, 12.362573314094897],
            [77.47652013136212, 12.21129961008964],
            [77.73749683215183, 12.175582207755063],
            [77.77799321675712, 12.118854568753093],
            [77.67450245609915, 11.963378817414355],
            [77.50126792195425, 11.93816653341348],
            [77.44277314419104, 11.780589758408002],
            [76.90957074688792, 11.791094876741699],
            [76.86007516570366, 11.608305817735346],
            [76.5608518794534, 11.620911959735784],
            [76.51360609741387, 11.70495290640537],
            [76.43261332820327, 11.667134480404059],
            [76.42586393076905, 11.728064166739507],
            [76.1153916487951, 11.85832763407737],
            [76.11314184965035, 11.978085983081533],
            [75.88141253774225, 11.946570628080437],
            [75.80041976853164, 12.078935119085038],
            [75.57993945234723, 12.156672994754405],
            [75.4337025079392, 12.297441580425966],
            [75.37070813188652, 12.488634734099278],
            [75.11198123024154, 12.679827887772591],
            [75.04673705504412, 12.665120722105414],
            [75.00624067043881, 12.789081118443056],
            [74.86225352517553, 12.75756576344196],
            [74.61477561925425, 13.835390904479425],
            [74.26155715353025, 14.722022891843576],
            [74.108570811688, 14.785053601845767],
            [74.08832261938535, 14.896407856182972],
            [74.23455956379338, 14.948933447851463],
            [74.31555233300398, 15.186349122193048],
            [74.24805835866181, 15.656978423542741],
            [74.1198198074117, 15.652776376209262],
            [74.16031619201699, 15.751524488546027],
            [74.34479972188558, 15.766231654213206],
            [74.48428726885939, 16.08978929889112],
            [74.5022856620173, 16.22425481356246],
            [74.34254992274084, 16.29358859456487],
            [74.26830655096447, 16.522600174239496],
            [74.48878686714887, 16.62975238124322],
            [74.54728164491209, 16.6339544285767],
            [74.56752983721474, 16.55411552924059],
            [74.77451135853072, 16.751611753914123],
            [74.91174910635979, 16.787329156248695],
            [74.99949127300461, 16.947006954920916],
            [75.22672098662323, 16.84195577158393],
            [75.2829659652417, 16.955411049587873],
            [75.66768161899205, 16.98482538092223],
            [75.63393463182096, 17.47856594260606],
            [75.80941896511061, 17.37141373560233],
            [75.89266153346594, 17.407131137936908],
            [75.93090811892651, 17.32519121493406],
            [76.11989124708457, 17.369312711935592],
            [76.38311774701901, 17.312585072933622],
            [76.33137236669003, 17.59622326794348],
            [76.4168647341901, 17.60672838627718],
            [76.52485509313757, 17.760103113949178],
            [76.69359002899299, 17.707577522280687],
            [76.9140703451774, 17.938690125622053],
            [76.95681652892743, 18.186610918297333],
            [77.06030728958542, 18.1529945396295],
            [77.31678439208567, 18.44083478197284],
            [77.4697707339279, 18.262247770299965],
            [77.55301330228325, 18.293763125301062],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Kerala',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [76.43261332820327, 11.667134480404059],
            [76.23238120432151, 11.570487391734032],
            [76.2661281914926, 11.480143374064225],
            [76.54735308458496, 11.360385025060062],
            [76.45286152050592, 11.207010297388063],
            [76.7520848067562, 11.116666279718256],
            [76.65759324267717, 10.935978244378642],
            [76.90957074688792, 10.778401469373165],
            [76.83082777682206, 10.303570120689994],
            [76.995063114388, 10.227933268687366],
            [77.17729684511185, 10.356095712358487],
            [77.26503901175667, 10.26365067102194],
            [77.2672888109014, 10.12078106168364],
            [77.20204463570397, 10.103972872349724],
            [77.27628800748036, 9.973709405011862],
            [77.18179644340132, 9.656454831334168],
            [77.40227675958573, 9.515686245662607],
            [77.26953861004614, 9.15220915131664],
            [77.15029925537499, 9.019844660312039],
            [77.26503901175667, 8.839156624972425],
            [77.19529523826976, 8.7425095363024],
            [77.28303740491458, 8.547114335295609],
            [77.10080367419073, 8.292890471620105],
            [76.55410248201918, 8.889581192974179],
            [76.33812176412424, 9.423241204326061],
            [76.10639245221614, 10.364499807025446],
            [75.7396751916237, 11.356182977726583],
            [75.53719326859719, 11.692346764404935],
            [75.53044387116299, 11.70495290640537],
            [75.2019731960311, 12.018005432749586],
            [74.86225352517553, 12.75756576344196],
            [75.00624067043881, 12.789081118443056],
            [75.04673705504412, 12.665120722105414],
            [75.11198123024154, 12.679827887772591],
            [75.37070813188652, 12.488634734099278],
            [75.4337025079392, 12.297441580425966],
            [75.57993945234723, 12.156672994754405],
            [75.80041976853164, 12.078935119085038],
            [75.88141253774225, 11.946570628080437],
            [76.11314184965035, 11.978085983081533],
            [76.1153916487951, 11.85832763407737],
            [76.42586393076905, 11.728064166739507],
            [76.43261332820327, 11.667134480404059],
          ],
          [
            [75.53044387116299, 11.730165190406248],
            [75.53044387116299, 11.728064166739507],
            [75.52819407201824, 11.71966007207255],
            [75.53269367030772, 11.71755904840581],
            [75.53944306774194, 11.713357001072332],
            [75.54619246517615, 11.715458024739071],
            [75.5484422643209, 11.715458024739071],
            [75.5551916617551, 11.71755904840581],
            [75.55969126004459, 11.71966007207255],
            [75.55744146089985, 11.725963143072768],
            [75.54619246517615, 11.740670308739947],
            [75.54619246517615, 11.755377474407124],
            [75.54394266603141, 11.759579521740605],
            [75.53494346945246, 11.757478498073866],
            [75.53269367030772, 11.755377474407124],
            [75.53269367030772, 11.751175427073646],
            [75.53719326859719, 11.746973379740165],
            [75.53044387116299, 11.730165190406248],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Lakshadweep',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [74.10182141425378, 11.204909273721324],
              [73.96458366642472, 11.305758409724831],
              [74.01182944846423, 11.347778883059624],
              [74.10182141425378, 11.204909273721324],
            ],
          ],
          [
            [
              [71.84527287208067, 11.835216373743233],
              [71.78227849602798, 11.835216373743233],
              [71.73503271398847, 11.961277793747616],
              [71.78677809431747, 12.00119724341567],
              [71.84527287208067, 11.835216373743233],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Madhya Pradesh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [82.81529350182751, 23.962324978164762],
            [82.55206700189306, 23.792142061158845],
            [81.96936902340569, 23.855172771161037],
            [81.95812002768199, 23.850970723827558],
            [81.81863248070817, 23.806849226826024],
            [81.67014573715541, 23.922405528496707],
            [81.60715136110272, 23.853071747494297],
            [81.69489352774754, 23.718606232822957],
            [81.62065015597116, 23.659777570154244],
            [81.61390075853694, 23.50850386614899],
            [81.73763971149758, 23.56313048148422],
            [81.92437304051091, 23.529514102816385],
            [81.98061801912938, 23.41815984847918],
            [81.98286781827412, 23.4139578011457],
            [82.19434893787958, 23.321512759809153],
            [82.15835215156375, 23.14082472446954],
            [81.9468710319583, 23.07779401446735],
            [81.94912083110304, 22.955934641796446],
            [81.77813609610288, 22.873994718793597],
            [81.7736364978134, 22.676498494120068],
            [81.48791200643157, 22.493709435113715],
            [81.48341240814209, 22.491608411446975],
            [81.33042606629984, 22.52522479011481],
            [81.19993771590498, 22.472699198446314],
            [81.19318831847077, 22.487406364113497],
            [81.11669514754965, 22.43908281977848],
            [81.12119474583912, 22.294112186773443],
            [81.00645498945744, 22.060898559765334],
            [80.92321242110211, 22.119727222434047],
            [80.83996985274676, 21.945342258094655],
            [80.83772005360203, 21.804573672423093],
            [80.71398110064139, 21.67220918141849],
            [80.73647909208877, 21.47261193307822],
            [80.66448551945713, 21.33184334740666],
            [80.45525419899641, 21.403278152075814],
            [80.26627107083834, 21.61968358975],
            [79.86355702393008, 21.531440595746933],
            [79.73981807096945, 21.600774376749342],
            [79.58233213083773, 21.544046737747372],
            [79.49458996419291, 21.67220918141849],
            [79.2403626608374, 21.714229654753286],
            [79.2223642676795, 21.649097921084355],
            [78.9793859600477, 21.615481542416518],
            [78.93663977629767, 21.48521807507866],
            [78.44393376359984, 21.502026264412578],
            [78.42593537044193, 21.600774376749342],
            [78.18520686195487, 21.55665287974781],
            [78.17395786623118, 21.4978242170791],
            [77.90173216971777, 21.375964844408195],
            [77.67450245609915, 21.371762797074716],
            [77.4405233450463, 21.47051090941148],
            [77.42702455017786, 21.541945714080633],
            [77.57101169544116, 21.527238548413454],
            [77.50126792195425, 21.74784603342112],
            [77.28078760576983, 21.75625012808808],
            [76.79708078965098, 21.594471305749124],
            [76.79483099050624, 21.4873190987454],
            [76.62609605465082, 21.327641300073182],
            [76.62384625550608, 21.191074761735102],
            [76.49110810596649, 21.19527680906858],
            [76.37861814872954, 21.075518460064416],
            [76.185135422282, 21.08812460206486],
            [76.09964305478192, 21.371762797074716],
            [75.21772179004428, 21.40958122307603],
            [75.05573625162307, 21.562955950748027],
            [74.58777802951738, 21.66170406308479],
            [74.50678526030678, 21.724734773086986],
            [74.5247836534647, 21.90752383209334],
            [74.38529610649088, 22.01887808643054],
            [74.28630494412238, 21.932736116094212],
            [74.14231779885908, 21.953746352761613],
            [74.0680744270827, 22.359243920442374],
            [74.18731378175386, 22.31932447077432],
            [74.2705563501092, 22.390759275443468],
            [74.09957161510904, 22.432779748778263],
            [74.08157322195113, 22.50631557711415],
            [74.27505594839867, 22.64498313911897],
            [74.3807965082014, 22.64078109178549],
            [74.46178927741201, 22.813065032458148],
            [74.46178927741201, 22.911813144794912],
            [74.37854670905666, 22.907611097461434],
            [74.32005193129346, 23.060985825133432],
            [74.53378285004365, 23.098804251134744],
            [74.66877079872799, 23.197552363471512],
            [74.53153305089891, 23.319411736142413],
            [74.61252582010951, 23.458079298147233],
            [74.85100452945184, 23.55472638681726],
            [74.92299810208348, 23.666080641154466],
            [74.90499970892557, 23.863576865827994],
            [74.99274187557039, 24.027456711833693],
            [74.87575232004396, 24.275377504508977],
            [74.7677619610965, 24.271175457175495],
            [74.75426316622806, 24.277478528175717],
            [74.85775392688605, 24.44766144518163],
            [74.7160165807675, 24.504389084183604],
            [74.71376678162277, 24.506490107850343],
            [74.81950734142549, 24.762814995192585],
            [74.87350252089922, 24.66616790652256],
            [75.00174107214934, 24.706087356190615],
            [75.01299006787303, 24.7901283028602],
            [74.8600037260308, 24.809037515860858],
            [74.86900292260975, 24.932997912198502],
            [75.04448725589938, 24.85526003652913],
            [75.1209804268205, 24.88467436786349],
            [75.15697721313633, 25.025442953535048],
            [75.3122133541233, 25.042251142868967],
            [75.30321415754435, 24.811138539527597],
            [75.24471937978115, 24.85736106019587],
            [75.2312205849127, 24.73129964019149],
            [75.61593623866305, 24.685077119523218],
            [75.74417478991317, 24.756511924192367],
            [75.84091615313694, 24.724996569191273],
            [75.92865831978176, 24.53380341551796],
            [75.90616032833438, 24.441358374181412],
            [75.73742539247895, 24.399337900846618],
            [75.76442298221582, 23.993840333165856],
            [75.51694507629455, 24.044264901167608],
            [75.45845029853133, 23.91820348116323],
            [75.65193302497887, 23.796344108492324],
            [75.97815390096602, 23.928708599496925],
            [75.96240530695285, 24.02115364083347],
            [76.13788964024248, 24.09048742183588],
            [76.12664064451879, 24.201841676173085],
            [76.20988321287413, 24.22705396017396],
            [76.46636031537436, 24.224952936507222],
            [76.52710489228231, 24.15772017917155],
            [76.6193466572166, 24.25856931517506],
            [76.79483099050624, 24.12200277683698],
            [76.90507114859844, 24.126204824170458],
            [76.94781733234848, 24.193437581506128],
            [76.84207677254575, 24.357317427511823],
            [76.85557556741419, 24.46446963451555],
            [76.92306954175635, 24.53170239185122],
            [76.96356592636165, 24.45606553984859],
            [77.0535578921512, 24.52119727351752],
            [77.06480688787491, 24.636753575188205],
            [76.80607998622993, 24.821643657861298],
            [76.9545667297827, 24.86786617852957],
            [76.8735739605721, 25.00653374053439],
            [77.1750470459671, 25.109483900204637],
            [77.30778519550671, 25.07796854520354],
            [77.39102776386204, 25.117887994871595],
            [77.41352575530944, 25.22083815454184],
            [77.31228479379618, 25.43094052121581],
            [77.15929845195393, 25.309081148544905],
            [76.96356592636165, 25.29227295921099],
            [76.6080976614929, 25.384718000547537],
            [76.52485509313757, 25.525486586219095],
            [76.53385428971653, 25.792316591895037],
            [76.79933058879571, 25.939388248566814],
            [76.84657637083524, 26.033934313570104],
            [76.85332576826944, 26.036035337236843],
            [77.1165522682039, 26.218824396243196],
            [77.11880206734864, 26.227228490910154],
            [77.82073940050716, 26.548685111921326],
            [78.00072333208628, 26.697857792259846],
            [78.09746469531005, 26.681049602925928],
            [78.10421409274427, 26.783999762596174],
            [78.21895384912595, 26.830222283264447],
            [78.3719401909682, 26.86593968559902],
            [78.5744221139947, 26.752484407595077],
            [78.7251586566922, 26.79450488092987],
            [78.99288475491613, 26.681049602925928],
            [79.12562290445572, 26.349087863581058],
            [78.76790484044224, 25.716679739892406],
            [78.80165182761333, 25.611628556555424],
            [78.48892974649462, 25.575911154220847],
            [78.34269280208659, 25.42463745021559],
            [78.44843336188933, 25.151504373539428],
            [78.17620766537591, 24.880472320530007],
            [78.27294902859968, 24.66406688285582],
            [78.27294902859968, 24.44345939784815],
            [78.39218838327085, 24.269074433508756],
            [78.50692813965253, 24.39093380617966],
            [78.79040283188964, 24.18083143950569],
            [78.97488636175822, 24.353115380178345],
            [78.88714419511341, 24.634652551521466],
            [78.75890564386329, 24.59893514918689],
            [78.77240443873173, 24.85736106019587],
            [78.63066709261318, 24.958210196199378],
            [78.64866548577109, 25.056958308536142],
            [78.52717633195519, 25.28176784087729],
            [78.61041890031052, 25.412031308215152],
            [78.77240443873173, 25.35320264554644],
            [78.73190805412642, 25.458253828883425],
            [78.9501385711661, 25.525486586219095],
            [78.79940202846859, 25.288070911877508],
            [78.87589519938972, 25.185120752207265],
            [79.03338113952144, 25.2292422492088],
            [79.1413714984689, 25.105281852871155],
            [79.2853586437322, 25.115786971204855],
            [79.34835301978488, 25.22714122554206],
            [79.26736025057428, 25.304879101211426],
            [79.34160362235066, 25.327990361545563],
            [79.44284458386392, 25.237646343875756],
            [79.39559880182439, 25.115786971204855],
            [79.49683976333765, 25.07796854520354],
            [79.5575843402456, 25.164110515539868],
            [79.83430963504848, 25.094776734537458],
            [79.8523080282064, 25.231343272875538],
            [80.19652729735144, 25.401526189881455],
            [80.31801645116734, 25.386819024214276],
            [80.32026625031207, 25.27756579354381],
            [80.41025821610164, 25.21663610720836],
            [80.35626303662791, 25.136797207872252],
            [80.48450158787801, 24.989725551200472],
            [80.75897708353617, 25.136797207872252],
            [80.87821643820732, 25.067463426869843],
            [80.86471764333889, 24.932997912198502],
            [81.17069032702338, 24.9540081488659],
            [81.2764308868261, 25.16200949187313],
            [81.44741562182627, 25.109483900204637],
            [81.57565417307639, 25.191423823207483],
            [81.65439714314225, 25.07166547420332],
            [81.90862444649774, 24.979220432866775],
            [81.91087424564247, 24.880472320530007],
            [82.21684692932696, 24.794330350193682],
            [82.30683889511653, 24.626248456854505],
            [82.41707905320872, 24.59683412552015],
            [82.42832804893243, 24.701885308857136],
            [82.77254731807747, 24.640955622521684],
            [82.71630233945899, 24.079982303502184],
            [82.81529350182751, 23.962324978164762],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Maharashtra',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [80.66448551945713, 21.33184334740666],
            [80.64648712629922, 21.25410547173729],
            [80.46425339557537, 21.172165548734444],
            [80.43050640840428, 21.010386726395488],
            [80.4665031947201, 20.92844680339264],
            [80.54299636564123, 20.932648850726117],
            [80.62398913485183, 20.327554034705088],
            [80.390010023799, 20.2435130880355],
            [80.5294975707728, 20.125855762698073],
            [80.49575058360172, 19.896844183023447],
            [80.54749596393071, 19.777085834019285],
            [80.67348471603609, 19.69514591101644],
            [80.66898511774662, 19.61320598801359],
            [80.89846463050998, 19.468235355008552],
            [80.79497386985199, 19.430416929007237],
            [80.84671925018098, 19.358982124338084],
            [80.75672728439142, 19.289648343335678],
            [80.6104903399834, 19.31486062733655],
            [80.56549435708862, 19.40100259767288],
            [80.39450962208846, 19.245526846334144],
            [80.2752702674173, 18.991302982658638],
            [80.35401323748316, 18.814816994652503],
            [80.2752702674173, 18.722371953315957],
            [80.12228392557506, 18.680351479981162],
            [79.93780039570646, 18.78750368698489],
            [79.93330079741699, 19.045929597993872],
            [79.86580682307482, 19.106859284329325],
            [79.94904939143017, 19.17829408899847],
            [79.9805465794565, 19.403103621339618],
            [79.78481405386422, 19.600599846013154],
            [79.476591571035, 19.499750710009646],
            [79.26061085314007, 19.61320598801359],
            [79.19536667794263, 19.45983126034159],
            [79.02438194294248, 19.54177118334444],
            [78.97488636175822, 19.651024414014906],
            [78.86464620366601, 19.657327485015124],
            [78.85114740879759, 19.760277644685367],
            [78.40343737899454, 19.838015520354737],
            [78.29094742175761, 19.68884284001622],
            [78.31344541320499, 19.457730236674852],
            [78.19195625938909, 19.3968005503394],
            [78.18745666109962, 19.243425822667405],
            [77.95347755004677, 19.34217393500417],
            [77.8634855842572, 19.302254485336114],
            [77.77124381932292, 18.98289888799168],
            [77.90173216971777, 18.869443609987737],
            [77.73749683215183, 18.676149432647684],
            [77.75099562702027, 18.590007462311355],
            [77.60025908432276, 18.54798698897656],
            [77.55301330228325, 18.293763125301062],
            [77.4697707339279, 18.262247770299965],
            [77.31678439208567, 18.44083478197284],
            [77.06030728958542, 18.1529945396295],
            [76.95681652892743, 18.186610918297333],
            [76.9140703451774, 17.938690125622053],
            [76.69359002899299, 17.707577522280687],
            [76.52485509313757, 17.760103113949178],
            [76.4168647341901, 17.60672838627718],
            [76.33137236669003, 17.59622326794348],
            [76.38311774701901, 17.312585072933622],
            [76.11989124708457, 17.369312711935592],
            [75.93090811892651, 17.32519121493406],
            [75.89266153346594, 17.407131137936908],
            [75.80941896511061, 17.37141373560233],
            [75.63393463182096, 17.47856594260606],
            [75.66768161899205, 16.98482538092223],
            [75.2829659652417, 16.955411049587873],
            [75.22672098662323, 16.84195577158393],
            [74.99949127300461, 16.947006954920916],
            [74.91174910635979, 16.787329156248695],
            [74.77451135853072, 16.751611753914123],
            [74.56752983721474, 16.55411552924059],
            [74.54728164491209, 16.6339544285767],
            [74.48878686714887, 16.62975238124322],
            [74.26830655096447, 16.522600174239496],
            [74.34254992274084, 16.29358859456487],
            [74.5022856620173, 16.22425481356246],
            [74.48428726885939, 16.08978929889112],
            [74.34479972188558, 15.766231654213206],
            [74.16031619201699, 15.751524488546027],
            [74.1198198074117, 15.652776376209262],
            [73.99383105530632, 15.610755902874468],
            [73.85659330747724, 15.799848032881041],
            [73.67435957675339, 15.72841322821189],
            [73.48762624774007, 15.976334020887174],
            [73.31439171359517, 16.539408363573415],
            [73.35263829905574, 16.61924726290952],
            [73.24239814096353, 17.32098916760058],
            [72.89142947438427, 18.459743994973497],
            [72.81718610260788, 19.12366747366324],
            [72.64395156846298, 19.840116544021477],
            [72.73394353425253, 19.909450325023887],
            [72.72944393596306, 20.136360881031774],
            [72.95892344872642, 20.21409875670114],
            [72.96567284616064, 20.180482378033307],
            [72.9746720427396, 20.17628033069983],
            [72.9746720427396, 20.15737111769917],
            [72.96117324787116, 20.130057810031555],
            [72.99267043589751, 20.119552691697855],
            [72.99492023504224, 20.121653715364594],
            [72.99941983333173, 20.138461904698513],
            [73.00616923076595, 20.136360881031774],
            [73.0039194316212, 20.125855762698073],
            [73.00841902991068, 20.10484552603068],
            [73.02866722221333, 20.07753221836306],
            [73.04216601708177, 20.067027100029364],
            [73.07816280339759, 20.094340407696983],
            [73.09391139741076, 20.08803733669676],
            [73.10741019227919, 20.0796332420298],
            [73.1186591880029, 20.07753221836306],
            [73.13440778201605, 20.07753221836306],
            [73.15465597431871, 20.073330171029582],
            [73.1659049700424, 20.058623005362406],
            [73.20415155550296, 20.0901383603635],
            [73.20865115379245, 20.10694654969742],
            [73.20640135464771, 20.121653715364594],
            [73.30089291872675, 20.207795685700923],
            [73.41788247425316, 20.201492614700705],
            [73.44937966227951, 20.714142389385188],
            [73.73960375195082, 20.56707073271341],
            [73.9353362775431, 20.768769004720422],
            [73.79134913227982, 21.067114365397458],
            [73.62936359385861, 21.132246099066393],
            [73.81609692287195, 21.170064525067705],
            [73.8250961194509, 21.26671161373773],
            [73.94208567497732, 21.296125945072085],
            [73.95558446984576, 21.392773033742113],
            [74.07482382451691, 21.455803743744305],
            [74.30205353813554, 21.478915004078438],
            [74.28630494412238, 21.55665287974781],
            [73.8543435083325, 21.49572319341236],
            [73.81834672201668, 21.596572329415864],
            [73.88584069635885, 21.710027607419807],
            [73.82284632030616, 21.810876743423314],
            [74.14231779885908, 21.953746352761613],
            [74.28630494412238, 21.932736116094212],
            [74.38529610649088, 22.01887808643054],
            [74.5247836534647, 21.90752383209334],
            [74.50678526030678, 21.724734773086986],
            [74.58777802951738, 21.66170406308479],
            [75.05573625162307, 21.562955950748027],
            [75.21772179004428, 21.40958122307603],
            [76.09964305478192, 21.371762797074716],
            [76.185135422282, 21.08812460206486],
            [76.37861814872954, 21.075518460064416],
            [76.49110810596649, 21.19527680906858],
            [76.62384625550608, 21.191074761735102],
            [76.62609605465082, 21.327641300073182],
            [76.79483099050624, 21.4873190987454],
            [76.79708078965098, 21.594471305749124],
            [77.28078760576983, 21.75625012808808],
            [77.50126792195425, 21.74784603342112],
            [77.57101169544116, 21.527238548413454],
            [77.42702455017786, 21.541945714080633],
            [77.4405233450463, 21.47051090941148],
            [77.67450245609915, 21.371762797074716],
            [77.90173216971777, 21.375964844408195],
            [78.17395786623118, 21.4978242170791],
            [78.18520686195487, 21.55665287974781],
            [78.42593537044193, 21.600774376749342],
            [78.44393376359984, 21.502026264412578],
            [78.93663977629767, 21.48521807507866],
            [78.9793859600477, 21.615481542416518],
            [79.2223642676795, 21.649097921084355],
            [79.2403626608374, 21.714229654753286],
            [79.49458996419291, 21.67220918141849],
            [79.58233213083773, 21.544046737747372],
            [79.73981807096945, 21.600774376749342],
            [79.86355702393008, 21.531440595746933],
            [80.26627107083834, 21.61968358975],
            [80.45525419899641, 21.403278152075814],
            [80.66448551945713, 21.33184334740666],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Manipur',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [94.68073419118042, 25.454051781549946],
            [94.59974142196982, 25.189322799540744],
            [94.74372856723312, 25.12839311320529],
            [94.73922896894364, 25.017038858868087],
            [94.26002175111425, 24.161922226505034],
            [94.158780789601, 23.84676867649408],
            [94.01479364433771, 23.928708599496925],
            [93.805562323877, 23.926607575830186],
            [93.76056634098222, 24.000143404166074],
            [93.4703422513109, 23.96652702549824],
            [93.41409727269243, 24.075780256168706],
            [93.33535430262657, 24.04846694850109],
            [92.9843856360473, 24.126204824170458],
            [93.00013423006047, 24.399337900846618],
            [93.09687559328424, 24.58002593618623],
            [93.11262418729741, 24.79853239752716],
            [93.19136715736327, 24.80273444486064],
            [93.245362336837, 25.000230669534172],
            [93.47484184960038, 25.304879101211426],
            [93.60757999913997, 25.1977268942077],
            [93.83705951190333, 25.559102964886932],
            [94.30051813571956, 25.48976918388452],
            [94.4715028707197, 25.615830603888902],
            [94.68073419118042, 25.454051781549946],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Meghalaya',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.43993424302049, 25.031746024535266],
            [92.07771658071752, 25.185120752207265],
            [91.64575514492766, 25.124191065871813],
            [91.26778888861152, 25.206130988874662],
            [90.44661220078183, 25.14310027887247],
            [89.91116000433396, 25.306980124878166],
            [89.81666844025494, 25.370010834880357],
            [89.8549150257155, 25.477163041884083],
            [89.88866201288658, 25.559102964886932],
            [90.00565156841301, 25.58431524888781],
            [89.89766120946554, 25.727184858226106],
            [90.11814152564995, 25.958297461567472],
            [90.48035918795291, 26.015025100569446],
            [90.74358568788736, 25.91417596456594],
            [90.88532303400592, 25.949893366900515],
            [91.0698065638745, 25.82383194689613],
            [91.15529893137457, 25.849044230897007],
            [91.22729250400623, 25.722982810892628],
            [91.33528286295369, 25.832236041563092],
            [91.47252061078277, 25.859549349230708],
            [91.47702020907224, 25.85534730189723],
            [91.57376157229601, 26.025530218903143],
            [91.63450614920396, 26.021328171569664],
            [91.63225635005922, 25.943590295900297],
            [91.72224831584879, 25.954095414233993],
            [91.8212394782173, 26.11797526023969],
            [91.88648365341471, 26.027631242569882],
            [92.17895754223078, 26.078055810571637],
            [92.25770051229664, 26.027631242569882],
            [92.16770854650709, 25.96249950890095],
            [92.17220814479656, 25.664154148223915],
            [92.4174362515731, 25.737689976559803],
            [92.58842098657325, 25.55069887021997],
            [92.6086691788759, 25.41623335554863],
            [92.79315270874449, 25.28176784087729],
            [92.43993424302049, 25.031746024535266],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Mizoram',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [93.00013423006047, 24.399337900846618],
            [92.9843856360473, 24.126204824170458],
            [93.33535430262657, 24.04846694850109],
            [93.42984586670559, 23.577837647151398],
            [93.39159928124504, 23.22066362380565],
            [93.29935751631075, 23.00425818613146],
            [93.13062258045532, 23.044177635799514],
            [93.15986996933694, 22.909712121128173],
            [93.09687559328424, 22.806761961457926],
            [93.1981165547975, 22.277303997439525],
            [93.15986996933694, 22.17855588510276],
            [93.05187961038946, 22.199566121770154],
            [92.99788443091573, 22.052494465098377],
            [92.892143871113, 22.010473991763583],
            [92.70541054209968, 22.15124257743514],
            [92.68741214894176, 22.03988832309794],
            [92.59966998229694, 22.010473991763583],
            [92.52092701223108, 22.701710778120944],
            [92.37469006782305, 22.93492440512905],
            [92.39268846098096, 23.262684097140443],
            [92.26894950802033, 23.724909303823175],
            [92.32969408492828, 23.909799386496267],
            [92.29594709775719, 24.250165220508098],
            [92.42193584986258, 24.250165220508098],
            [92.46243223446788, 24.134608918837415],
            [92.61091897802063, 24.250165220508098],
            [92.76390531986289, 24.51909624985078],
            [92.83139929420506, 24.39723687717988],
            [93.00013423006047, 24.399337900846618],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Nagaland',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [95.23643457993093, 26.681049602925928],
            [95.0676996440755, 26.46044211791826],
            [95.13519361841767, 26.380603218582152],
            [95.12619442183872, 26.092762976238813],
            [95.18468919960193, 26.078055810571637],
            [95.01370446460177, 25.865852420230926],
            [95.0497012509176, 25.75449816589372],
            [94.81122254157528, 25.48766816021778],
            [94.68073419118042, 25.454051781549946],
            [94.4715028707197, 25.615830603888902],
            [94.30051813571956, 25.48976918388452],
            [93.83705951190333, 25.559102964886932],
            [93.60757999913997, 25.1977268942077],
            [93.47484184960038, 25.304879101211426],
            [93.45684345644247, 25.437243592216028],
            [93.33310450348182, 25.546496822886493],
            [93.70657116150848, 25.849044230897007],
            [93.68857276835057, 25.90577186989898],
            [93.78531413157434, 25.954095414233993],
            [93.78081453328487, 25.84274115989679],
            [93.98104665716663, 25.9225800592329],
            [94.01254384519297, 26.18100597024188],
            [94.28251974256165, 26.559190230255027],
            [94.32526592631167, 26.475149283585438],
            [94.41075829381175, 26.611715821923518],
            [94.80222334499632, 26.800807951930093],
            [94.8877157124964, 26.926869371934472],
            [95.08794783637816, 26.94577858493513],
            [95.19593819532562, 27.036122602604937],
            [95.23643457993093, 26.681049602925928],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Delhi',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.1975450374145, 28.861912169001734],
            [77.33703258438831, 28.67912310999538],
            [77.33478278524358, 28.67702208632864],
            [77.348281580112, 28.502637121989245],
            [77.1750470459671, 28.40178798598574],
            [77.1165522682039, 28.487929956322066],
            [76.84207677254575, 28.578273973991873],
            [76.95681652892743, 28.67912310999538],
            [76.95231693063796, 28.81568964833346],
            [77.1975450374145, 28.861912169001734],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Puducherry',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [79.79156345129844, 10.992705883380616],
              [79.79156345129844, 10.986402812380396],
              [79.79381325044318, 10.984301788713656],
              [79.84330883162744, 11.001109978047573],
              [79.84105903248269, 10.994806907047355],
              [79.8523080282064, 10.992705883380616],
              [79.83655943419322, 10.82672501370818],
              [79.7600662632721, 10.896058794710589],
              [79.7600662632721, 10.906563913044288],
              [79.7600662632721, 10.908664936711027],
              [79.76231606241683, 10.910765960377766],
              [79.7600662632721, 10.914968007711245],
              [79.74431766925892, 10.912866984044506],
              [79.74656746840367, 10.917069031377986],
              [79.74206787011418, 10.919170055044725],
              [79.74431766925892, 10.921271078711465],
              [79.74206787011418, 10.923372102378206],
              [79.73981807096945, 10.921271078711465],
              [79.73531847267996, 10.921271078711465],
              [79.7375682718247, 10.925473126044945],
              [79.72631927610101, 10.927574149711685],
              [79.72856907524576, 10.933877220711903],
              [79.72181967781154, 10.933877220711903],
              [79.72631927610101, 10.942281315378862],
              [79.7308188743905, 10.942281315378862],
              [79.73981807096945, 10.950685410045821],
              [79.73531847267996, 10.9548874573793],
              [79.72631927610101, 10.950685410045821],
              [79.72181967781154, 10.950685410045821],
              [79.71956987866679, 10.96749359937974],
              [79.72856907524576, 10.96749359937974],
              [79.75781646412736, 10.959089504712779],
              [79.76231606241683, 10.959089504712779],
              [79.76231606241683, 10.965392575712999],
              [79.75781646412736, 10.971695646713219],
              [79.75556666498262, 10.973796670379958],
              [79.75781646412736, 10.982200765046915],
              [79.75556666498262, 10.984301788713656],
              [79.74656746840367, 10.986402812380396],
              [79.74656746840367, 10.996907930714094],
              [79.74656746840367, 11.001109978047573],
              [79.75331686583787, 11.003211001714313],
              [79.7600662632721, 11.001109978047573],
              [79.79156345129844, 10.992705883380616],
            ],
          ],
          [
            [
              [75.55744146089985, 11.725963143072768],
              [75.55969126004459, 11.71966007207255],
              [75.5551916617551, 11.71755904840581],
              [75.5484422643209, 11.715458024739071],
              [75.54619246517615, 11.715458024739071],
              [75.53944306774194, 11.713357001072332],
              [75.53269367030772, 11.71755904840581],
              [75.52819407201824, 11.71966007207255],
              [75.53044387116299, 11.728064166739507],
              [75.53044387116299, 11.730165190406248],
              [75.53719326859719, 11.746973379740165],
              [75.53269367030772, 11.751175427073646],
              [75.53269367030772, 11.755377474407124],
              [75.53494346945246, 11.757478498073866],
              [75.54394266603141, 11.759579521740605],
              [75.54619246517615, 11.755377474407124],
              [75.54619246517615, 11.740670308739947],
              [75.55744146089985, 11.725963143072768],
            ],
          ],
          [
            [
              [79.67457389577201, 11.805802042408878],
              [79.67682369491675, 11.814206137075836],
              [79.68807269064045, 11.812105113409096],
              [79.69032248978519, 11.807903066075617],
              [79.67457389577201, 11.805802042408878],
            ],
          ],
          [
            [
              [79.75331686583787, 11.837317397409972],
              [79.76456586156158, 11.831014326409754],
              [79.79606304958791, 11.826812279076275],
              [79.79381325044318, 11.833115350076493],
              [79.79831284873265, 11.837317397409972],
              [79.79606304958791, 11.78479180574148],
              [79.78256425471949, 11.782690782074742],
              [79.78031445557474, 11.780589758408002],
              [79.76231606241683, 11.791094876741699],
              [79.76231606241683, 11.78899385307496],
              [79.75781646412736, 11.778488734741261],
              [79.71282048123258, 11.793195900408438],
              [79.72406947695627, 11.8015999950754],
              [79.72631927610101, 11.805802042408878],
              [79.72631927610101, 11.807903066075617],
              [79.7308188743905, 11.824711255409536],
              [79.7308188743905, 11.837317397409972],
              [79.75331686583787, 11.837317397409972],
            ],
          ],
          [
            [
              [79.67007429748254, 11.814206137075836],
              [79.64532650689041, 11.816307160742575],
              [79.64082690860093, 11.822610231742795],
              [79.66107510090359, 11.839418421076711],
              [79.66107510090359, 11.826812279076275],
              [79.67232409662728, 11.822610231742795],
              [79.67007429748254, 11.814206137075836],
            ],
          ],
          [
            [
              [79.63632731031146, 11.881438894411506],
              [79.63632731031146, 11.887741965411726],
              [79.64307670774568, 11.885640941744985],
              [79.6498261051799, 11.881438894411506],
              [79.6498261051799, 11.875135823411288],
              [79.6565755026141, 11.870933776077809],
              [79.66332490004832, 11.860428657744109],
              [79.6273281137325, 11.852024563077151],
              [79.62057871629828, 11.862529681410848],
              [79.60258032314037, 11.85622661041063],
              [79.60033052399564, 11.875135823411288],
              [79.60033052399564, 11.879337870744767],
              [79.63182771202197, 11.887741965411726],
              [79.63632731031146, 11.881438894411506],
            ],
          ],
          [
            [
              [79.69032248978519, 11.971782912081313],
              [79.68357309235097, 11.952873699080655],
              [79.65432570346937, 11.957075746414136],
              [79.66107510090359, 11.963378817414355],
              [79.66332490004832, 11.967580864747834],
              [79.69032248978519, 11.971782912081313],
            ],
          ],
          [
            [
              [79.6273281137325, 12.030611574750026],
              [79.65432570346937, 12.024308503749806],
              [79.64082690860093, 11.99069212508197],
              [79.64082690860093, 11.973883935748052],
              [79.6498261051799, 11.961277793747616],
              [79.64757630603515, 11.952873699080655],
              [79.64307670774568, 11.944469604413698],
              [79.65882530175884, 11.948671651747176],
              [79.65882530175884, 11.944469604413698],
              [79.66107510090359, 11.940267557080219],
              [79.66107510090359, 11.93816653341348],
              [79.67232409662728, 11.933964486079999],
              [79.67232409662728, 11.921358344079561],
              [79.67232409662728, 11.915055273079343],
              [79.66332490004832, 11.915055273079343],
              [79.6273281137325, 11.902449131078903],
              [79.6273281137325, 11.904550154745642],
              [79.62057871629828, 11.9234593677463],
              [79.62057871629828, 11.940267557080219],
              [79.62057871629828, 11.944469604413698],
              [79.62957791287724, 11.944469604413698],
              [79.62957791287724, 11.952873699080655],
              [79.61832891715355, 11.969681888414573],
              [79.62057871629828, 11.986490077748492],
              [79.62507831458777, 11.988591101415231],
              [79.62057871629828, 12.00119724341567],
              [79.62057871629828, 12.009601338082629],
              [79.62282851544302, 12.013803385416107],
              [79.6273281137325, 12.030611574750026],
            ],
          ],
          [
            [
              [79.70607108379836, 12.011702361749368],
              [79.71732007952205, 12.00119724341567],
              [79.71732007952205, 11.99489417241545],
              [79.76681566070631, 11.99069212508197],
              [79.72856907524576, 11.969681888414573],
              [79.75106706669314, 11.942368580746958],
              [79.75106706669314, 11.921358344079561],
              [79.75556666498262, 11.921358344079561],
              [79.75556666498262, 11.9234593677463],
              [79.76231606241683, 11.944469604413698],
              [79.80731204531162, 11.963378817414355],
              [79.80731204531162, 11.971782912081313],
              [79.83205983590373, 11.954974722747396],
              [79.78031445557474, 11.847822515743673],
              [79.77356505814053, 11.837317397409972],
              [79.76681566070631, 11.839418421076711],
              [79.76231606241683, 11.845721492076931],
              [79.76456586156158, 11.849923539410412],
              [79.76906545985105, 11.85622661041063],
              [79.76681566070631, 11.873034799744548],
              [79.7600662632721, 11.873034799744548],
              [79.75781646412736, 11.868832752411068],
              [79.75331686583787, 11.849923539410412],
              [79.74431766925892, 11.845721492076931],
              [79.72181967781154, 11.860428657744109],
              [79.71956987866679, 11.852024563077151],
              [79.71282048123258, 11.866731728744329],
              [79.7083208829431, 11.868832752411068],
              [79.70607108379836, 11.887741965411726],
              [79.69257228892992, 11.883539918078245],
              [79.68582289149572, 11.881438894411506],
              [79.67457389577201, 11.885640941744985],
              [79.69482208807467, 11.894045036411946],
              [79.69482208807467, 11.900348107412164],
              [79.69482208807467, 11.906651178412382],
              [79.71732007952205, 11.917156296746082],
              [79.71732007952205, 11.889842989078465],
              [79.7308188743905, 11.889842989078465],
              [79.7308188743905, 11.900348107412164],
              [79.73306867353523, 11.904550154745642],
              [79.71956987866679, 11.927661415079779],
              [79.71057068208783, 11.933964486079999],
              [79.71282048123258, 11.93186346241326],
              [79.69257228892992, 11.952873699080655],
              [79.71732007952205, 11.965479841081095],
              [79.71057068208783, 11.975984959414792],
              [79.6565755026141, 11.988591101415231],
              [79.65882530175884, 12.00119724341567],
              [79.65882530175884, 12.00750031441589],
              [79.66107510090359, 12.011702361749368],
              [79.7083208829431, 12.026409527416545],
              [79.70607108379836, 12.011702361749368],
            ],
            [
              [79.77356505814053, 11.887741965411726],
              [79.78031445557474, 11.877236847078027],
              [79.78031445557474, 11.868832752411068],
              [79.78931365215371, 11.875135823411288],
              [79.79831284873265, 11.898247083745424],
              [79.79156345129844, 11.894045036411946],
              [79.78256425471949, 11.898247083745424],
              [79.77581485728527, 11.902449131078903],
              [79.77356505814053, 11.887741965411726],
            ],
          ],
          [
            [
              [79.84105903248269, 12.013803385416107],
              [79.84555863077217, 12.032712598416765],
              [79.85005822906166, 12.049520787750682],
              [79.87480601965378, 12.043217716750462],
              [79.84105903248269, 12.013803385416107],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Punjab',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [76.73858601188776, 30.939824575407293],
            [76.73858601188776, 30.935622528073814],
            [76.78358199478254, 30.904107173072717],
            [76.84207677254575, 30.76123756373442],
            [76.82632817853258, 30.763338587401158],
            [76.80607998622993, 30.763338587401158],
            [76.81057958451942, 30.782247800401816],
            [76.80158038794045, 30.778045753068337],
            [76.78583179392729, 30.780146776735076],
            [76.79033139221676, 30.780146776735076],
            [76.78808159307202, 30.784348824068555],
            [76.7588342041904, 30.790651895068773],
            [76.75433460590094, 30.786449847735295],
            [76.7520848067562, 30.782247800401816],
            [76.74308561017725, 30.778045753068337],
            [76.73183661445356, 30.765439611067897],
            [76.70483902471668, 30.75703551640094],
            [76.7295868153088, 30.717116066732885],
            [76.72733701616407, 30.710812995732667],
            [76.72733701616407, 30.708711972065927],
            [76.73858601188776, 30.706610948399188],
            [76.73858601188776, 30.700307877398966],
            [76.74308561017725, 30.69400480639875],
            [76.74533540932198, 30.69400480639875],
            [76.7588342041904, 30.68770173539853],
            [76.8330775759668, 30.681398664398312],
            [76.9320687383353, 30.502811652725438],
            [76.92981893919057, 30.494407558058477],
            [76.60359806320344, 30.25068881271667],
            [76.63959484951926, 30.151940700379907],
            [76.54510328544022, 30.07840487204402],
            [76.43711292649276, 30.14353660571295],
            [76.26387839234786, 30.116223298045334],
            [76.22113220859782, 29.83888817403569],
            [76.2143828111636, 29.84309022136917],
            [75.94440691379494, 29.729634943365227],
            [75.82966715741325, 29.811574866368076],
            [75.45395070024186, 29.78426155870046],
            [75.2312205849127, 29.605674547027586],
            [75.19297399945215, 29.836787150368952],
            [75.11198123024154, 29.80106974803438],
            [75.07598444392572, 29.876706600037007],
            [74.99949127300461, 29.85359533970287],
            [74.81050814484654, 29.99226290170769],
            [74.52928325175418, 29.943939357372678],
            [74.33130092701715, 29.954444475706374],
            [74.2998037389908, 29.956545499373114],
            [74.28855474326711, 29.956545499373114],
            [73.90158929037202, 29.971252665040293],
            [73.9758326621484, 30.19606219738144],
            [73.9060888886615, 30.35153794872018],
            [73.97133306385894, 30.48600346339152],
            [74.0680744270827, 30.519619842059353],
            [74.45953947826727, 30.969238906741648],
            [74.66877079872799, 31.055380877077976],
            [74.69126879017537, 31.128916705413864],
            [74.51803425603048, 31.137320800080825],
            [74.5540310423463, 31.366332379755452],
            [74.65527200385955, 31.462979468425477],
            [74.53603264918839, 31.681485929766406],
            [74.56977963635947, 31.830658610104926],
            [74.9409964952414, 32.06597326077977],
            [75.2829659652417, 32.120599876115],
            [75.37970732846547, 32.24035822511917],
            [75.33921094386018, 32.34330838478941],
            [75.48319808912346, 32.31809610078854],
            [75.72617639675526, 32.4168442131253],
            [75.88816193517647, 32.57652201179752],
            [75.9421571146502, 32.41054114212508],
            [75.63843423011045, 32.23405515411895],
            [75.62493543524201, 32.0995896394476],
            [75.90841012747912, 31.94831593544235],
            [76.21888240945307, 31.30330166975326],
            [76.44161252478223, 31.322210882753918],
            [76.65084384524295, 31.208755604749975],
            [76.62384625550608, 31.000754261742745],
            [76.73858601188776, 30.939824575407293],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Rajasthan',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.28855474326711, 29.956545499373114],
            [74.2998037389908, 29.956545499373114],
            [74.33130092701715, 29.954444475706374],
            [74.52928325175418, 29.943939357372678],
            [74.56303023892525, 29.864100458036567],
            [74.48203746971465, 29.742241085365666],
            [74.61477561925425, 29.752746203699363],
            [74.57877883293843, 29.406077298687315],
            [74.7857603542544, 29.357753754352302],
            [74.85775392688605, 29.401875251353836],
            [75.08723343964942, 29.22959131068118],
            [75.38870652504443, 29.261106665682277],
            [75.37070813188652, 29.139247293011373],
            [75.4449515036629, 29.013185873006993],
            [75.51919487543928, 29.00688280200677],
            [75.56419085833406, 28.61189035265971],
            [75.89716113175542, 28.389181843985302],
            [76.02764948215028, 28.277827589648098],
            [76.01640048642658, 28.248413258313743],
            [76.06139646932135, 28.218998926979385],
            [75.98265349925549, 27.859723879966896],
            [76.18063582399252, 27.802996240964926],
            [76.185135422282, 28.05511908097369],
            [76.34487116155846, 28.025704749639335],
            [76.36736915300585, 28.13916002764328],
            [76.48210890938753, 28.145463098643496],
            [76.538353888006, 27.973179157970844],
            [76.66884223840086, 28.013098607638895],
            [76.65759324267717, 28.086634435974787],
            [76.84207677254575, 28.218998926979385],
            [76.97031532379587, 28.13916002764328],
            [76.89607195201948, 27.71475324696186],
            [76.97931452037483, 27.651722536959667],
            [77.046808494717, 27.813501359298623],
            [77.28528720405932, 27.800895217298187],
            [77.348281580112, 27.519358045955066],
            [77.65200446465175, 27.23361882727847],
            [77.56651209715167, 27.044526697271895],
            [77.69925024669126, 26.973091892602746],
            [77.49001892623056, 26.897455040600118],
            [77.42477475103313, 26.838626377931405],
            [77.47202053307264, 26.765090549595516],
            [77.77349361846765, 26.93107141926795],
            [78.14696027649431, 26.95208165593535],
            [78.24145184057335, 26.926869371934472],
            [78.21895384912595, 26.830222283264447],
            [78.10421409274427, 26.783999762596174],
            [78.09746469531005, 26.681049602925928],
            [78.00072333208628, 26.697857792259846],
            [77.82073940050716, 26.548685111921326],
            [77.11880206734864, 26.227228490910154],
            [77.1165522682039, 26.218824396243196],
            [76.85332576826944, 26.036035337236843],
            [76.84657637083524, 26.033934313570104],
            [76.79933058879571, 25.939388248566814],
            [76.53385428971653, 25.792316591895037],
            [76.52485509313757, 25.525486586219095],
            [76.6080976614929, 25.384718000547537],
            [76.96356592636165, 25.29227295921099],
            [77.15929845195393, 25.309081148544905],
            [77.31228479379618, 25.43094052121581],
            [77.41352575530944, 25.22083815454184],
            [77.39102776386204, 25.117887994871595],
            [77.30778519550671, 25.07796854520354],
            [77.1750470459671, 25.109483900204637],
            [76.8735739605721, 25.00653374053439],
            [76.9545667297827, 24.86786617852957],
            [76.80607998622993, 24.821643657861298],
            [77.06480688787491, 24.636753575188205],
            [77.0535578921512, 24.52119727351752],
            [76.96356592636165, 24.45606553984859],
            [76.92306954175635, 24.53170239185122],
            [76.85557556741419, 24.46446963451555],
            [76.84207677254575, 24.357317427511823],
            [76.94781733234848, 24.193437581506128],
            [76.90507114859844, 24.126204824170458],
            [76.79483099050624, 24.12200277683698],
            [76.6193466572166, 24.25856931517506],
            [76.52710489228231, 24.15772017917155],
            [76.46636031537436, 24.224952936507222],
            [76.20988321287413, 24.22705396017396],
            [76.12664064451879, 24.201841676173085],
            [76.13788964024248, 24.09048742183588],
            [75.96240530695285, 24.02115364083347],
            [75.97815390096602, 23.928708599496925],
            [75.65193302497887, 23.796344108492324],
            [75.45845029853133, 23.91820348116323],
            [75.51694507629455, 24.044264901167608],
            [75.76442298221582, 23.993840333165856],
            [75.73742539247895, 24.399337900846618],
            [75.90616032833438, 24.441358374181412],
            [75.92865831978176, 24.53380341551796],
            [75.84091615313694, 24.724996569191273],
            [75.74417478991317, 24.756511924192367],
            [75.61593623866305, 24.685077119523218],
            [75.2312205849127, 24.73129964019149],
            [75.24471937978115, 24.85736106019587],
            [75.30321415754435, 24.811138539527597],
            [75.3122133541233, 25.042251142868967],
            [75.15697721313633, 25.025442953535048],
            [75.1209804268205, 24.88467436786349],
            [75.04448725589938, 24.85526003652913],
            [74.86900292260975, 24.932997912198502],
            [74.8600037260308, 24.809037515860858],
            [75.01299006787303, 24.7901283028602],
            [75.00174107214934, 24.706087356190615],
            [74.87350252089922, 24.66616790652256],
            [74.81950734142549, 24.762814995192585],
            [74.71376678162277, 24.506490107850343],
            [74.7160165807675, 24.504389084183604],
            [74.85775392688605, 24.44766144518163],
            [74.75426316622806, 24.277478528175717],
            [74.7677619610965, 24.271175457175495],
            [74.87575232004396, 24.275377504508977],
            [74.99274187557039, 24.027456711833693],
            [74.90499970892557, 23.863576865827994],
            [74.92299810208348, 23.666080641154466],
            [74.85100452945184, 23.55472638681726],
            [74.61252582010951, 23.458079298147233],
            [74.53153305089891, 23.319411736142413],
            [74.66877079872799, 23.197552363471512],
            [74.53378285004365, 23.098804251134744],
            [74.32005193129346, 23.060985825133432],
            [74.24805835866181, 23.176542126804115],
            [74.12431940570117, 23.176542126804115],
            [74.13331860228013, 23.262684097140443],
            [74.02082864504318, 23.332017878142853],
            [74.00058045274054, 23.334118901809592],
            [73.89034029464833, 23.336219925476332],
            [73.82284632030616, 23.445473156146797],
            [73.62936359385861, 23.451776227147015],
            [73.65636118359548, 23.62195914415293],
            [73.35713789734521, 23.853071747494297],
            [73.41113307681894, 24.05056797216783],
            [73.24914753839775, 24.010648522499775],
            [73.07816280339759, 24.18503348683917],
            [73.18390336320032, 24.334206167177687],
            [73.08041260254232, 24.39513585351314],
            [73.09166159826603, 24.493883965849903],
            [72.97692184188433, 24.45186349251511],
            [72.97242224359486, 24.359418451178563],
            [72.72719413681833, 24.361519474845302],
            [72.53596120951552, 24.506490107850343],
            [72.4594680385944, 24.407741995513575],
            [72.22323912839681, 24.634652551521466],
            [71.48080541063298, 24.678774048522996],
            [71.28957248333018, 24.61364231485407],
            [71.09833955602737, 24.685077119523218],
            [70.88460863727717, 25.151504373539428],
            [70.66412832109276, 25.397324142547973],
            [70.67312751767172, 25.670457219224133],
            [70.27491306905293, 25.70827564522545],
            [70.0994287357633, 25.935186201233336],
            [70.17367210753969, 26.550786135588066],
            [69.80020544951303, 26.59910967992308],
            [69.51448095813119, 26.74197928926138],
            [69.59097412905231, 27.183194259276718],
            [70.0251853639869, 27.56137851928986],
            [70.13542552207912, 27.807198288298405],
            [70.37390423142145, 28.013098607638895],
            [70.59213474846112, 28.010997583972156],
            [70.76761908175075, 27.72525836529556],
            [70.90485682957981, 27.71055119962838],
            [71.20857971411957, 27.83241057229928],
            [71.65853954306735, 27.874431045634076],
            [71.89926805155442, 27.962674039637143],
            [71.9330150387255, 28.128654909309578],
            [72.2052407352389, 28.39128286765204],
            [72.31098129504163, 28.6854261809956],
            [72.94992425214747, 29.032095086007647],
            [73.27164552984513, 29.546845884358873],
            [73.39313468366103, 29.9292321917055],
            [73.9758326621484, 30.19606219738144],
            [73.90158929037202, 29.971252665040293],
            [74.28855474326711, 29.956545499373114],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Sikkim',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.75926284222766, 27.14747685694214],
            [88.59727730380646, 27.193699377610415],
            [88.43979136367474, 27.08234512327321],
            [88.16081626972712, 27.111759454607565],
            [88.01682912446383, 27.21681063794455],
            [88.04832631249018, 27.49414576195419],
            [88.20131265433241, 27.85131978529994],
            [88.13381867999024, 27.872330021967336],
            [88.14731747485868, 27.962674039637143],
            [88.3970451799247, 27.977381205304322],
            [88.64677288499072, 28.10554364897544],
            [88.75926284222766, 28.074028293974347],
            [88.88750139347778, 27.895441282301473],
            [88.77501143624083, 27.5634795429566],
            [88.90774958578042, 27.273538276946525],
            [88.80875842341192, 27.246224969278906],
            [88.75926284222766, 27.14747685694214],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Tamil Nadu',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [79.78256425471949, 11.898247083745424],
              [79.79156345129844, 11.894045036411946],
              [79.79831284873265, 11.898247083745424],
              [79.78931365215371, 11.875135823411288],
              [79.78031445557474, 11.868832752411068],
              [79.78031445557474, 11.877236847078027],
              [79.77356505814053, 11.887741965411726],
              [79.77581485728527, 11.902449131078903],
              [79.78256425471949, 11.898247083745424],
            ],
          ],
          [
            [
              [79.69482208807467, 11.885640941744985],
              [79.70607108379836, 11.887741965411726],
              [79.7083208829431, 11.868832752411068],
              [79.71282048123258, 11.866731728744329],
              [79.71956987866679, 11.852024563077151],
              [79.72181967781154, 11.860428657744109],
              [79.74431766925892, 11.845721492076931],
              [79.75331686583787, 11.849923539410412],
              [79.75781646412736, 11.868832752411068],
              [79.7600662632721, 11.873034799744548],
              [79.76681566070631, 11.873034799744548],
              [79.76906545985105, 11.85622661041063],
              [79.76456586156158, 11.849923539410412],
              [79.76231606241683, 11.845721492076931],
              [79.76681566070631, 11.839418421076711],
              [79.77356505814053, 11.837317397409972],
              [79.77581485728527, 11.837317397409972],
              [79.78031445557474, 11.847822515743673],
              [79.79831284873265, 11.837317397409972],
              [79.79381325044318, 11.833115350076493],
              [79.79606304958791, 11.826812279076275],
              [79.76456586156158, 11.831014326409754],
              [79.75331686583787, 11.837317397409972],
              [79.7308188743905, 11.837317397409972],
              [79.7308188743905, 11.824711255409536],
              [79.72631927610101, 11.807903066075617],
              [79.72631927610101, 11.805802042408878],
              [79.72406947695627, 11.8015999950754],
              [79.71282048123258, 11.793195900408438],
              [79.75781646412736, 11.778488734741261],
              [79.76231606241683, 11.78899385307496],
              [79.76231606241683, 11.791094876741699],
              [79.78031445557474, 11.780589758408002],
              [79.78256425471949, 11.782690782074742],
              [79.8523080282064, 10.992705883380616],
              [79.84105903248269, 10.994806907047355],
              [79.84330883162744, 11.001109978047573],
              [79.79381325044318, 10.984301788713656],
              [79.79156345129844, 10.986402812380396],
              [79.79156345129844, 10.992705883380616],
              [79.7600662632721, 11.001109978047573],
              [79.75331686583787, 11.003211001714313],
              [79.74656746840367, 11.001109978047573],
              [79.74656746840367, 10.996907930714094],
              [79.74656746840367, 10.986402812380396],
              [79.75556666498262, 10.984301788713656],
              [79.75781646412736, 10.982200765046915],
              [79.75556666498262, 10.973796670379958],
              [79.75781646412736, 10.971695646713219],
              [79.76231606241683, 10.965392575712999],
              [79.76231606241683, 10.959089504712779],
              [79.75781646412736, 10.959089504712779],
              [79.72856907524576, 10.96749359937974],
              [79.71956987866679, 10.96749359937974],
              [79.72181967781154, 10.950685410045821],
              [79.72631927610101, 10.950685410045821],
              [79.73531847267996, 10.9548874573793],
              [79.73981807096945, 10.950685410045821],
              [79.7308188743905, 10.942281315378862],
              [79.72631927610101, 10.942281315378862],
              [79.72181967781154, 10.933877220711903],
              [79.72856907524576, 10.933877220711903],
              [79.72631927610101, 10.927574149711685],
              [79.7375682718247, 10.925473126044945],
              [79.73531847267996, 10.921271078711465],
              [79.73981807096945, 10.921271078711465],
              [79.74206787011418, 10.923372102378206],
              [79.74431766925892, 10.921271078711465],
              [79.74206787011418, 10.919170055044725],
              [79.74656746840367, 10.917069031377986],
              [79.74431766925892, 10.912866984044506],
              [79.7600662632721, 10.914968007711245],
              [79.76231606241683, 10.910765960377766],
              [79.7600662632721, 10.908664936711027],
              [79.7600662632721, 10.906563913044288],
              [79.7600662632721, 10.896058794710589],
              [79.83655943419322, 10.82672501370818],
              [79.87930561794326, 10.290963978689556],
              [79.38884940439019, 10.322479333690652],
              [79.27635944715324, 10.238438387021064],
              [79.2628606522848, 10.036740115014053],
              [78.92539078057396, 9.585020026665019],
              [78.90064298998183, 9.456857582993896],
              [78.98838515662666, 9.276169547654282],
              [78.42143577215245, 9.114390725315326],
              [78.21220445169173, 8.96101599764333],
              [78.13346148162587, 8.498790790960594],
              [78.04121971669159, 8.358022205289036],
              [77.55526310142798, 8.078586057612656],
              [77.31678439208567, 8.12060653094745],
              [77.10080367419073, 8.292890471620105],
              [77.28303740491458, 8.547114335295609],
              [77.19529523826976, 8.7425095363024],
              [77.26503901175667, 8.839156624972425],
              [77.15029925537499, 9.019844660312039],
              [77.26953861004614, 9.15220915131664],
              [77.40227675958573, 9.515686245662607],
              [77.18179644340132, 9.656454831334168],
              [77.27628800748036, 9.973709405011862],
              [77.20204463570397, 10.103972872349724],
              [77.2672888109014, 10.12078106168364],
              [77.26503901175667, 10.26365067102194],
              [77.17729684511185, 10.356095712358487],
              [76.995063114388, 10.227933268687366],
              [76.83082777682206, 10.303570120689994],
              [76.90957074688792, 10.778401469373165],
              [76.65759324267717, 10.935978244378642],
              [76.7520848067562, 11.116666279718256],
              [76.45286152050592, 11.207010297388063],
              [76.54735308458496, 11.360385025060062],
              [76.2661281914926, 11.480143374064225],
              [76.23238120432151, 11.570487391734032],
              [76.43261332820327, 11.667134480404059],
              [76.51360609741387, 11.70495290640537],
              [76.5608518794534, 11.620911959735784],
              [76.86007516570366, 11.608305817735346],
              [76.90957074688792, 11.791094876741699],
              [77.44277314419104, 11.780589758408002],
              [77.50126792195425, 11.93816653341348],
              [77.67450245609915, 11.963378817414355],
              [77.77799321675712, 12.118854568753093],
              [77.73749683215183, 12.175582207755063],
              [77.47652013136212, 12.21129961008964],
              [77.6137578791912, 12.362573314094897],
              [77.60700848175698, 12.629403319770837],
              [77.75999482359921, 12.696636077106508],
              [77.8342381953756, 12.868920017779164],
              [78.23245264399439, 12.761767810775439],
              [78.38093938754716, 12.62099922510388],
              [78.56317311827101, 12.707141195440208],
              [78.63066709261318, 12.984476319449847],
              [78.81290082333702, 13.087426479120094],
              [79.16611928906103, 13.020193721784423],
              [79.23811286169267, 13.146255141788803],
              [79.43384538728496, 13.196679709790557],
              [79.42934578899548, 13.333246248128637],
              [79.68357309235097, 13.24920530145905],
              [79.93780039570646, 13.347953413795814],
              [80.01879316491707, 13.501328141467813],
              [80.22577468623305, 13.484519952133894],
              [80.27077066912783, 13.562257827803265],
              [80.34051444261473, 13.270215538126447],
              [80.25502207511465, 12.768070881775659],
              [80.13803251958822, 12.431907095097307],
              [79.87480601965378, 12.043217716750462],
              [79.85005822906166, 12.049520787750682],
              [79.84555863077217, 12.032712598416765],
              [79.84105903248269, 12.013803385416107],
              [79.83205983590373, 11.954974722747396],
              [79.80731204531162, 11.971782912081313],
              [79.80731204531162, 11.963378817414355],
              [79.76231606241683, 11.944469604413698],
              [79.75556666498262, 11.9234593677463],
              [79.75556666498262, 11.921358344079561],
              [79.75106706669314, 11.921358344079561],
              [79.75106706669314, 11.942368580746958],
              [79.72856907524576, 11.969681888414573],
              [79.76681566070631, 11.99069212508197],
              [79.71732007952205, 11.99489417241545],
              [79.71732007952205, 12.00119724341567],
              [79.70607108379836, 12.011702361749368],
              [79.7083208829431, 12.026409527416545],
              [79.66107510090359, 12.011702361749368],
              [79.65882530175884, 12.00750031441589],
              [79.65882530175884, 12.00119724341567],
              [79.6565755026141, 11.988591101415231],
              [79.71057068208783, 11.975984959414792],
              [79.71732007952205, 11.965479841081095],
              [79.69257228892992, 11.952873699080655],
              [79.71282048123258, 11.93186346241326],
              [79.71057068208783, 11.933964486079999],
              [79.71956987866679, 11.927661415079779],
              [79.73306867353523, 11.904550154745642],
              [79.7308188743905, 11.900348107412164],
              [79.7308188743905, 11.889842989078465],
              [79.71732007952205, 11.889842989078465],
              [79.71732007952205, 11.917156296746082],
              [79.69482208807467, 11.906651178412382],
              [79.69482208807467, 11.900348107412164],
              [79.69482208807467, 11.894045036411946],
              [79.67457389577201, 11.885640941744985],
              [79.68582289149572, 11.881438894411506],
              [79.69257228892992, 11.883539918078245],
              [79.69482208807467, 11.885640941744985],
            ],
            [
              [79.64307670774568, 11.885640941744985],
              [79.63632731031146, 11.887741965411726],
              [79.63632731031146, 11.881438894411506],
              [79.63182771202197, 11.887741965411726],
              [79.60033052399564, 11.879337870744767],
              [79.60033052399564, 11.875135823411288],
              [79.60258032314037, 11.85622661041063],
              [79.62057871629828, 11.862529681410848],
              [79.6273281137325, 11.852024563077151],
              [79.66332490004832, 11.860428657744109],
              [79.6565755026141, 11.870933776077809],
              [79.6498261051799, 11.875135823411288],
              [79.6498261051799, 11.881438894411506],
              [79.64307670774568, 11.885640941744985],
            ],
            [
              [79.66332490004832, 11.915055273079343],
              [79.67232409662728, 11.915055273079343],
              [79.67232409662728, 11.921358344079561],
              [79.67232409662728, 11.933964486079999],
              [79.66107510090359, 11.93816653341348],
              [79.66107510090359, 11.940267557080219],
              [79.65882530175884, 11.944469604413698],
              [79.65882530175884, 11.948671651747176],
              [79.64307670774568, 11.944469604413698],
              [79.64757630603515, 11.952873699080655],
              [79.6498261051799, 11.961277793747616],
              [79.64082690860093, 11.973883935748052],
              [79.64082690860093, 11.99069212508197],
              [79.65432570346937, 12.024308503749806],
              [79.6273281137325, 12.030611574750026],
              [79.62282851544302, 12.013803385416107],
              [79.62057871629828, 12.009601338082629],
              [79.62057871629828, 12.00119724341567],
              [79.62507831458777, 11.988591101415231],
              [79.62057871629828, 11.986490077748492],
              [79.61832891715355, 11.969681888414573],
              [79.62957791287724, 11.952873699080655],
              [79.62957791287724, 11.944469604413698],
              [79.62057871629828, 11.944469604413698],
              [79.62057871629828, 11.940267557080219],
              [79.62057871629828, 11.9234593677463],
              [79.6273281137325, 11.904550154745642],
              [79.6273281137325, 11.902449131078903],
              [79.66332490004832, 11.915055273079343],
            ],
            [
              [79.66332490004832, 11.967580864747834],
              [79.66107510090359, 11.963378817414355],
              [79.65432570346937, 11.957075746414136],
              [79.68357309235097, 11.952873699080655],
              [79.69032248978519, 11.971782912081313],
              [79.66332490004832, 11.967580864747834],
            ],
            [
              [79.67007429748254, 11.814206137075836],
              [79.67232409662728, 11.822610231742795],
              [79.66107510090359, 11.826812279076275],
              [79.66107510090359, 11.839418421076711],
              [79.64082690860093, 11.822610231742795],
              [79.64532650689041, 11.816307160742575],
              [79.67007429748254, 11.814206137075836],
            ],
            [
              [79.67682369491675, 11.814206137075836],
              [79.67457389577201, 11.805802042408878],
              [79.69032248978519, 11.807903066075617],
              [79.68807269064045, 11.812105113409096],
              [79.67682369491675, 11.814206137075836],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Telangana',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [81.0559505706417, 17.791618468950272],
            [81.05820036978643, 17.75380004294896],
            [81.04245177577326, 17.73909287728178],
            [80.97270800228635, 17.774810279616354],
            [80.88721563478629, 17.722284687947862],
            [80.89171523307576, 17.67606216727959],
            [81.01770398518113, 17.52268743960759],
            [81.1256943441286, 17.524788463274334],
            [81.32592646801037, 17.390322948602993],
            [81.19993771590498, 17.33569633326776],
            [81.17069032702338, 17.241150268264473],
            [80.91421322452315, 17.20963491326338],
            [80.83322045531254, 17.04575506725768],
            [80.56774415623336, 17.146604203261184],
            [80.50474978018067, 17.10878577725987],
            [80.45075460070693, 16.953310025921134],
            [80.59249194682549, 16.934400812920476],
            [80.57224375452283, 16.77052096691478],
            [80.3270156477463, 16.879774197585242],
            [80.27077066912783, 17.018441759590065],
            [80.20327669478566, 17.024744830590283],
            [80.05478995123289, 16.97221923892179],
            [80.00079477175916, 16.871370102918284],
            [80.07953774182502, 16.820945534916532],
            [79.9512991905749, 16.64025749957692],
            [79.78256425471949, 16.736904588246944],
            [79.27410964800849, 16.56882269490777],
            [79.2336132634032, 16.257871192230294],
            [79.02888154123195, 16.251568121230076],
            [78.74540684899486, 16.028859612555667],
            [78.6441658874816, 16.098193393558077],
            [78.4124365755735, 16.08768827522438],
            [78.27519882774443, 16.041465754556107],
            [78.106463891889, 15.833464411548876],
            [77.51476671682268, 15.925909452885422],
            [77.4922687253753, 16.253669144896815],
            [77.59575948603329, 16.316699854899007],
            [77.28753700320405, 16.407043872568813],
            [77.25603981517772, 16.47217560623774],
            [77.42027515274364, 16.522600174239496],
            [77.45852173820421, 16.610843168242564],
            [77.42027515274364, 16.665469783577795],
            [77.50351772109899, 17.010037664923104],
            [77.37977876813835, 17.140301132260966],
            [77.38427836642782, 17.222241055263815],
            [77.66100366123071, 17.524788463274334],
            [77.44277314419104, 17.583617125943043],
            [77.46077153734895, 17.701274451280465],
            [77.54176430655954, 17.72858775894808],
            [77.52826551169112, 17.829436894951588],
            [77.65650406294124, 17.972306504289886],
            [77.55301330228325, 18.293763125301062],
            [77.60025908432276, 18.54798698897656],
            [77.75099562702027, 18.590007462311355],
            [77.73749683215183, 18.676149432647684],
            [77.90173216971777, 18.869443609987737],
            [77.77124381932292, 18.98289888799168],
            [77.8634855842572, 19.302254485336114],
            [77.95347755004677, 19.34217393500417],
            [78.18745666109962, 19.243425822667405],
            [78.19195625938909, 19.3968005503394],
            [78.31344541320499, 19.457730236674852],
            [78.29094742175761, 19.68884284001622],
            [78.40343737899454, 19.838015520354737],
            [78.85114740879759, 19.760277644685367],
            [78.86464620366601, 19.657327485015124],
            [78.97488636175822, 19.651024414014906],
            [79.02438194294248, 19.54177118334444],
            [79.19536667794263, 19.45983126034159],
            [79.26061085314007, 19.61320598801359],
            [79.476591571035, 19.499750710009646],
            [79.78481405386422, 19.600599846013154],
            [79.9805465794565, 19.403103621339618],
            [79.94904939143017, 19.17829408899847],
            [79.86580682307482, 19.106859284329325],
            [79.93330079741699, 19.045929597993872],
            [79.93780039570646, 18.78750368698489],
            [80.12228392557506, 18.680351479981162],
            [80.2752702674173, 18.722371953315957],
            [80.34951363919369, 18.592108485978095],
            [80.49575058360172, 18.63202793564615],
            [80.639737728865, 18.524875728642428],
            [80.7319794937993, 18.37360202463717],
            [80.72972969465457, 18.367298953636947],
            [80.79272407070725, 18.24964162829953],
            [80.74322848952299, 18.178206823630376],
            [80.98170719886531, 18.174004776296897],
            [80.98170719886531, 18.16560068162994],
            [81.05370077149696, 17.791618468950272],
            [81.0559505706417, 17.791618468950272],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Tripura',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.2329527217045, 24.502288060516864],
            [92.27344910630981, 24.38673175884618],
            [92.21270452940186, 24.24806419684136],
            [92.29594709775719, 24.250165220508098],
            [92.32969408492828, 23.909799386496267],
            [92.26894950802033, 23.724909303823175],
            [91.94047883288846, 23.663979617487723],
            [91.97872541834901, 23.48119055848137],
            [91.78749249104621, 23.306805594141977],
            [91.8212394782173, 23.098804251134744],
            [91.62100735433553, 22.94332849979601],
            [91.45677201676959, 23.258482049806965],
            [91.38477844413795, 23.195451339804773],
            [91.32628366637474, 23.239572836806307],
            [91.1620483288088, 23.600948907485535],
            [91.23404190144043, 23.88668812616213],
            [91.42752462788799, 24.10939663483654],
            [91.59175996545392, 24.075780256168706],
            [91.66375353808557, 24.18503348683917],
            [91.76724429874356, 24.149316084504594],
            [91.83248847394098, 24.214447818173525],
            [91.91573104229633, 24.147215060837855],
            [91.92698003802002, 24.340509238177905],
            [92.0934651747307, 24.374125616845742],
            [92.17220814479656, 24.422449161180754],
            [92.15420975163865, 24.504389084183604],
            [92.2329527217045, 24.502288060516864],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Uttar Pradesh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [80.07053854524607, 28.828295790333897],
            [80.37201163064107, 28.632900589327107],
            [80.5587449596544, 28.691729251995817],
            [80.90971362623367, 28.456414601320972],
            [81.21343651077342, 28.357666488984208],
            [81.33042606629984, 28.13705900397654],
            [81.42941722866836, 28.170675382644372],
            [81.4789128098526, 28.084533412308044],
            [81.89062605333983, 27.857622856300157],
            [82.07510958320842, 27.922754589969088],
            [82.45982523695876, 27.676934820960543],
            [82.68930474972213, 27.7168542706286],
            [82.7455497283406, 27.50044883295441],
            [83.18426056156468, 27.452125288619396],
            [83.3124991128148, 27.330265915948495],
            [83.40249107860436, 27.477337572620275],
            [83.6072228007756, 27.468933477953314],
            [83.86594970242058, 27.344973081615674],
            [83.99418825367069, 27.178992211943235],
            [83.95144206992065, 27.115961501941047],
            [84.06168222801286, 26.889050945933157],
            [84.23941636044722, 26.86593968559902],
            [84.42389989031582, 26.624321963923958],
            [84.16067339038136, 26.636928105924394],
            [83.91544528360483, 26.504563614919793],
            [83.91094568531535, 26.447835975917823],
            [84.18092158268402, 26.372199123915195],
            [84.16517298867085, 26.25874384591125],
            [84.00993684768386, 26.1873090412421],
            [84.05718262972337, 26.09696502357229],
            [84.63088141163179, 25.790215568228298],
            [84.52514085182906, 25.676760290224355],
            [84.40140189886843, 25.727184858226106],
            [84.387903104, 25.733487929226325],
            [84.32715852709205, 25.670457219224133],
            [84.07743082202603, 25.69356847955827],
            [84.08643001860499, 25.63473981688956],
            [83.78945653149945, 25.397324142547973],
            [83.35749509570958, 25.1977268942077],
            [83.32824770682798, 25.01283681153461],
            [83.39574168117014, 24.783825231859982],
            [83.46998505294653, 24.73340066385823],
            [83.47448465123601, 24.73340066385823],
            [83.526230031565, 24.552712628518616],
            [83.526230031565, 24.548510581185138],
            [83.50598183926235, 24.525399320851],
            [83.40024127945962, 24.500187036850125],
            [83.44298746320966, 24.346812309178127],
            [83.33049750597272, 24.10099254016958],
            [83.13476498038044, 23.88878914982887],
            [82.96153044623554, 23.871980960494955],
            [82.81529350182751, 23.962324978164762],
            [82.71630233945899, 24.079982303502184],
            [82.77254731807747, 24.640955622521684],
            [82.42832804893243, 24.701885308857136],
            [82.41707905320872, 24.59683412552015],
            [82.30683889511653, 24.626248456854505],
            [82.21684692932696, 24.794330350193682],
            [81.91087424564247, 24.880472320530007],
            [81.90862444649774, 24.979220432866775],
            [81.65439714314225, 25.07166547420332],
            [81.57565417307639, 25.191423823207483],
            [81.44741562182627, 25.109483900204637],
            [81.2764308868261, 25.16200949187313],
            [81.17069032702338, 24.9540081488659],
            [80.86471764333889, 24.932997912198502],
            [80.87821643820732, 25.067463426869843],
            [80.75897708353617, 25.136797207872252],
            [80.48450158787801, 24.989725551200472],
            [80.35626303662791, 25.136797207872252],
            [80.41025821610164, 25.21663610720836],
            [80.32026625031207, 25.27756579354381],
            [80.31801645116734, 25.386819024214276],
            [80.19652729735144, 25.401526189881455],
            [79.8523080282064, 25.231343272875538],
            [79.83430963504848, 25.094776734537458],
            [79.5575843402456, 25.164110515539868],
            [79.49683976333765, 25.07796854520354],
            [79.39559880182439, 25.115786971204855],
            [79.44284458386392, 25.237646343875756],
            [79.34160362235066, 25.327990361545563],
            [79.26736025057428, 25.304879101211426],
            [79.34835301978488, 25.22714122554206],
            [79.2853586437322, 25.115786971204855],
            [79.1413714984689, 25.105281852871155],
            [79.03338113952144, 25.2292422492088],
            [78.87589519938972, 25.185120752207265],
            [78.79940202846859, 25.288070911877508],
            [78.9501385711661, 25.525486586219095],
            [78.73190805412642, 25.458253828883425],
            [78.77240443873173, 25.35320264554644],
            [78.61041890031052, 25.412031308215152],
            [78.52717633195519, 25.28176784087729],
            [78.64866548577109, 25.056958308536142],
            [78.63066709261318, 24.958210196199378],
            [78.77240443873173, 24.85736106019587],
            [78.75890564386329, 24.59893514918689],
            [78.88714419511341, 24.634652551521466],
            [78.97488636175822, 24.353115380178345],
            [78.79040283188964, 24.18083143950569],
            [78.50692813965253, 24.39093380617966],
            [78.39218838327085, 24.269074433508756],
            [78.27294902859968, 24.44345939784815],
            [78.27294902859968, 24.66406688285582],
            [78.17620766537591, 24.880472320530007],
            [78.44843336188933, 25.151504373539428],
            [78.34269280208659, 25.42463745021559],
            [78.48892974649462, 25.575911154220847],
            [78.80165182761333, 25.611628556555424],
            [78.76790484044224, 25.716679739892406],
            [79.12562290445572, 26.349087863581058],
            [78.99288475491613, 26.681049602925928],
            [78.7251586566922, 26.79450488092987],
            [78.5744221139947, 26.752484407595077],
            [78.3719401909682, 26.86593968559902],
            [78.21895384912595, 26.830222283264447],
            [78.24145184057335, 26.926869371934472],
            [78.14696027649431, 26.95208165593535],
            [77.77349361846765, 26.93107141926795],
            [77.47202053307264, 26.765090549595516],
            [77.42477475103313, 26.838626377931405],
            [77.49001892623056, 26.897455040600118],
            [77.69925024669126, 26.973091892602746],
            [77.56651209715167, 27.044526697271895],
            [77.65200446465175, 27.23361882727847],
            [77.348281580112, 27.519358045955066],
            [77.28528720405932, 27.800895217298187],
            [77.54401410570429, 27.98788632363802],
            [77.48326952879634, 28.097139554308484],
            [77.54626390484903, 28.18538254831155],
            [77.348281580112, 28.502637121989245],
            [77.33478278524358, 28.67702208632864],
            [77.33703258438831, 28.67912310999538],
            [77.1975450374145, 28.861912169001734],
            [77.09630407590124, 29.595169428693886],
            [77.28303740491458, 30.042687469709442],
            [77.42702455017786, 30.105718179711634],
            [77.58901008859907, 30.380952280054533],
            [77.57551129373063, 30.39986149305519],
            [77.93097955859938, 30.25068881271667],
            [77.71499884070444, 29.861999434369828],
            [77.77124381932292, 29.710725730364572],
            [77.8454871910993, 29.66660423336304],
            [77.95347755004677, 29.70862470669783],
            [77.99172413550733, 29.548946908025613],
            [78.34494260123134, 29.79056462970068],
            [78.50017874221831, 29.73593801436545],
            [78.70491046438956, 29.50272438735734],
            [78.91189198570554, 29.45019879568885],
            [78.74090725070538, 29.30732918635055],
            [78.91639158399501, 29.15605548234529],
            [79.08512651985043, 29.14555036401159],
            [79.4135971949823, 28.927043902670665],
            [79.42259639156126, 28.849306027001294],
            [79.78706385300896, 28.88502342933587],
            [80.00079477175916, 28.710638464996475],
            [80.07053854524607, 28.828295790333897],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Uttarakhand',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [80.07053854524607, 28.828295790333897],
            [80.00079477175916, 28.710638464996475],
            [79.78706385300896, 28.88502342933587],
            [79.42259639156126, 28.849306027001294],
            [79.4135971949823, 28.927043902670665],
            [79.08512651985043, 29.14555036401159],
            [78.91639158399501, 29.15605548234529],
            [78.74090725070538, 29.30732918635055],
            [78.91189198570554, 29.45019879568885],
            [78.70491046438956, 29.50272438735734],
            [78.50017874221831, 29.73593801436545],
            [78.34494260123134, 29.79056462970068],
            [77.99172413550733, 29.548946908025613],
            [77.95347755004677, 29.70862470669783],
            [77.8454871910993, 29.66660423336304],
            [77.77124381932292, 29.710725730364572],
            [77.71499884070444, 29.861999434369828],
            [77.93097955859938, 30.25068881271667],
            [77.57551129373063, 30.39986149305519],
            [77.81174020392821, 30.507013700058916],
            [77.70150004583601, 30.763338587401158],
            [77.91748076373095, 31.154128989414744],
            [78.38543898583663, 31.284392456752602],
            [78.48443014820515, 31.198250486416274],
            [78.81065102419228, 31.200351510083017],
            [78.89614339169236, 31.09950237407951],
            [79.01313294721878, 31.11210851607995],
            [78.92089118228449, 31.254978125418248],
            [79.071627724982, 31.467181515758956],
            [79.14587109675838, 31.433565137091122],
            [79.42709598985074, 31.03226961674384],
            [79.51708795564029, 31.03437064041058],
            [79.60483012228511, 30.937723551740554],
            [79.75331686583787, 31.000754261742745],
            [79.86805662221957, 30.97554197774187],
            [80.25277227596992, 30.736025279733543],
            [80.22577468623305, 30.576347481061326],
            [80.60824054083866, 30.47759936872456],
            [81.04245177577326, 30.19816322104818],
            [80.86696744248363, 30.170849913380565],
            [80.4890011861675, 29.79266565336742],
            [80.41025821610164, 29.78846360603394],
            [80.41250801524637, 29.59727045236063],
            [80.27752006656203, 29.393471156686875],
            [80.27302046827256, 29.14344934034485],
            [80.13578272044349, 29.072014535675702],
            [80.07053854524607, 28.828295790333897],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'West Bengal',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [88.22156084663507, 21.7625531990883],
              [88.31155281242462, 21.67220918141849],
              [88.29130462012196, 21.562955950748027],
              [88.21706124834559, 21.61968358975],
              [88.22156084663507, 21.7625531990883],
            ],
          ],
          [
            [
              [88.8312564148593, 21.76675524642178],
              [88.86050380374091, 21.642794850084137],
              [88.80875842341192, 21.640693826417397],
              [88.8312564148593, 21.76675524642178],
            ],
          ],
          [
            [
              [88.33180100472727, 21.861301311425066],
              [88.37679698762204, 21.76885627008852],
              [88.30930301327989, 21.710027607419807],
              [88.30030381670093, 21.76885627008852],
              [88.28230542354302, 21.789866506755914],
              [88.26430703038511, 21.794068554089392],
              [88.25980743209563, 21.806674696089832],
              [88.30255361584567, 21.82768493275723],
              [88.33180100472727, 21.861301311425066],
            ],
          ],
          [
            [
              [88.13606847913499, 21.876008477092242],
              [88.13381867999024, 21.6301887080837],
              [88.04157691505596, 21.649097921084355],
              [88.13606847913499, 21.876008477092242],
            ],
          ],
          [
            [
              [88.83575601314878, 21.918028950427036],
              [88.9639945643989, 21.863402335091806],
              [88.88975119262251, 21.812977767090054],
              [88.83575601314878, 21.918028950427036],
            ],
          ],
          [
            [
              [88.81325802170139, 22.01887808643054],
              [88.89875038920147, 22.012575015430322],
              [88.90774958578042, 21.928534068760733],
              [88.75251344479344, 21.955847376428352],
              [88.81325802170139, 22.01887808643054],
            ],
          ],
          [
            [
              [88.69401866703024, 22.207970216437115],
              [88.78851023110927, 22.170151790435803],
              [88.69176886788549, 22.092413914766432],
              [88.63327409012228, 22.105020056766868],
              [88.65352228242493, 22.16174769576884],
              [88.69401866703024, 22.207970216437115],
            ],
          ],
          [
            [
              [88.98424275670155, 22.382355180776507],
              [89.04048773532003, 22.222677382104294],
              [88.96849416268837, 22.214273287437333],
              [88.98424275670155, 22.382355180776507],
            ],
          ],
          [
            [
              [88.91899858150413, 22.565144239782864],
              [88.95499536781995, 22.550437074115685],
              [88.99774155156999, 22.42857770144478],
              [88.92574797893835, 22.405466441110647],
              [88.894250790912, 22.466396127446096],
              [88.89650059005673, 22.56094219244938],
              [88.91899858150413, 22.565144239782864],
            ],
          ],
          [
            [
              [88.75926284222766, 27.14747685694214],
              [88.87625239775409, 27.109658430940826],
              [89.13497929939905, 26.80921204659705],
              [89.32621222670187, 26.853333543598584],
              [89.86166442314972, 26.704160863260064],
              [89.86166442314972, 26.38480526591563],
              [89.85941462400498, 26.38270424224889],
              [89.72217687617591, 26.25874384591125],
              [89.72217687617591, 26.1662988045747],
              [89.64118410696531, 26.214622348909717],
              [89.54894234203101, 25.998216911235527],
              [89.3577094147282, 26.010823053235967],
              [89.16197688913593, 26.138985496907086],
              [89.08998331650427, 26.39951243158281],
              [88.9864925558463, 26.420522668250207],
              [89.00449094900421, 26.294461248245824],
              [88.75926284222766, 26.311269437579742],
              [88.39479538077995, 26.615917869256997],
              [88.35879859446413, 26.508765662253275],
              [88.52303393203007, 26.38270424224889],
              [88.16306606887186, 26.105369118239253],
              [88.11582028683233, 25.796518639228516],
              [88.37679698762204, 25.712477692558927],
              [88.55453112005642, 25.517082491552138],
              [88.83575601314878, 25.48136508921756],
              [88.84700500887249, 25.36580878754688],
              [89.00449094900421, 25.300677053877948],
              [88.91899858150413, 25.166211539206607],
              [88.44204116281948, 25.201928941541183],
              [88.39929497906944, 24.9435030305322],
              [88.30705321413515, 24.880472320530007],
              [88.17431506459555, 24.945604054198938],
              [88.01457932531909, 24.67036995385604],
              [88.34305000045097, 24.37832766417922],
              [88.72776565420132, 24.298488764843114],
              [88.73676485078028, 23.91610245749649],
              [88.57927891064855, 23.861475842161255],
              [88.56802991492485, 23.716505209156217],
              [88.76826203880661, 23.445473156146797],
              [88.72326605591184, 23.254280002473482],
              [88.9639945643989, 23.193350316138034],
              [88.87400259860934, 23.098804251134744],
              [88.8717527994646, 22.970641807463625],
              [88.97074396183311, 22.844580387459242],
              [88.93699697466204, 22.571447310783082],
              [88.89650059005673, 22.56304321611612],
              [88.86950300031987, 22.546235026782206],
              [88.84925480801722, 22.430678725111523],
              [88.93699697466204, 22.294112186773443],
              [88.90324998749095, 22.17645486143602],
              [88.78626043196454, 22.1806569087695],
              [88.7435142482145, 22.199566121770154],
              [88.69401866703024, 22.210071240103854],
              [88.67377047472758, 22.205869192770376],
              [88.61977529525385, 22.109222104100347],
              [88.60177690209593, 21.90752383209334],
              [88.28005562439827, 21.857099264091588],
              [88.31380261156936, 21.846594145757887],
              [88.25980743209563, 21.810876743423314],
              [88.25755763295089, 21.800371625089614],
              [88.27330622696405, 21.752048080754598],
              [88.21706124834559, 21.76465422275504],
              [88.1540668722929, 21.96004942376183],
              [88.2238106457798, 22.077706749099253],
              [88.05282591077965, 22.01467603909706],
              [87.83684519288471, 21.716330678420025],
              [87.49037612459493, 21.60287540041608],
              [87.44762994084489, 21.76675524642178],
              [87.28564440242369, 21.804573672423093],
              [87.23839862038417, 21.95794840009509],
              [87.00216971018659, 21.911725879426818],
              [87.04041629564715, 21.993665802429668],
              [86.96167332558129, 22.088211867432953],
              [86.72094481709422, 22.147040530101663],
              [86.72769421452844, 22.218475334770815],
              [86.82443557775221, 22.26469785543909],
              [86.84918336834434, 22.39916337011043],
              [86.76144120169953, 22.577750381783304],
              [86.65795044104154, 22.579851405450043],
              [86.64220184702836, 22.65758928111941],
              [86.42172153084395, 22.77944865379031],
              [86.39922353939656, 22.976944878463843],
              [86.21698980867271, 22.993753067797762],
              [86.04375527452783, 23.145026771803018],
              [85.92226612071192, 23.13242062980258],
              [85.84127335150131, 23.193350316138034],
              [85.86602114209344, 23.451776227147015],
              [86.0482548728173, 23.49169567681507],
              [86.04375527452783, 23.586241741818355],
              [86.14499623604107, 23.571534576151176],
              [86.23048860354115, 23.43917008514658],
              [86.35647735564652, 23.466483392814194],
              [86.45096891972555, 23.634565286153368],
              [86.79743798801535, 23.689191901488602],
              [86.90092874867334, 23.882486078828652],
              [87.1529062528841, 23.796344108492324],
              [87.1416572571604, 23.869879936828216],
              [87.29914319729212, 23.890890173495613],
              [87.33738978275268, 24.031658759167172],
              [87.4431303425554, 23.979133167498677],
              [87.50162512031862, 24.1178007295035],
              [87.69510784676616, 24.151417108171334],
              [87.68610865018721, 24.24806419684136],
              [87.80084840656889, 24.3825297115127],
              [87.80084840656889, 24.563217746852313],
              [87.89309017150319, 24.563217746852313],
              [87.90658896637163, 24.72289554552453],
              [87.84584438946368, 24.73970373485845],
              [87.97183314156905, 24.88257334419675],
              [87.77835041512151, 25.101079805537676],
              [87.79409900913467, 25.22083815454184],
              [87.85709338518737, 25.29857603021121],
              [87.78734961170046, 25.338495479879263],
              [87.79184920998993, 25.449849734216468],
              [88.032577718477, 25.55279989388671],
              [88.05507570992438, 25.68726540855805],
              [87.8300957954505, 25.872155491231144],
              [87.84809418860841, 26.0444394319038],
              [88.28005562439827, 26.376401171248673],
              [88.1135704876876, 26.54028101725437],
              [88.17206526545081, 26.868040709265763],
              [87.99433113301643, 27.107557407274086],
              [88.01682912446383, 27.21681063794455],
              [88.16081626972712, 27.111759454607565],
              [88.43979136367474, 27.08234512327321],
              [88.59727730380646, 27.193699377610415],
              [88.75926284222766, 27.14747685694214],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Odisha',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [86.72769421452844, 22.218475334770815],
            [86.72094481709422, 22.147040530101663],
            [86.96167332558129, 22.088211867432953],
            [87.04041629564715, 21.993665802429668],
            [87.00216971018659, 21.911725879426818],
            [87.23839862038417, 21.95794840009509],
            [87.28564440242369, 21.804573672423093],
            [87.44762994084489, 21.76675524642178],
            [87.49037612459493, 21.60287540041608],
            [87.15065645373936, 21.508329335412796],
            [86.94817453071286, 21.352853584074058],
            [86.84243397091012, 21.157458383067265],
            [87.01566850505502, 20.682627034384097],
            [86.78843879143639, 20.529252306712095],
            [86.73444361196266, 20.409493957707934],
            [86.78393919314692, 20.32545301103835],
            [86.52746209064668, 20.167876236032868],
            [86.36772635137022, 19.9472687510252],
            [85.35081713794824, 19.58379165667923],
            [84.8918581124215, 19.243425822667405],
            [84.76811915946087, 19.079545976661706],
            [84.66912799709236, 19.129970544663458],
            [84.60388382189493, 19.029121408659954],
            [84.51614165525011, 19.045929597993872],
            [84.34065732196048, 18.800109828985327],
            [84.09992881347341, 18.753887308317054],
            [83.87719869814427, 18.827423136652943],
            [83.79620592893366, 19.016515266659518],
            [83.73321155288097, 18.98499991165842],
            [83.61172239906507, 19.100556213329103],
            [83.48123404867022, 19.008111171992557],
            [83.34399630084116, 19.01441424299278],
            [83.39574168117014, 18.879948728321438],
            [83.03352401886718, 18.57319927297744],
            [83.07402040347249, 18.400915332304784],
            [82.98402843768292, 18.36309690630347],
            [82.80629430524856, 18.447137852973057],
            [82.7747971172222, 18.339985645969335],
            [82.59706298478784, 18.264348793966704],
            [82.61056177965627, 18.37360202463717],
            [82.48907262584036, 18.52277470497569],
            [82.36983327116921, 18.417723521638703],
            [82.34508548057708, 18.056347450959475],
            [82.03461319860311, 18.066852569293175],
            [81.61840035682641, 17.823133823951366],
            [81.39342044235254, 17.81472972928441],
            [81.53515778847108, 18.266449817633443],
            [81.75338830551075, 18.35259178796977],
            [81.95587022853725, 18.564795178310483],
            [81.96261962597147, 18.686654550981384],
            [82.15385255327428, 18.78120061598467],
            [82.1786003438664, 18.898857941322092],
            [82.24609431820858, 18.91776715432275],
            [82.1786003438664, 19.434618976340715],
            [82.05486139090577, 19.54387220701118],
            [82.05936098919524, 19.795995047019943],
            [81.8658782627477, 19.90314725402367],
            [81.86812806189243, 20.02710765036131],
            [81.94012163452408, 20.10484552603068],
            [82.02336420287942, 20.02500662669457],
            [82.2708421088007, 19.976683082359557],
            [82.34733527972182, 19.835914496687998],
            [82.48232322840616, 19.905248277690408],
            [82.7050533437353, 19.83171244935452],
            [82.71855213860374, 19.987188200693254],
            [82.4035802583403, 20.05231993436219],
            [82.3405858822876, 20.84440585672305],
            [82.56106619847202, 20.945254992726557],
            [82.64430876682735, 21.153256335733786],
            [83.20000915557786, 21.14275121740009],
            [83.27650232649898, 21.378065868074934],
            [83.38224288630171, 21.342348465740358],
            [83.3867424845912, 21.615481542416518],
            [83.6072228007756, 21.913826903093558],
            [83.54197862557817, 21.96635249476205],
            [83.56447661702556, 22.10291903310013],
            [83.65221878367038, 22.226879429437773],
            [84.00768704853913, 22.37185006244281],
            [84.00993684768386, 22.52312376644807],
            [84.2956613390657, 22.340334707441716],
            [85.05834324913218, 22.483204316780014],
            [85.11233842860591, 22.294112186773443],
            [85.03134565939531, 22.15754564843536],
            [85.2158291892639, 22.0482924177649],
            [85.41606131314566, 22.159646672102102],
            [85.89976812926453, 21.981059660429228],
            [86.03250627880412, 22.189061003436457],
            [85.97626130018566, 22.247889666105166],
            [86.02800668051465, 22.38445620444325],
            [85.96051270617248, 22.460093056445878],
            [86.07525246255416, 22.546235026782206],
            [86.43072072742291, 22.31092037610736],
            [86.5049640991993, 22.344536754775195],
            [86.72769421452844, 22.218475334770815],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Andhra Pradesh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [81.10544615182596, 17.823133823951366],
            [81.39342044235254, 17.81472972928441],
            [81.61840035682641, 17.823133823951366],
            [82.03461319860311, 18.066852569293175],
            [82.34508548057708, 18.056347450959475],
            [82.36983327116921, 18.417723521638703],
            [82.48907262584036, 18.52277470497569],
            [82.61056177965627, 18.37360202463717],
            [82.59706298478784, 18.264348793966704],
            [82.7747971172222, 18.339985645969335],
            [82.80629430524856, 18.447137852973057],
            [82.98402843768292, 18.36309690630347],
            [83.07402040347249, 18.400915332304784],
            [83.03352401886718, 18.57319927297744],
            [83.39574168117014, 18.879948728321438],
            [83.34399630084116, 19.01441424299278],
            [83.48123404867022, 19.008111171992557],
            [83.61172239906507, 19.100556213329103],
            [83.73321155288097, 18.98499991165842],
            [83.79620592893366, 19.016515266659518],
            [83.87719869814427, 18.827423136652943],
            [84.09992881347341, 18.753887308317054],
            [84.34065732196048, 18.800109828985327],
            [84.51614165525011, 19.045929597993872],
            [84.60388382189493, 19.029121408659954],
            [84.66912799709236, 19.129970544663458],
            [84.76811915946087, 19.079545976661706],
            [84.11792720663132, 18.30216721996802],
            [83.70396416399937, 18.102569971627748],
            [83.21575774959103, 17.58992019694326],
            [82.56106619847202, 17.255857433931652],
            [82.30008949768231, 17.024744830590283],
            [82.24834411735331, 16.896582386919164],
            [82.36533367287973, 16.82514758225001],
            [82.29783969853756, 16.57092371857451],
            [81.72639071577389, 16.30829576023205],
            [81.38217144662883, 16.3587203282338],
            [81.2584324936682, 16.318800878565746],
            [81.15269193386547, 15.976334020887174],
            [80.96145900656266, 15.73471629921211],
            [80.83772005360203, 15.707402991544495],
            [80.72972969465457, 15.885990003217367],
            [80.51149917761488, 15.858676695549752],
            [80.3450140409042, 15.751524488546027],
            [80.13128312215402, 15.3796432995331],
            [80.04579075465394, 15.049782583854968],
            [80.1942774982067, 14.564446116838099],
            [80.12903332300928, 14.184160833158213],
            [80.27077066912783, 13.562257827803265],
            [80.22577468623305, 13.484519952133894],
            [80.01879316491707, 13.501328141467813],
            [79.93780039570646, 13.347953413795814],
            [79.68357309235097, 13.24920530145905],
            [79.42934578899548, 13.333246248128637],
            [79.43384538728496, 13.196679709790557],
            [79.23811286169267, 13.146255141788803],
            [79.16611928906103, 13.020193721784423],
            [78.81290082333702, 13.087426479120094],
            [78.63066709261318, 12.984476319449847],
            [78.56317311827101, 12.707141195440208],
            [78.38093938754716, 12.62099922510388],
            [78.23245264399439, 12.761767810775439],
            [78.25270083629704, 12.856313875778726],
            [78.46868155419197, 12.973971201116148],
            [78.57667191313944, 13.282821680126883],
            [78.36519079353398, 13.364761603129732],
            [78.38993858412611, 13.59167215913762],
            [78.0884654987311, 13.65890491647329],
            [78.11546308846796, 13.86270421214704],
            [78.05021891327054, 13.894219567148136],
            [77.91973056287569, 13.906825709148574],
            [77.53501490912534, 13.715632555475262],
            [77.43152414846735, 13.833289880812686],
            [77.24704061859876, 13.900522638148356],
            [77.17279724682237, 13.761855076143535],
            [76.97481492208534, 13.816481691478767],
            [77.04230889642751, 13.93203799314945],
            [76.94781733234848, 14.119029099489282],
            [77.02656030241434, 14.179958785824734],
            [77.02881010155909, 14.060200436820573],
            [77.14130005879602, 14.005573821485338],
            [77.3190341912304, 14.032887129152956],
            [77.35503097754622, 13.902623661815095],
            [77.42477475103313, 13.902623661815095],
            [77.34603178096727, 14.060200436820573],
            [77.39777716129626, 14.173655714824516],
            [77.51701651596743, 14.179958785824734],
            [77.50576752024372, 14.266100756161062],
            [77.40227675958573, 14.335434537163472],
            [77.36627997326991, 14.27660587449476],
            [77.15929845195393, 14.34383863183043],
            [77.0535578921512, 14.245090519493665],
            [76.94331773405901, 14.245090519493665],
            [76.88932255458528, 14.396364223498924],
            [76.97931452037483, 14.48460721750199],
            [76.86907436228262, 14.480405170168511],
            [76.76558360162463, 14.602264542839414],
            [76.86907436228262, 14.96994368451886],
            [76.76783340076938, 14.97414573185234],
            [76.79933058879571, 15.096005104523242],
            [76.88032335800631, 15.032974394521052],
            [77.11430246905915, 15.035075418187791],
            [77.1682976485329, 15.17374298019261],
            [77.15254905451972, 15.291400305530034],
            [77.04230889642751, 15.364936133865923],
            [77.08055548188807, 15.915404334551724],
            [77.25379001603297, 15.961626855219997],
            [77.51476671682268, 15.925909452885422],
            [78.106463891889, 15.833464411548876],
            [78.27519882774443, 16.041465754556107],
            [78.4124365755735, 16.08768827522438],
            [78.6441658874816, 16.098193393558077],
            [78.74540684899486, 16.028859612555667],
            [79.02888154123195, 16.251568121230076],
            [79.2336132634032, 16.257871192230294],
            [79.27410964800849, 16.56882269490777],
            [79.78256425471949, 16.736904588246944],
            [79.9512991905749, 16.64025749957692],
            [80.07953774182502, 16.820945534916532],
            [80.00079477175916, 16.871370102918284],
            [80.05478995123289, 16.97221923892179],
            [80.20327669478566, 17.024744830590283],
            [80.27077066912783, 17.018441759590065],
            [80.3270156477463, 16.879774197585242],
            [80.57224375452283, 16.77052096691478],
            [80.59249194682549, 16.934400812920476],
            [80.45075460070693, 16.953310025921134],
            [80.50474978018067, 17.10878577725987],
            [80.56774415623336, 17.146604203261184],
            [80.83322045531254, 17.04575506725768],
            [80.91421322452315, 17.20963491326338],
            [81.17069032702338, 17.241150268264473],
            [81.19993771590498, 17.33569633326776],
            [81.32592646801037, 17.390322948602993],
            [81.1256943441286, 17.524788463274334],
            [81.01770398518113, 17.52268743960759],
            [80.89171523307576, 17.67606216727959],
            [80.88721563478629, 17.722284687947862],
            [80.97270800228635, 17.774810279616354],
            [81.04245177577326, 17.73909287728178],
            [81.05820036978643, 17.75380004294896],
            [81.0559505706417, 17.791618468950272],
            [81.06494976722065, 17.789517445283533],
            [81.09869675439174, 17.818931776617887],
            [81.10544615182596, 17.823133823951366],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Jammu and Kashmir',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [74.09957161510904, 35.141871908886685],
            [74.52028405517522, 35.106154506552116],
            [74.6350238115569, 34.94017363687968],
            [74.92749770037295, 34.814112216875294],
            [75.1614768114258, 34.86243576121031],
            [75.22672098662323, 34.6502323708696],
            [75.34821014043914, 34.55778732953305],
            [75.40220531991287, 34.54518118753261],
            [75.4337025079392, 34.349785986525816],
            [75.74192499076844, 34.19851228252056],
            [75.9421571146502, 33.98840991584659],
            [76.05689687103188, 33.98630889217985],
            [76.25037959747942, 33.60812463216671],
            [76.39661654188745, 33.66695329483542],
            [76.79483099050624, 33.255152656154436],
            [76.74758520846672, 33.179515804151805],
            [76.40111614017692, 33.18371785148529],
            [76.25487919576891, 33.032444147480035],
            [76.11089205050561, 33.00302981614568],
            [76.03439887958449, 32.91688784580935],
            [75.95115631122916, 32.88537249080825],
            [75.82516755912377, 32.929493987809785],
            [75.93990731550545, 32.64375476913319],
            [75.72617639675526, 32.4168442131253],
            [75.48319808912346, 32.31809610078854],
            [75.12997962339946, 32.406339094791605],
            [75.03323826017568, 32.49248106512793],
            [74.94549609353086, 32.44415752079291],
            [74.68226959359642, 32.486177994127715],
            [74.617025418399, 32.755109023470396],
            [74.38304630734615, 32.757210047137136],
            [73.63161339300336, 33.08707076281526],
            [73.65861098274023, 33.204728088152685],
            [73.55961982037171, 33.36860793415838],
            [73.62036439727966, 33.578710300832356],
            [73.58661741010857, 33.90016692184352],
            [73.39988408109525, 34.36029110485952],
            [73.44712986313476, 34.57459551886697],
            [73.61811459813492, 34.58720166086741],
            [73.77335073912191, 34.79730402754137],
            [74.02982784162214, 34.87714292687748],
            [74.09957161510904, 35.141871908886685],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        st_nm: 'Ladakh',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [76.79483099050624, 33.255152656154436],
            [76.39661654188745, 33.66695329483542],
            [76.25037959747942, 33.60812463216671],
            [76.05689687103188, 33.98630889217985],
            [75.9421571146502, 33.98840991584659],
            [75.74192499076844, 34.19851228252056],
            [75.4337025079392, 34.349785986525816],
            [75.40220531991287, 34.54518118753261],
            [75.34821014043914, 34.55778732953305],
            [75.22672098662323, 34.6502323708696],
            [75.1614768114258, 34.86243576121031],
            [74.92749770037295, 34.814112216875294],
            [74.6350238115569, 34.94017363687968],
            [74.52028405517522, 35.106154506552116],
            [74.09957161510904, 35.141871908886685],
            [73.77560053826664, 35.23641797388997],
            [73.68785837162183, 35.305751754892384],
            [73.75760214510873, 35.526359239900046],
            [73.50337484175324, 35.53266231090027],
            [73.28964392300304, 35.62300632857008],
            [73.1366575811608, 35.715451369906624],
            [73.08491220083181, 35.87723019224558],
            [72.79243831201575, 35.83731074257753],
            [72.51571301721286, 35.904543499913196],
            [72.58770658984452, 36.013796730583664],
            [72.56520859839712, 36.23020216825785],
            [72.97917164102908, 36.47181988993292],
            [73.09391139741076, 36.60418438093751],
            [73.07816280339759, 36.700831469607536],
            [73.80259812800351, 36.72814477727516],
            [73.87009210234568, 36.784872416277125],
            [73.71935555964818, 36.8521051736128],
            [74.09507201681957, 36.831094936945405],
            [74.11532020912222, 36.889923599614114],
            [74.67776999530695, 37.05170242195307],
            [74.89150091405713, 36.92143895461521],
            [75.10073223451785, 36.9949747829511],
            [75.35045993958387, 36.915135883614994],
            [75.41120451649182, 36.747053990275816],
            [75.57094025576828, 36.76596320327647],
            [75.7914205719527, 36.6882253276071],
            [75.98040370011076, 36.4445065822653],
            [76.66884223840086, 36.1902827185898],
            [76.83982697340102, 35.86042200291166],
            [77.08955467846704, 35.740663653907504],
            [77.348281580112, 35.7196534172401],
            [77.51926631511216, 35.48854081389874],
            [77.90623176800725, 35.46963160089808],
            [77.9669763449152, 35.574682784235065],
            [78.13796107991536, 35.53896538190049],
            [78.27294902859968, 35.72175444090684],
            [78.4236855712972, 35.78478515090903],
            [78.94113937458714, 35.879331215912316],
            [79.11662370787677, 35.83731074257753],
            [79.24261245998215, 35.986483422916045],
            [79.38659960524544, 35.990685470249524],
            [79.424846190706, 35.8940383815795],
            [79.74656746840367, 35.799492316576206],
            [79.99854497261441, 35.83731074257753],
            [80.11553452814084, 35.67763294390531],
            [80.29326866057521, 35.60199609190268],
            [80.32926544689104, 35.47383364823156],
            [80.06828874610133, 34.71536410453852],
            [79.77356505814053, 34.62291906320198],
            [79.79381325044318, 34.484251501197164],
            [79.58008233169298, 34.48845354853064],
            [79.58458192998246, 34.238431732188616],
            [79.37535060952175, 34.03463243651487],
            [79.01538274636353, 34.051440625848784],
            [78.90514258827132, 33.97580377384615],
            [79.10537471215308, 33.614427703166925],
            [78.91864138313974, 33.63333691616758],
            [78.99288475491613, 33.33078950815707],
            [79.4540935795876, 33.259354703487915],
            [79.3843498061007, 32.97361548481132],
            [79.6273281137325, 32.738300834136474],
            [79.44734418215339, 32.53240051479598],
            [79.33935382320593, 32.5912291774647],
            [79.11437390873203, 32.383227834457465],
            [78.96138756688978, 32.36641964512354],
            [78.82639961820546, 32.439955473459435],
            [78.74990644728433, 32.681573195134504],
            [78.61941809688948, 32.595431224798176],
            [78.44393376359984, 32.5702189407973],
            [78.32694420807343, 32.47147082846053],
            [78.31569521234972, 32.574420988130775],
            [78.40568717813929, 32.61854248513231],
            [78.31344541320499, 32.708886502802116],
            [77.99397393465206, 32.58912815379796],
            [77.73074743471761, 32.96941343747784],
            [77.33928238353305, 32.82024075713932],
            [77.15479885366446, 32.9778175321448],
            [76.93431853748005, 33.032444147480035],
            [76.79483099050624, 33.255152656154436],
          ],
        ],
      },
    },
  ],
};
