import React, { useState } from 'react';
import { DialogVideo } from '../../../components/DialogVideo/DialogVideo';
import VideoButton from '../../../components/VideoButton/VideoButton';
import CustomePopup from '../../../components/CustomePopup/CustomePopup';
import VideoButtonSmall from '../../../components/VideoButton/VideoButtonSmall';

function ReusablePartnersComponent({ data }) {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="reusable-partners-component-mob">
      <div className="mob-image-section" onClick={() => handleClickOpen(data?.link)}>
        <img src={data?.imageUrl} alt="shalini" />
      </div>
      <div className="mob-info-section" style={{ backgroundColor: data?.bgcolor }}>
        <div className="mob-info-box">
          <div className="mob-pertner-title">{data?.title} </div>
          <div className="mob-pertner-disc">{data?.dics}</div>
          <div className="mob-pertner-watch-now-btn">
            {/* <VideoButton handleClickOpen={() => handleClickOpen(data?.link)}></VideoButton> */}
            <VideoButtonSmall handleClickOpen={() => handleClickOpen(data?.link)}></VideoButtonSmall>
          </div>
        </div>
      </div>
      {/* {open && <DialogVideo link={activeLink} handleClose={handleClose} />} */}
      {open && (
        <CustomePopup
          link={activeLink}
          onClose={handleClose}
          popupData={data?.popupData}
          isVisible={open}
          IsVideo={true}
        />
      )}
    </div>
  );
}

export default ReusablePartnersComponent;
