import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { CONNECT_SLIDER_IMAGES, PRODUCT_CONNECT_INFO } from '../../../utils/constants';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import { useInView } from 'react-intersection-observer';
import './DGVConnect.css';
import ForMoreDetails from '../../../components/ForMoreDetails/ForMoreDetails';
import connectImage from '../../../assets/images/connectImage.png';
import connectImageMob from '../../../assets/images/connect-image-mob.png';
import connectQrCode from '../../../assets/images/connect-QR-code.png';
import connectFeatureIcon_Img_1 from '../../../assets/images/features-icon-text-1.png';
import connectFeatureIcon_Img_2 from '../../../assets/images/features-icon-text-2.png';
import connectFeatureIcon_Img_3 from '../../../assets/images/features-icon-text-3.png';
import connectFeatureIcon_Img_4 from '../../../assets/images/features-icon-text-4.png';
import featureDotsMob from '../../../assets/images/feature-dots-mob.png';
import featureMob_1 from '../../../assets/images/feature-mob-1.png';
import featureMob_2 from '../../../assets/images/feature-mob-2.png';
import featureMob_3 from '../../../assets/images/feature-mob-3.png';
import featureMob_4 from '../../../assets/images/feature-mob-4.png';

const DGVConnect = () => {
  const { ref, inView: logoView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  const [ref2, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  const { ref: mobileRef, inView: mobileInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <div className="main-container  desktop-view ">
      <Header />
      <BreadCrumb />
      <div className="page-container page-content">
        <ProductInfoPlot info={PRODUCT_CONNECT_INFO} images={CONNECT_SLIDER_IMAGES} customeClassName={'flex-around'} />
      </div>

      {/* <div className="connect-image">
        <img src={mobileView ? connectImage : connectImageMob} alt="connect-image" />
      </div> */}
      <div ref={ref} className={logoView ? `connect-image animate__animated animate__flipInY ` : 'connect-image'}>
        <img src={mobileView ? connectImage : connectImageMob} alt="DGV Pay Graphic" />
      </div>
      <div className="scanner-container">
        <div className="scanner-text">SCAN TO DOWNLOAD THE APP</div>
        <div className="scanner-image">
          <img src={connectQrCode} alt="qr-code" />
        </div>
      </div>

      {mobileView ? (
        <div className="connect-feature-container">
          <div className="feature-text">Features</div>
          <div ref={ref2} className="feature-logo-info">
            {/* <div className="feature-list-box">
              <div className="feature-icon">
                <img src={connectFeatureIcon_Img_1} alt=""></img>
              </div>
              <div className="feature-text-disc">Integrated Marketplace for Dairy Farmers</div>
            </div> */}
            <div className={inView ? `feature-list-box animate__animated animate__flipInY` : ''}>
              <div className="feature-icon">
                <img src={connectFeatureIcon_Img_1} alt=""></img>
              </div>
              <div className="feature-text-disc">Integrated Marketplace for Dairy Farmers</div>
            </div>
            <div className={inView ? `feature-list-box animate__animated animate__flipInY` : ''}>
              <div className="feature-icon">
                <img src={connectFeatureIcon_Img_2} alt=""></img>
              </div>
              <div className="feature-text-disc">Digital Bovine Loan available at the doorstep</div>
            </div>
            <div className={inView ? `feature-list-box animate__animated animate__flipInY` : ''}>
              <div className="feature-icon">
                <img src={connectFeatureIcon_Img_3} alt=""></img>
              </div>
              <div className="feature-text-disc">Explore Wide variety of Dairy Equipments with quick loan options</div>
            </div>
            <div className={inView ? `feature-list-box animate__animated animate__flipInY` : ''}>
              <div className="feature-icon">
                <img src={connectFeatureIcon_Img_4} alt=""></img>
              </div>
              <div className="feature-text-disc">
                One-stop <br />
                solution for dairy <br />
                farmers
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div ref={mobileRef} className="feature-mob-container">
          <div className="feature-mob-title">Features</div>
          <div className="feature-dots-box">
            <img src={featureDotsMob} alt="dots" />
          </div>
          <div className="feature-mob-info-section">
            <div
              // className="feature-mob-info-box"
              className={mobileInView ? `feature-mob-info-box animate__animated animate__fadeInLeft` : ''}
            >
              <div className="feature-mob-logo">
                <img src={featureMob_1} alt="logo" />
              </div>
              <div className="feature-mob-dics">Integrated Marketplace for Dairy Farmers</div>
            </div>
            <div className={mobileInView ? `feature-mob-info-box animate__animated animate__fadeInRight` : ''}>
              <div className="feature-mob-logo">
                <img src={featureMob_2} alt="logo" />
              </div>
              <div className="feature-mob-dics">Digital Bovine Loan available at the doorstep</div>
            </div>
            <div className={mobileInView ? `feature-mob-info-box animate__animated animate__fadeInLeft` : ''}>
              <div className="feature-mob-logo">
                <img src={featureMob_3} alt="logo" />
              </div>
              <div className="feature-mob-dics">Explore Wide variety of Dairy Equipments with quick loan options</div>
            </div>
            <div className={mobileInView ? `feature-mob-info-box animate__animated animate__fadeInRight` : ''}>
              <div className="feature-mob-logo">
                <img src={featureMob_4} alt="logo" />
              </div>
              <div className="feature-mob-dics">
                One-stop <br />
                solution for dairy <br />
                farmers
              </div>
            </div>
          </div>
        </div>
      )}

      <ForMoreDetails mailId={'connect@dgvconnect.in'} />

      <Footer />
    </div>
  );
};

export default DGVConnect;
