import React from 'react';
import './DGVQualify.css';
import DGV_Qualify_Logo from '../../../../assets/images/DGV-Qualify-Logo.png';
import DGV_Qualify_Newspaper_image from '../../../../assets/images/DGV-Qualify-Newspaper-image.png';
import { InView, useInView } from 'react-intersection-observer';

function DGVQualify() {
  const [ref, inView] = useInView({
    threshold: 0.1, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  return (
    <div ref={ref} className="qualify-main-container">
      <div className={inView ? 'qualify-logo animate__animated animate__fadeIn' : 'quality-logo'}>
        <img src={DGV_Qualify_Logo} alt="DGV_Qualify_Logo" />
      </div>
      <div className="quality-section-box">
        <div className="quality-wrapper">
          <div className={inView ? 'quality-left-box animate__animated animate__fadeInUp' : 'quality-left-box'}>
            <div className="quality-text-left">
              The service is provided via a strategic partnership forged with the Reserve Bank Innovation Hub (RBIH)
              under the Public Tech Infrastructure program. ​
            </div>
          </div>
          <div
            className={
              inView ? 'quality-rigth-box animate__animated animate__fadeInUp animate__delay-1s' : 'quality-rigth-box'
            }
          >
            <div className="quality-text-right">
              DGV Qualifi is a ‘first of its kind’ unique proposition offered by DGV to empower Banks, NBFC’s and
              Lending Institutions better assess and underwrite dairy related loans credibly. (SCORECARD)​​
            </div>
          </div>
        </div>
        <div
          className={
            inView
              ? 'qualify-newspaper-wrapper animate__animated animate__fadeIn animate__delay-1s'
              : 'qualify-newspaper-wrapper'
          }
        >
          <div className="qualify-newspaper-box">
            <img src={DGV_Qualify_Newspaper_image} alt="newspaper-imgae" />
          </div>
          <div className="quality-click-here-section">
            <div className="quality-click-here-box">
              <a
                href="https://timesofindia.indiatimes.com/business/india-business/rbi-uses-milk-supply-data-for-dairy-farmer-loans/amp_articleshow/101841261.cms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for details
              </a>
            </div>
            <div className="quality-click-here-box">
              <a
                href="https://economictimes.indiatimes.com/industry/cons-products/food/new-rbi-platform-to-use-milk-supply-data-for-dairy-farmer-loans/articleshow/101850645.cms?from=mdr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DGVQualify;
