import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Footer from '../../../components/Footer';
import { MONEY_SLIDER_IMAGES, PRODUCT_MONEY_INFO } from '../../../utils/constants';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import './DGVMoney.css';
import ForMoreDetails from '../../../components/ForMoreDetails/ForMoreDetails';
import moneyImage from '../../../assets/images/dgv-money-page-img.png';
import moneyImageMob from '../../../assets/images/dgv-money-page-img-mob.png';
import featureBovineMobLogo_1 from '../../../assets/images/feature-bovine-mob-1.png';
import featureMaintenanceMobLogo_1 from '../../../assets/images/feature-insurance-mob-1.png';
import featureEquipmentsMobLogo_1 from '../../../assets/images/feature-equipments-mob-1.png';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';
const DGVMoney = () => {
  const [mobileView, setMobileView] = useState(false);
  const { ref, inView: logoView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  const { ref2, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  const { ref: desktopRef, inView: desktopInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  const eventSettings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    adaptiveHeight: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    cssEase: 'linear',
    // initialSlide: 'slide',

    responsive: [
      {
        breakpoint: 580,
        settings: {
          adaptiveHeight: true,
          variableWidth: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="money-container desktop-view">
      <Header />
      <BreadCrumb />
      <div className="page-container page-content">
        <div className="money-info-slider-box">
          <ProductInfoPlot info={PRODUCT_MONEY_INFO} images={MONEY_SLIDER_IMAGES} customeClassName={'flex-start'} />
        </div>
      </div>
      {/* <div className="money-image-container">
        <img src={mobileView ? moneyImage : moneyImageMob} alt="money-img" />
      </div> */}
      <div
        ref={ref}
        className={logoView ? `money-image-container animate__animated animate__flipInY ` : 'money-image-container'}
      >
        <img src={mobileView ? moneyImage : moneyImageMob} alt="DGV Pay Graphic" />
      </div>
      <div ref={desktopRef} className="money-features-container">
        <div className="money-features-title">Features</div>
        <div className="money-feature-list-section">
          {!mobileView ? (
            <Slider {...eventSettings}>
              <div style={{ width: '208px' }} className="money-feature-box">
                {/* <div className="feature-logo">
                  <img src={DairyBovineLoan} alt="Bovine Loan" />
                </div> */}
                <div className="feature-logo-text-section">
                  <div className="feature-mob-logo">
                    <img src={featureBovineMobLogo_1} alt="" />
                  </div>
                  <div className="feature-mob-text">Digital Bovine Loan</div>
                </div>
                <div className="feature-list">
                  <ul>
                    <li>Loan to buy New Bovine</li>
                    <li>30 mins sanctioning</li>
                    <li>72 hrs Disbursement</li>
                    <li>
                      Loan Amount: <br></br>Minimum-₹60,000 <br></br> Maximum - ₹4,00,000
                    </li>
                    <li>
                      Loan Tenure: <br></br>Minimum 24 Months<br></br> Maximum 36 Months
                    </li>
                  </ul>
                </div>
              </div>
              <div className="money-feature-box">
                {/* <div className="feature-logo">
                  <img src={DairyMentananceLoan} alt="Bovine Loan" />
                </div> */}
                <div className="feature-logo-text-section">
                  <div className="feature-mob-logo">
                    <img src={featureMaintenanceMobLogo_1} alt="" />
                  </div>
                  <div className="feature-mob-text">Dairy Maintenance Loan</div>
                </div>
                <div className="feature-list">
                  <ul>
                    <li>Loan to upkeep Bovine</li>
                    <li>24 hrs Disbursement </li>
                    <li>Best fit for small, medium need of the farmer. </li>
                    <li>
                      Loan Amount: <br></br>Minimum-₹40,000 <br></br> Maximum - ₹1,00,000
                    </li>
                    <li>
                      Loan Tenure: <br></br>Minimum 12 Months<br></br> Maximum 36 Months
                    </li>
                  </ul>
                </div>
              </div>
              <div className="money-feature-box">
                {/* <div className="feature-logo">
                  <img src={DairyEquipmentLoan} alt="Bovine Loan" />
                </div> */}
                <div className="feature-logo-text-section">
                  <div className="feature-mob-logo">
                    <img src={featureEquipmentsMobLogo_1} alt="" />
                  </div>
                  <div className="feature-mob-text">Dairy Equipment Loan</div>
                </div>
                <div className="feature-list">
                  <ul>
                    <li>Buy desired Dairy Equipment </li>
                    <li>Easy EMI options </li>
                    <li>Complete Digital journey</li>
                    <li>
                      Loan Amount: <br></br>Minimum-₹25,000 <br></br> Maximum - ₹4,00,000
                    </li>
                    <li>
                      Loan Tenure: <br></br>Minimum 12 Months<br></br> Maximum 36 Months
                    </li>
                  </ul>
                </div>
              </div>
            </Slider>
          ) : (
            <>
              <div
                // className="money-feature-box"
                className={
                  desktopInView ? `money-feature-box animate__animated animate__fadeInLeft` : 'money-feature-box'
                }
              >
                {/* <div className="feature-logo"> */}
                {/* <img src={DairyBovineLoan} alt="Bovine Loan" /> */}
                <div className="feature-logo-text-section">
                  <div className="feature-desk-logo">
                    <img src={featureBovineMobLogo_1} alt="" />
                  </div>
                  <div className="feature-desk-text">Digital Bovine Loan</div>
                </div>
                {/* </div> */}
                <div className="feature-list">
                  <ul>
                    <li>Loan to buy New Bovine</li>
                    <li>30 mins sanctioning</li>
                    <li>72 hrs Disbursement</li>
                    <li>
                      Loan Amount: <br></br>Minimum-₹60,000 <br></br> Maximum - ₹4,00,000
                    </li>
                    <li>
                      Loan Tenure: <br></br>Minimum 24 Months<br></br> Maximum 36 Months
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={
                  desktopInView
                    ? `money-feature-box animate__animated animate__fadeInUp animate__delay-1s`
                    : 'money-feature-box'
                }
              >
                <div className="feature-logo-text-section">
                  <div className="feature-desk-logo">
                    <img src={featureMaintenanceMobLogo_1} alt="" />
                  </div>
                  <div className="feature-desk-text">Dairy Maintenance Loan</div>
                </div>
                <div className="feature-list">
                  <ul>
                    <li>Loan to upkeep Bovine</li>
                    <li>24 hrs Disbursement </li>
                    <li>Best fit for small, medium need of the farmer. </li>
                    <li>
                      Loan Amount: <br></br>Minimum-₹40,000 <br></br> Maximum - ₹1,00,000
                    </li>
                    <li>
                      Loan Tenure: <br></br>Minimum 12 Months<br></br> Maximum 36 Months
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={
                  desktopInView ? `money-feature-box animate__animated animate__fadeInRight` : 'money-feature-box'
                }
              >
                <div className="feature-logo-text-section">
                  <div className="feature-desk-logo">
                    <img src={featureEquipmentsMobLogo_1} alt="" />
                  </div>
                  <div className="feature-desk-text">Dairy Equipment Loan</div>
                </div>
                <div className="feature-list">
                  <ul>
                    <li>Buy desired Dairy Equipment </li>
                    <li>Easy EMI options </li>
                    <li>Complete Digital journey</li>
                    <li>
                      Loan Amount: <br></br>Minimum-₹25,000 <br></br> Maximum - ₹4,00,000
                    </li>
                    <li>
                      Loan Tenure: <br></br>Minimum 12 Months<br></br> Maximum 36 Months
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ForMoreDetails mailId={'money@dgv.in'} />
      <Footer />
    </div>
  );
};

export default DGVMoney;
