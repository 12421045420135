import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './NewHomePageSlide5.css';
import DGVInsurTechTitle from '../../assets/images/new-Insurance-Horizontal.png';
import DGVInsuranceBanner from '../../assets/images/insurance-banner-2.png';
import DGVInsuranceSubjectLayer from '../../assets/images/insurance-subject-layer.png';
import DGVInsuranceSubjectBanner from '../../assets/images/insurance-mob-subject-banner.png';
import DGVInsuranceSection1 from '../../assets/images/insurance-section-1.png';
import DGVInsuranceSection2 from '../../assets/images/insurance-section-2.png';
import DGVInsuranceGropusImg from '../../assets/images/insurance-mob-Group.png';
import DGVInsuranceChecklistTaskImg from '../../assets/images/insuarance-mob-checklist-task.png';
import ChecklistLeftTop from '../../assets/images/checklist-left-top.png';
import ChecklistLeftBottom from '../../assets/images/checklist-left-bottom.png';
import ChecklistRightTop from '../../assets/images/checklist-right-top.png';
import ChecklistRightBottom from '../../assets/images/checklist-right-botton.png';
import middleImage from '../../assets/images/middle-image.png';
import InsurImg_1 from '../../assets/images/insur-img-1.png';
import InsurImg_2 from '../../assets/images/insur-img-2.png';
import { Link } from 'react-router-dom';

const NewHomePageSlide5 = ({ HomePageSlide, animated, info, pause, play }) => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [mobileView, setMobileView] = useState(false);

  const { ref } = useInView();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    play();
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);

  return (
    <div className="banner-slide" id={`slide-${HomePageSlide.id}`}>
      {/* <div className="insuarnce-banner-title">
        <img src={DGVInsurTechTitle} alt="logo" />
      </div> */}
      <div
        ref={ref}
        className={
          animated === HomePageSlide.id
            ? 'insuarnce-banner-title animate__animated animate__fadeInDown'
            : 'insuarnce-banner-title animate__animated animate__fadeOutLeft'
        }
      >
        <img src={DGVInsurTechTitle} alt="logo" />
      </div>

      <div className="home-banner-img ">
        <img src={HomePageSlide.img} alt="banner" />
      </div>

      {/* <div className="image-bannner-box">
        <img src={DGVInsuranceBanner} alt="banner-insurance" />
      </div>
      <div className="subject-layer-box">
        <img src={DGVInsuranceSubjectLayer} alt="banner-insurance" />
      </div> */}

      {mobileView ? (
        <div className="group-section-wrapper">
          {/* <div className="group-section">
            <img src={DGVInsuranceSection1} alt="group-1" />
          </div> */}
          <div
            // ref={ref}
            className={
              animated === HomePageSlide.id
                ? 'group-section-one animate__animated animate__fadeInLeft animate__delay-3s'
                : 'group-section-one animate__animated animate__fadeOutLeft'
            }
          >
            <img src={DGVInsuranceSection1} alt="logo" />
          </div>
          <div
            // ref={ref}
            className={
              animated === HomePageSlide.id
                ? 'group-section-two animate__animated animate__fadeInRight animate__delay-3s'
                : 'group-section-two animate__animated animate__fadeOutLeft'
            }
          >
            <img src={DGVInsuranceSection2} alt="logo" />
          </div>
          {/* <div className="group-section-2">
            <img src={DGVInsuranceSection2} alt="group-2" />
          </div> */}
        </div>
      ) : (
        <>
          <div className="mob-group-section-wrapper">
            <div className="mob-group-section-box">
              {/* <img src={DGVInsuranceGropusImg} alt="" /> */}
              <div className="isure-banner-wrapper">
                {/* <div className="insur-banner-box">
                  <img src={InsurImg_1} alt="insureImg-1" />
                </div> */}
                <div
                  // ref={ref}
                  className={
                    animated === HomePageSlide.id
                      ? 'insur-banner-box animate__animated animate__fadeInLeft animate__delay-2s'
                      : 'insur-banner-box animate__animated animate__fadeOutLeft'
                  }
                >
                  <img src={InsurImg_1} alt="logo" />
                </div>
                <div
                  // ref={ref}
                  className={
                    animated === HomePageSlide.id
                      ? 'insur-banner-box animate__animated animate__fadeInRight animate__delay-2s'
                      : 'insur-banner-box animate__animated animate__fadeOutLeft'
                  }
                >
                  <img src={InsurImg_2} alt="logo" />
                </div>
                {/* <div className="insur-banner-box">
                  <img src={InsurImg_2} alt="insureImg-2" />
                </div> */}
              </div>

              <div className="insurance-grid">
                <div className="insurance-col-1">
                  {/* <div className="col-block">
                    <img src={ChecklistLeftTop} alt="Top Left Corner" />
                  </div> */}
                  <div
                    // ref={ref}
                    className={
                      animated === HomePageSlide.id
                        ? 'col-block animate__animated animate__fadeInLeft animate__delay-2s'
                        : 'col-block animate__animated animate__fadeOutLeft'
                    }
                  >
                    <img src={ChecklistLeftTop} alt="logo" />
                  </div>
                  <div
                    // ref={ref}
                    className={
                      animated === HomePageSlide.id
                        ? 'col-block animate__animated animate__fadeInLeft animate__delay-3s'
                        : 'col-block animate__animated animate__fadeOutLeft'
                    }
                  >
                    <img src={ChecklistLeftTop} alt="logo" />
                  </div>
                  {/* <div className="col-block">
                    <img src={ChecklistLeftTop} alt="Top Left Corner" />
                  </div> */}
                </div>
                {/* <div className="middle-col">
                  <img src={middleImage} alt="Middle Image" />
                </div> */}
                <div
                  // ref={ref}
                  className={
                    animated === HomePageSlide.id
                      ? 'middle-col animate__animated animate__bounceIn animate__delay-2s'
                      : 'middle-col animate__animated animate__fadeOutLeft'
                  }
                >
                  <img src={middleImage} alt="logo" />
                </div>
                <div className="insurance-col-1">
                  {/* <div className="col-block">
                    <img src={ChecklistRightTop} alt="Top Left Corner" />
                  </div> */}
                  <div
                    // ref={ref}
                    className={
                      animated === HomePageSlide.id
                        ? 'col-block animate__animated animate__fadeInRight animate__delay-3s'
                        : 'col-block animate__animated animate__fadeOutLeft'
                    }
                  >
                    <img src={ChecklistRightTop} alt="logo" />
                  </div>
                  <div
                    // ref={ref}
                    className={
                      animated === HomePageSlide.id
                        ? 'col-block animate__animated animate__fadeInRight animate__delay-3s'
                        : 'col-block animate__animated animate__fadeOutLeft'
                    }
                  >
                    <img src={ChecklistRightTop} alt="logo" />
                  </div>
                  {/* <div className="col-block">
                    <img src={ChecklistRightTop} alt="Top Left Corner" />
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="mob-group-section-box-2">
              <img src={DGVInsuranceChecklistTaskImg} alt="" />
            </div> */}
          </div>
          {/* <div className="mob-subject-img">
            <img src={DGVInsuranceSubjectBanner} alt="subject-img" />
          </div> */}
        </>
      )}

      <Link
        className={
          animated === HomePageSlide.id
            ? 'money-know-more animate__animated animate__fadeInUp '
            : 'money-know-more animate__animated animate__fadeOutLeft'
        }
        to={HomePageSlide.path}
      >
        {' '}
        know more
      </Link>

      {/* <div  
        // ref={ref}
        // className={
        //   animated === HomePageSlide.id
        //     ? 'animate__animated animate__fadeInDown'
        //     : 'animate__animated animate__fadeOutLeft'
        // }
        style={{ width: '300px' }}
      >
        {HomePageSlide.title}
        <img src={DGVConnectTitle} alt="DGV Connect" />
      </div> */}

      {/* <div className="banner-desc-slide3">
        <div
          ref={ref}
          className={
            animated === HomePageSlide.id
              ? 'bottom-mr banner-title animate__animated animate__fadeInDown'
              : 'bottom-mr  banner-title animate__animated animate__fadeOutLeft'
          }
        >
          {HomePageSlide.title}
        </div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'sub-title mob-title animate__animated animate__fadeInUp animate__delay-2s'
              : 'sub-title mob-title animate__animated animate__fadeOutLeft'
          }
          dangerouslySetInnerHTML={{ __html: HomePageSlide.description }}
        ></div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'know-more-slide3 animate__animated animate__fadeInUp animate__delay-3s'
              : 'know-more-slide3 animate__animated animate__fadeOutLeft'
          }
        >
          {' '}
        </div>
        <div
          className={
            animated === HomePageSlide.id
              ? 'u-tube-btn animate__animated animate__fadeInUp animate__delay-4s'
              : 'u-tube-btn animate__animated animate__fadeOutLeft'
          }
          onClick={pause}
        >
          <VideoButton handleClickOpen={() => handleClickOpen(HomePageSlide.link)}></VideoButton>
        </div>
        {open && <DialogVideo title={'partner'} link={activeLink} handleClose={handleClose} />}
      </div> */}
    </div>
  );
};

export default NewHomePageSlide5;
