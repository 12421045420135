import React from 'react';
import './InvestorPartner.css';

const InvestorPartner = () => {
  return (
    <div className="investorPartner" id="investerParner">
      <div className="investor-partner-wrapper">
        <div className="investor-heading">
          <h1>Investors</h1>
          {/* <p>India's leading Venture Capitalists believe in DGV's vision for Rural Finance</p> */}
        </div>
        <div className="logo-wrapper">
          <div className={'investor-logo-1 '}>
            <img src={require('../../assets/images/infoedge_ventures.png')} alt="infoedge ventures logo" />
          </div>
          <div className={'investor-logo-2 '}>
            <img src={require('../../assets/images/omnivore.png')} alt="omnivore logo" />
          </div>
          <div className={'investor-logo-3 '}>
            <img src={require('../../assets/images/omidyar.png')} alt="omnidyar logo" />
          </div>
        </div>
        {/* <div className="partner-heading">
          <h1>Dairy Partners</h1>
          <p>Collaborating with leading Dairy Brand</p>
        </div>
        <div className="logo-wrapper">
          <div className={'partner-logo '}>
            <img src={require('../../assets/images/amul_dairy.png')} alt="amul dairy logo" />
          </div>
          <div className={'partner-logo-sid-farm '}>
            <img src={require('../../assets/images/sid_farm.png')} alt="sid farm logo" />
          </div>
          <div className={'partner-logo-jersey '}>
            <img src={require('../../assets/images/jersey.png')} alt="jersey logo" />
          </div>
          <div className={'partner-logo-muktaa '}>
            <img src={require('../../assets/images/muktaa.png')} alt="muktaa logo" />
          </div>
        </div>
        <div className="partner-heading">
          <h1>Banking Partners</h1>
          <p>Backed by cutting edge innovators</p>
        </div>
        <div className="logo-wrapper">
          <div className={'partner-logo-bank '}>
            <a href={'https://www.federalbank.co.in/dairy-loans'} target="_blank">
              <img src={require('../../assets/images/federal_bank.png')} alt="federal bank logo" />
            </a>
          </div>
          <div className={'partner-logo-bank '}>
            <a href={'https://www.hdfcbank.com/agri/cattle-finance'} target="_blank">
              <img src={require('../../assets/images/hdfc_bank.png')} alt="hdfc bank logo" />
            </a>
          </div>
          <div className={'partner-logo '}>
            <img src={require('../../assets/images/karnataka_bank.png')} alt="karnataka bank logo" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default InvestorPartner;
