import React, { useEffect, useState } from 'react';
import ReusablePartnersComponent from './ReusablePartnersComponent';
import DairyUnion_mob_1 from '../../../assets/images/dairy-union-mob-1.png';
import DairyUnion_mob_2 from '../../../assets/images/dairy-union-mob-2.png';
import DairyUnion_desk_1 from '../../../assets/images/Dairy-union-desk-1.png';
import DairyUnion_desk_2 from '../../../assets/images/dairy-union-pertner-2.png';
import DairyUnion_desk_3 from '../../../assets/images/dairy-union-pertner-3.png';
import DairyUnion_desk_5 from '../../../assets/images/dairy-union-pertner-5.png';
import './DairyUnion.css';
import WithWatchBtnComponent from './WithWatchBtnComponent';
import WithoutWatchComponent from './WithoutWatchComponent';

function DairyUnion() {
  const [desktopView, setDesktopView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  const reusableCompoentData = [
    {
      imageUrl: DairyUnion_mob_1,
      title: 'H.R Joshi - Managing Director, Sarvottam Dairy',
      dics: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile...',
      link: `https://www.youtube.com/embed/OAoc7jJP9Pc`,
      bgcolor: 'rgba(223, 255, 190, 0.6)',
      popupData: {
        link: `https://www.youtube.com/embed/OAoc7jJP9Pc`,
        imagesSlideArry: [],
        heading: '',
        discription:
          'H.R Joshi, Managing Director - Sarvottam Dairy talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: '/static/media/dairyUnion-2.3185acaa6c69e45320e6.png', text: '' },
          // { imageUrl: '/static/media/dairyUnion-1.e5574b91f8b9c7562cb7.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-3.e3bdee56c4eae62b8213.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-4.f00030442f662723819d.png', text: '' },
        ],
      },
    },
    {
      imageUrl: DairyUnion_mob_2,
      title: 'Ashok Chaudhary ',
      dics: 'Chairman Dudhsagar Dairy',
      link: `https://www.youtube.com/embed/LDPcPptUiBs`,
      bgcolor: '#FFEB9C',
      popupData: {
        link: `https://www.youtube.com/embed/LDPcPptUiBs`,
        imagesSlideArry: [],
        heading: '',
        discription: 'Ashok Chaudhary Chairman Dudhsagar Dairy',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: '/static/media/dairyUnion-2.3185acaa6c69e45320e6.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-1.e5574b91f8b9c7562cb7.png', text: '' },
          // { imageUrl: '/static/media/dairyUnion-3.e3bdee56c4eae62b8213.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-4.f00030442f662723819d.png', text: '' },
        ],
      },
    },
  ];

  const withWatchBtnComponentData = {
    imageUrl: DairyUnion_desk_1,
    title: 'DAIRY',
    title_2: 'UNIONS',
    subTitle: 'H.R Joshi, Managing Director - Sarvottam Dairy',
    disc: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
    color: `rgba(223, 255, 190, 0.6)`,
    link: `https://www.youtube.com/embed/OAoc7jJP9Pc`,
    width: '440px',
    showWatchNowBtn: true,
    popupData: {
      link: `https://www.youtube.com/embed/OAoc7jJP9Pc`,
      imagesSlideArry: [],
      heading: '',
      discription:
        'H.R Joshi, Managing Director - Sarvottam Dairy talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [
        { imageUrl: '/static/media/dairyUnion-2.3185acaa6c69e45320e6.png', text: '' },
        // { imageUrl: '/static/media/dairyUnion-1.e5574b91f8b9c7562cb7.png', text: '' },
        { imageUrl: '/static/media/dairyUnion-3.e3bdee56c4eae62b8213.png', text: '' },
        { imageUrl: '/static/media/dairyUnion-4.f00030442f662723819d.png', text: '' },
      ],
    },
  };

  const withoutWatchNowData = [
    {
      imageUrl: DairyUnion_desk_3,
      title: 'Ashok Chaudhary',
      title_2: 'Chairman Dudhsagar Dairy',
      disc: '',
      link: `https://www.youtube.com/embed/LDPcPptUiBs`,
      //   bgcolor: 'rgba(109, 98, 192, 1)',
      popupData: {
        link: `https://www.youtube.com/embed/LDPcPptUiBs`,
        imagesSlideArry: [],
        heading: '',
        discription: 'Ashok Chaudhary Chairman Dudhsagar Dairy',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: '/static/media/dairyUnion-2.3185acaa6c69e45320e6.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-1.e5574b91f8b9c7562cb7.png', text: '' },
          // { imageUrl: '/static/media/dairyUnion-3.e3bdee56c4eae62b8213.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-4.f00030442f662723819d.png', text: '' },
        ],
      },
    },
    {
      imageUrl: DairyUnion_desk_5,
      title: 'Pinal Shah',
      title_2: 'GM - Finance & Accounts',
      disc: '',
      link: `https://www.youtube.com/embed/JHX5iXWHznk`,
      //   bgcolor: 'rgba(109, 98, 192, 1)',
      popupData: {
        link: `https://www.youtube.com/embed/JHX5iXWHznk`,
        imagesSlideArry: [],
        heading: '',
        discription: 'Pinal Shah GM - Finance & Accounts',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: '/static/media/dairyUnion-2.3185acaa6c69e45320e6.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-1.e5574b91f8b9c7562cb7.png', text: '' },
          { imageUrl: '/static/media/dairyUnion-3.e3bdee56c4eae62b8213.png', text: '' },
          // { imageUrl: '/static/media/dairyUnion-4.f00030442f662723819d.png', text: '' },
        ],
      },
    },
  ];

  const withoutWatchNowData_1 = {
    imageUrl: DairyUnion_desk_2,
    title: 'Gurdit Singh, Managing Director',
    disc: `discusses how DGV in Gujarat's Surendranagar district is revolutionizing rural finance and dairy operations emphasizing on how this initiative sets a benchmark for transparency and rural empowerment.`,
    link: `https://www.youtube.com/embed/W39kum43RT4`,
    bgcolor: 'rgba(179, 172, 149, 1)',
    popupData: {
      link: 'https://www.youtube.com/embed/W39kum43RT4',
      imagesSlideArry: [],
      heading: '',
      discription: 'Gurdit Singh, Managing Director',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [
        // { imageUrl: '/static/media/dairyUnion-2.3185acaa6c69e45320e6.png', text: '' },
        { imageUrl: '/static/media/dairyUnion-1.e5574b91f8b9c7562cb7.png', text: '' },
        { imageUrl: '/static/media/dairyUnion-3.e3bdee56c4eae62b8213.png', text: '' },
        { imageUrl: '/static/media/dairyUnion-4.f00030442f662723819d.png', text: '' },
      ],
    },
  };

  return desktopView ? (
    <div className="daily-union-desk-wrappwer">
      {/* // <div className="daily-union-desk-wrappwer">
    //   <WithWatchBtnComponent data={withWatchBtnComponentData} />
    //   <div className="daily-union-right">
    //     {withoutWatchNowData?.map((data, i) => {
    //       return (
    //         <div key={i} className="daily-union-box">
    //           <WithoutWatchComponent data={data} />
    //         </div>
    //       );
    //     })}
    //   </div>
    // </div> */}

      <div className="daily-union-left-section">
        <div className="daily-union-left-top-box">
          <WithoutWatchComponent data={withoutWatchNowData_1} className={'small-img'} />
          <div className="daily-farmer-right">
            {withoutWatchNowData?.map((data, i) => {
              return (
                <div key={i} className="daily-union-box">
                  <WithoutWatchComponent data={data} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="daily-union-left-bottom-box"></div>
      </div>
      <div className="daily-union-right-section">
        <WithWatchBtnComponent data={withWatchBtnComponentData} />
      </div>
    </div>
  ) : (
    <div className="daily-union-mob-wrappwer">
      {reusableCompoentData?.map((data) => (
        <ReusablePartnersComponent data={data} />
      ))}
    </div>
  );
}

export default DairyUnion;
