import React, { useEffect, useState } from 'react';
import ReusablePartnersComponent from './ReusablePartnersComponent';
import DairyFarmer_mob_1 from '../../../assets/images/DairyFarmer_mob-1.png';
import DairyFarmer_mob_2 from '../../../assets/images/DairyFarmer_mob-2.png';
import DairyFarmer_desk_2 from '../../../assets/images/dairy-farmer-desk-2.png';
import DairyFarmer_desk_3 from '../../../assets/images/dairy-farmer-desk-3.png';
import './DairyFarmer.css';
import WithWatchBtnComponent from './WithWatchBtnComponent';
import WithoutWatchComponent from './WithoutWatchComponent';

function DairyFarmer() {
  const [desktopView, setDesktopView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  const reusableCompoentData = [
    {
      imageUrl: DairyFarmer_mob_1,
      title: 'Ambaben Rabadia, Customer',
      dics: 'speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing...',
      link: `https://www.youtube.com/embed/WUDOCQhkT_w`,
      bgcolor: '#DFFFBE',
      popupData: {
        link: 'https://www.youtube.com/embed/WUDOCQhkT_w',
        imagesSlideArry: [],
        heading: '',
        discription: 'Ambaben Rabadia, Customer',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [{ imageUrl: DairyFarmer_mob_2, text: '' }],
      },
    },
    {
      imageUrl: DairyFarmer_mob_2,
      title: 'Ram, Customer ',
      dics: 'addresses challenges faced by dairy farmers & provides solutions by using DGV platform.',
      link: `https://www.youtube.com/embed/C3SZGKTx6T0`,
      bgcolor: '#FFEB9C',
      popupData: {
        link: 'https://www.youtube.com/embed/C3SZGKTx6T0',
        imagesSlideArry: [],
        heading: '',
        discription: 'Ram, Customer',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [{ imageUrl: DairyFarmer_mob_1, text: '' }],
      },
    },
  ];

  const withWatchBtnComponentData = {
    imageUrl: DairyFarmer_desk_3,
    title: 'DAIRY',
    title_2: 'FARMERS',
    subTitle: 'Ram, Customer',
    disc: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
    color: `rgba(223, 255, 190, 0.6)`,
    link: `https://www.youtube.com/embed/C3SZGKTx6T0`,
    width: '440px',
    popupData: {
      link: 'https://www.youtube.com/embed/C3SZGKTx6T0',
      imagesSlideArry: [],
      heading: '',
      discription:
        'Ram, Customer talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [{ imageUrl: DairyFarmer_desk_2, text: '' }],
    },
  };
  const withWatchBtnComponentData2 = {
    imageUrl: DairyFarmer_desk_2,
    title: 'DAIRY',
    title_2: 'FARMERS',
    subTitle: 'Ambaben Rabadia - Customer',
    disc: 'speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
    color: `rgb(255, 235, 156,0.6)`,
    link: `https://www.youtube.com/embed/WUDOCQhkT_w`,
    width: '440px',
    popupData: {
      link: 'https://www.youtube.com/embed/WUDOCQhkT_w',
      imagesSlideArry: [],
      heading: '',
      discription:
        'Ambaben Rabadia - Customer speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [{ imageUrl: DairyFarmer_desk_3, text: '' }],
    },
  };

  const withoutWatchNowData_1 = {
    imageUrl: DairyFarmer_desk_2,
    title: 'Ambaben Rabadia - Customer',
    disc: 'speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
    link: `https://www.youtube.com/embed/WUDOCQhkT_w`,
    bgcolor: '#557065',
    popupData: {
      link: 'https://www.youtube.com/embed/WUDOCQhkT_w',
      imagesSlideArry: [],
      heading: '',
      discription: 'Ambaben Rabadia - Customer',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [{ imageUrl: DairyFarmer_desk_3, text: '' }],
    },
  };

  const withoutWatchNowData = [
    {
      imageUrl: DairyFarmer_desk_2,
      title: 'Ambaben Rabadia - Customer',
      disc: '',
      link: `https://www.youtube.com/embed/WUDOCQhkT_w`,
      //   bgcolor: 'rgba(109, 98, 192, 1)',
      className: 'paddingBootm',
      popupData: {
        link: 'https://www.youtube.com/embed/WUDOCQhkT_w',
        imagesSlideArry: [],
        heading: '',
        discription: 'Ambaben Rabadia - Customer',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [{ imageUrl: DairyFarmer_desk_3, text: '' }],
      },
    },
    {
      imageUrl: DairyFarmer_desk_3,
      title: 'Ram - Customer',
      disc: '',
      link: `https://www.youtube.com/embed/C3SZGKTx6T0`,
      //   bgcolor: 'rgba(109, 98, 192, 1)',
      className: 'paddingBootm',
      popupData: {
        link: 'https://www.youtube.com/embed/C3SZGKTx6T0',
        imagesSlideArry: [],
        heading: '',
        discription: 'Ram, Customer',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [{ imageUrl: DairyFarmer_desk_2, text: '' }],
      },
    },
  ];
  return desktopView ? (
    <div className="daily-union-desk-wrappwer">
      {/* <div className="daily-union-left-section">
        <div className="daily-union-left-top-box">
          <WithoutWatchComponent data={withoutWatchNowData_1} className={'small-img'} />
          <div className="daily-farmer-right">
            {withoutWatchNowData?.map((data, i) => {
              return (
                <div key={i} className="daily-union-box">
                  <WithoutWatchComponent data={data} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="daily-union-left-bottom-box"></div>
      </div>
      <div className="daily-union-right-section">
        <WithWatchBtnComponent data={withWatchBtnComponentData} />
      </div> */}

      <div className="daily-societies-desk-wrappwer">
        <WithWatchBtnComponent data={withWatchBtnComponentData} />
        <WithWatchBtnComponent data={withWatchBtnComponentData2} />
      </div>
    </div>
  ) : (
    <div className="daily-union-mob-wrappwer">
      {reusableCompoentData?.map((data) => (
        <ReusablePartnersComponent data={data} />
      ))}
    </div>
  );
}

export default DairyFarmer;
