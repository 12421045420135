import React, { useState } from 'react';
import FormButton from '../FormComponents/FormButton/FormButton';
import './CareersApplyJob.css';
import { useEffect } from 'react';
const CareersApplyJob = ({ showDropDownApp }) => {
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <div className="carrers-first-apply-container">
      {mobileView ? (
        <div className="careers-firstApply-title">We're always looking for enthusiastic, talented people!</div>
      ) : (
        <div className="careers-firstApply-title">
          We're always looking for <br></br>enthusiastic, talented people!
        </div>
      )}
      <div className="careers-firstApply-Secondtitle">
        Come join our team of experts and accelerate your career the way you want to.
      </div>
      <div className="careers-join-us">
        Join Us : <a href={`mailto:${'careers@dgv.in'}`}>careers@dgv.in</a>
      </div>
      <div className="apply-carres-btn">
        <FormButton title="Apply Now" handleSubmit={() => showDropDownApp()} />
      </div>
    </div>
  );
};

export default CareersApplyJob;
