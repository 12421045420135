import React from 'react';
import BenefitsPay from '../../../components/BenefitsPay/BenefitsPay';
import { PAY_SLIDER_IMAGES, PRODUCT_PAY_INFO, BenefitsPayData } from '../../../utils/constants';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import DGVPayGraphic from '../../../assets/images/DGV Pay graphic.png';
import DGVPayGraphicMob from '../../../assets/images/DGV Pay Graphic mob.png';
import './DGVPay.css';
import ForMoreDetails from '../../../components/ForMoreDetails/ForMoreDetails';
import { useInView } from 'react-intersection-observer';
const DGVPay = () => {
  const { ref: desktopRef, inView: desktopInView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  const { ref: mobileRef, inView: mobileInView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  return (
    <div className="main-div desktop-view">
      <Header />
      <BreadCrumb />

      <div className="page-container page-content">
        <ProductInfoPlot info={PRODUCT_PAY_INFO} images={PAY_SLIDER_IMAGES} />
      </div>
      {/* <div className="pay-image-container animate__animated animate__flipInY">
        <img src={DGVPayGraphic} alt="DGV Pay Graphic" />
      </div>
      <div className="pay-image-container-mob animate__animated animate__flipInY">
        <img src={DGVPayGraphicMob} alt="connect animation" />
      </div> */}
      {/* Desktop Image Animation */}
      <div
        ref={desktopRef}
        className={desktopInView ? `pay-image-container animate__animated animate__flipInY` : 'pay-image-container'}
      >
        <img src={DGVPayGraphic} alt="DGV Pay Graphic" />
      </div>

      {/* Mobile Image Animation */}
      <div
        ref={mobileRef}
        className={`pay-image-container-mob animate__animated ${mobileInView ? 'animate__flipInY' : ''}`}
      >
        <img src={DGVPayGraphicMob} alt="connect animation" />
      </div>
      <div className="Product-benefits page-container">
        <BenefitsPay Benefits={BenefitsPayData} />
      </div>
      <ForMoreDetails mailId={'pay@dgv.in'} />
      <Footer />
    </div>
  );
};

export default DGVPay;
