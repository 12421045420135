import React from 'react';
import { useInView } from 'react-intersection-observer';
import './CareersPerksLogos.css';

const CareerPerksLogos = ({ props }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  return (
    <div ref={ref}>
      <div className={inView ? 'perks-logo-container animate__animated animate__flipInX' : 'perks-logo-container'}>
        <img src={require(`../../assets/images/Careers/${props.logoImg}`)} alt="PerkLogo"></img>

        <p className="perk-logo-title">{props.perkTitle}</p>
      </div>
    </div>
  );
};

export default CareerPerksLogos;
