import React from 'react';
import { useInView } from 'react-intersection-observer';
import BenefitHeading from '../BenefitHeading';
import 'animate.css';
import './BenefitsPay.css';

const BenefitsPay = ({ Benefits = [] }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <>
      <div>
        <BenefitHeading />
        <div ref={ref} className="benefits-wrapper">
          {Benefits.map((benefitsPayData) => (
            <div
              key={benefitsPayData.id}
              className={
                inView
                  ? `benefits-outer animate__animated animate__fadeInUp animate__delay-${benefitsPayData.delay} `
                  : 'benefits-outer'
              }
            >
              <div className="benefits-inner  ">
                <div className="benefits-contain ">{benefitsPayData.contain}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BenefitsPay;
