import React from 'react';
import './MeetOurCEO.css';
import linkedIn from '../../assets/images/linkedin.png';

const MeetOurCEO = () => {
  return (
    <>
      <div className="meet-our-CEO-main ">
        <div className="meet-our-CEO-header">Meet our CEO</div>
        <div className="meet-our-CEO-photo">
          <img className="CEO-img" src={require('../../assets/images/Meet-our-CEO.png')} alt="ceo Img" />
        </div>

        <div className="CEO-name ">RAGAVAN VENKATESAN</div>
        <div className="CEO-founder">FOUNDER & CEO, DGV Group</div>
        {/* <div className="CEO-linkedin">
          <a href="https://www.linkedin.com/in/ragavan-venkatesan-296a6a13" target="_blank" rel="noreferrer">
            <img src={linkedIn} />
          </a>
        </div> */}
        <div className={'CEO-para'}>
          We have dedicated ourselves to the Dairy sector and Dairy farmers in India to help them reap the entire
          benefit of technology and all elements of India Stack. We need to multiply the farmers income then dairy
          provides us the best avenue to increase their disposable income. Our country needs specialized organizations
          capable of building an ecosystem approach at ground level and deep understanding of the profile of customers.
        </div>
      </div>
    </>
  );
};

export default MeetOurCEO;
