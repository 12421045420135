import React from 'react';
import PhoneIcon from '../../assets/images/phone.png';
import TwitterIcon from '../../assets/images/twitter 1.svg';
import LinkedinIcon from '../../assets/images/linkedin 1.svg';
import YoutubeIcon from '../../assets/images/Combined-Shape.svg';
import instaIcon from '../../assets/images/insta.svg';
import facebookIcon from '../../assets/images/facebook.svg';
import mailicon from '../../assets/images/mailicon.svg';
import './ContactWrapper.css';

const ContactWrapper = () => {
  return (
    <div className="info-wrapper">
      <div className="contact-img mr-1">
        <a href="mailto:hello@dgv.in">{/* <img src={mailicon} alt="Phone"></img> */}</a>
      </div>
      <div className="phone LineHeight19">
        <a href="mailto:hello@dgv.in">hello@dgv.in</a>
      </div>

      <a
        href="https://www.linkedin.com/company/neobharatbanking/?originalSubdomain=in"
        className="contact-img mr-1"
        target="_blank"
        rel="noreferrer"
      >
        <img src={LinkedinIcon} alt="Linkedin"></img>
      </a>
      <a href="https://twitter.com/dgvwings" target="_blank" className="contact-img mr-1" rel="noreferrer">
        <img src={TwitterIcon} alt="Twitter"></img>
      </a>
      <a
        href="https://www.youtube.com/channel/UC8--WZaJ9ZxSh9C-jU8zifw"
        className="contact-img mr-1"
        target="_blank"
        rel="noreferrer"
      >
        <img src={YoutubeIcon} alt="Youtube"></img>
      </a>
      <a href="https://www.facebook.com/Digivriddhi/" className="contact-img mr-1" target="_blank" rel="noreferrer">
        <img src={facebookIcon} alt="Facebook"></img>
      </a>
      <a href="https://www.instagram.com/digi.vriddhi/" className="contact-img mr-1" target="_blank" rel="noreferrer">
        <img src={instaIcon} alt="Instagram"></img>
      </a>
    </div>
  );
};

export default ContactWrapper;
