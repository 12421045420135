import React from 'react';
import './ButtonWithIcon.css'; // Add this to apply CSS styles
import checkBoxMoneyBanner from '../../assets/images/check-mark-meney-banner.svg';

const ButtonWithIcon = ({ name }) => {
  return (
    <div className="button-container">
      <span className="button-text">{name?.toUpperCase()}</span>
      <div className="check-icon">
        <img src={checkBoxMoneyBanner} alt="checkBoxMoneyBanner" />
      </div>
    </div>
  );
};

export default ButtonWithIcon;

// import React from 'react';
// import './ButtonWithIcon.css';

// const ButtonWithIcon = ({ name }) => {
//   return (
//     <div className="button-container">
//       <span className="button-text">{name?.toUpperCase()}</span>
//     </div>
//   );
// };

// export default ButtonWithIcon;
