import React, { useEffect, useState } from 'react';
import VideoButton from '../../../components/VideoButton/VideoButton';
import { DialogVideo } from '../../../components/DialogVideo/DialogVideo';
import './WithWatchBtnComponent.css';
import CustomePopup from '../../../components/CustomePopup/CustomePopup';
import VideoButtonSmall from '../../../components/VideoButton/VideoButtonSmall';

function WithWatchBtnComponent({ data }) {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [desktopView, setDesktopView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div
      className="with-watch-now-main-component"
      style={{ width: data?.width, height: data?.height, background: data?.color }}
    >
      <div className="with-watch-now-image-box" onClick={() => data?.link && handleClickOpen(data?.link)}>
        <img src={data?.imageUrl} alt="partner_1" />
      </div>
      <div className="with-watch-now-info-box">
        <div className="with-watch-now-sub-title">{data?.subTitle}</div>
        {data?.disc && <div className="with-watch-now-disc">{data?.disc}</div>}
        {data?.showWatchNowBtn && (
          <div className="with-watch-now-btn">
            {desktopView ? (
              <VideoButton handleClickOpen={() => handleClickOpen(data?.link)}></VideoButton>
            ) : (
              <VideoButtonSmall handleClickOpen={() => handleClickOpen(data?.link)}></VideoButtonSmall>
            )}
          </div>
        )}
        {data?.showReadNowBtn &&
          (data?.donloadPDF ? (
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <div className="read-more-btn" onClick={() => window.open(data?.donloadPDF)}>
                {/* <a href={data?.readNowLink} target="_blank"> */}
                Read Now
                {/* </a> */}
              </div>
              {/* <VideoButton titles={''} text={'Read More'} handleClickOpen={() => handleClickOpen(link)}></VideoButton> */}
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <div className="read-more-btn">
                <a href={data?.readNowLink} target="_blank">
                  Read Now
                </a>
              </div>
              {/* <VideoButton titles={''} text={'Read More'} handleClickOpen={() => handleClickOpen(link)}></VideoButton> */}
            </div>
          ))}
        <div className="with-watch-now-box">
          <div className="with-watch-now-title">{data?.title}</div>
          {data?.title_2 && <div className="with-watch-now-title">{data?.title_2}</div>}
        </div>
      </div>
      {open && (
        <CustomePopup
          link={activeLink}
          onClose={handleClose}
          popupData={data?.popupData}
          isVisible={open}
          IsVideo={true}
        />
      )}
      {/* {open && <DialogVideo link={activeLink} handleClose={handleClose} />} */}
    </div>
  );
}

export default WithWatchBtnComponent;
