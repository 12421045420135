import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-3d-carousel';
import 'react-responsive-3d-carousel/dist/styles.css';
import CarouselItem from './CarouselItem';
import './style/custom-carousel.css';
const Carousel3D = ({ sliderData, handleClickOpen, handleImageClick, backgroundColor }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmounts

  // Can be any JSX.Element tag
  const items = sliderData.map((item, idx) => (
    <CarouselItem
      data={item}
      thumbnail={item.thumbnail}
      title={item.title}
      link={item.link}
      showBtn={activeIndex === idx && item?.showBtn}
      handleClickOpenPopup={handleClickOpen}
      showRedMoreBtn={activeIndex === idx && item?.showReadMore}
      backgroundColor={backgroundColor}
      targetId={activeIndex === idx && item?.targetId}
      handleImageClick={handleImageClick}
    />
  ));
  return (
    <section className="section">
      <Carousel
        items={items}
        startIndex={activeIndex}
        onChange={(currentIndex) => setActiveIndex(currentIndex)}
        autoPlay={false}
        interval={5000} // slide change time
        transformDuration={1000} // slide change animation time
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        transformTimingFn="ease-in-out" // uses css transition-timing-function: https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function#syntax
        swipeable={true} // Enables swipe gestures for navigating slides on touch devices.
        swipeDirection="horizontal"
        defaultOption={
          isMobile ? { angleFactor: 2, widthFactor: 2, depthFactor: 1 } : { angleFactor: 2, widthFactor: 1 }
        }
        containerHeight="auto"
        align="center"
        width={isMobile ? '60%' : '40%'}
        height={isMobile ? '31vh' : 'auto'}
        aspectRatio={isMobile ? '1.3' : '1.2'}
        numberOfSlides={3}
      />
    </section>
  );
};

export default Carousel3D;
