import React, { useEffect, useState } from 'react';
import './PartnerComponents.css';
import partner_1 from '../../../assets/images/partner-speak-partners-1.png';
import partner_2 from '../../../assets/images/partner-speak-partners-2.png';
import partner_3 from '../../../assets/images/partner-speak-partners-3.png';
import partner_mob_1 from '../../../assets/images/Partners-mob-1.png';
import partner_mob_2 from '../../../assets/images/Partners-mob-2.png';
import partner_mob_3 from '../../../assets/images/Partners-mob-3.png';
import WithWatchBtnComponent from './WithWatchBtnComponent';
import WithoutWatchComponent from './WithoutWatchComponent';
import VideoButton from '../../../components/VideoButton/VideoButton';
import ReusablePartnersComponent from './ReusablePartnersComponent';

function PartnerComponents() {
  const [desktopView, setDesktopView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);
  const withWatchBtnComponentData = {
    imageUrl: '/static/media/partner-speak-partners-1.dc3edafce8e84c1a0574.png',
    title: 'PARTNERS',
    subTitle: 'Dr. R S Sodhi, Former MD of GCMMF (AMUL) & President of Indian Dairy Association,',
    disc: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
    color: 'rgba(223, 255, 190, 0.6)',
    link: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
    width: '440px',
    popupData: {
      link: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
      imagesSlideArry: [],
      heading: 'AMUL',
      discription:
        'Dr. R S Sodhi, Former MD of GCMMF (AMUL) & President of Indian Dairy Association,talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
      showBtn: true,
      otherText: 'From Other',
      otherInfoArry: [
        { imageUrl: partner_2, text: 'FEDERAL' },
        { imageUrl: partner_3, text: 'AMUL' },
      ],
    },
  };

  const withoutWatchNowData_1 = {
    imageUrl: partner_2,
    title: 'Shalini Warrier - Executive Director at Federal Bank',
    disc: 'speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
    link: `https://www.youtube.com/embed/wAOLSJatSes`,
    bgcolor: 'rgba(109, 98, 192, 1)',
    popupData: {
      link: `https://www.youtube.com/embed/wAOLSJatSes`,
      imagesSlideArry: [],
      heading: 'FEDERAL',
      discription:
        'Shalini Warrier - Executive Director at Federal Bank speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
      showBtn: true,
      otherText: 'From Other',
      otherInfoArry: [
        { imageUrl: '/static/media/partner-speak-partners-1.dc3edafce8e84c1a0574.png', text: 'AMUL' },
        { imageUrl: partner_3, text: 'AMUL' },
      ],
    },
  };
  const withoutWatchNowData_2 = {
    imageUrl: partner_3,
    title: 'Atul Agarwal, Senior General Manager Finance at GCMMF (AMUL)',
    disc: 'addressing challenges faced by dairy farmers & provides solutions by using DGV platform.',
    link: `https://www.youtube.com/embed/8PvInu4a_TU`,
    bgcolor: 'rgba(193, 141, 116, 1)',
    popupData: {
      link: `https://www.youtube.com/embed/8PvInu4a_TU`,
      imagesSlideArry: [],
      heading: 'AMUL',
      discription:
        'Atul Agarwal, Senior General Manager Finance at GCMMF (AMUL) addressing challenges faced by dairy farmers & provides solutions by using DGV platform.',
      showBtn: true,
      otherText: 'From Other',
      otherInfoArry: [
        { imageUrl: '/static/media/partner-speak-partners-1.dc3edafce8e84c1a0574.png', text: 'AMUL' },
        { imageUrl: partner_2, text: 'FEDERAL' },
      ],
    },
  };

  const reusableCompoentData = [
    {
      imageUrl: partner_mob_1,
      title: 'Shalini Warrier - Executive Director at Federal Bank',
      dics: 'speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap...',
      link: `https://www.youtube.com/embed/wAOLSJatSes`,
      bgcolor: '#DFFFBE',
      popupData: {
        link: `https://www.youtube.com/embed/wAOLSJatSes`,
        imagesSlideArry: [],
        heading: 'FEDERAL',
        discription:
          'Shalini Warrier - Executive Director at Federal Bank speaks about DGV dedicating itself to the dairy ecosystem in India to help them reap the entire benefits of technology & providing last mile banking services to dairy farmers.',
        showBtn: true,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: '/static/media/partner-speak-partners-1.dc3edafce8e84c1a0574.png', text: 'AMUL' },
          { imageUrl: partner_3, text: 'AMUL' },
        ],
      },
    },
    {
      imageUrl: partner_mob_2,
      title: 'Atul Agarwal, Senior General Manager Finance at GCMMF (AMUL) ',
      dics: 'addresses challenges faced by dairy farmers & provides solutions by using DGV platform.',
      link: `https://www.youtube.com/embed/8PvInu4a_TU`,
      bgcolor: '#FFEB9C',
      popupData: {
        link: `https://www.youtube.com/embed/8PvInu4a_TU`,
        imagesSlideArry: [],
        heading: 'AMUL',
        discription:
          'Atul Agarwal, Senior General Manager Finance at GCMMF (AMUL) addressing challenges faced by dairy farmers & provides solutions by using DGV platform.',
        showBtn: true,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: '/static/media/partner-speak-partners-1.dc3edafce8e84c1a0574.png', text: 'AMUL' },
          { imageUrl: partner_2, text: 'FEDERAL' },
        ],
      },
    },
    {
      imageUrl: partner_mob_3,
      title: 'Dr. R S Sodhi, Former MD of GCMMF (AMUL) & President of Indian Dairy Association',
      dics: 'talks about how DGV provides dairy farmer’s access to institutional credit, last...',
      link: `https://www.youtube.com/embed/Uxm_3R5QBTc`,
      bgcolor: '#E2E1FF',
      popupData: {
        link: 'https://www.youtube.com/embed/Uxm_3R5QBTc',
        imagesSlideArry: [],
        heading: 'AMUL',
        discription:
          'Dr. R S Sodhi, Former MD of GCMMF (AMUL) & President of Indian Dairy Association,talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
        showBtn: true,
        otherText: 'From Other',
        otherInfoArry: [
          { imageUrl: partner_2, text: 'FEDERAL' },
          { imageUrl: partner_3, text: 'AMUL' },
        ],
      },
    },
  ];

  return (
    <div className="partner-component-main-component">
      {desktopView ? (
        <>
          <WithWatchBtnComponent data={withWatchBtnComponentData} />
          <div className="partner-components-right">
            <WithoutWatchComponent data={withoutWatchNowData_1} className={'small-img'} />
            <WithoutWatchComponent data={withoutWatchNowData_2} />
          </div>
        </>
      ) : (
        <div className="partner-mob-wrappwer">
          {reusableCompoentData?.map((data) => (
            <ReusablePartnersComponent data={data} />
          ))}
        </div>
      )}
    </div>
  );
}

export default PartnerComponents;
