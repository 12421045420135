import React from 'react';
import videoicon from '../../assets/images/play-video.png';
import './VideoButtonSmall.css';

export default function VideoButtonSmall({ title = '', handleClickOpen }) {
  return (
    <>
      <button className="_video_button-small" onClick={handleClickOpen}>
        <div className="play-icon-small">
          <img src={videoicon} alt="videoicon" />
        </div>
        <p className="watch-txt-small">Watch Now</p>
      </button>
      <p>{title}</p>
    </>
  );
}
