import React from 'react';
import { useNavigate } from 'react-router-dom';
// import HamFooterImg from '../../assets/images/hamburgerFooterImg.png';
import './Menu.css';

const Menu = ({ menuItems = [] }) => {
  let navigate = useNavigate();

  const onMenuClick = (listItem) => {
    if (listItem.linkTo) {
      navigate(listItem.linkTo);
      document.getElementById('hamburgerChk').checked = false;
      document.body.style.overflow = 'unset';
    }
  };

  const onSubMenuClick = (event, listItem) => {
    event.stopPropagation();
    navigate(listItem.linkTo);
    document.getElementById('hamburgerChk').checked = false;
    document.body.style.overflow = 'unset';
  };

  return (
    <>
      <ul className="nav-links ">
        {menuItems.map((menuItem) => (
          <li className="nav-items menu-items" key={menuItem.id} onClick={() => onMenuClick(menuItem)}>
            {menuItem.title}
            {menuItem?.subItems?.length && (
              <>
                <i className="arrow arrow-down"></i>
                <ul className="sub-items">
                  {menuItem?.subItems.map((subItem) => (
                    <li
                      key={subItem.id}
                      className={subItem.menuStyle}
                      onClick={(event) => onSubMenuClick(event, subItem)}
                    >
                      {subItem.title}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </li>
        ))}
        {/* <div className="hamurger-ft-img-container">
          <img src={HamFooterImg} alt="footerImg" className="Hamburger-Footer-Img"></img>
        </div> */}
      </ul>
    </>
  );
};

export default Menu;
