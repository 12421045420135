// import React, { useState } from 'react';
// import './PartnerSpeaks.css';

// function PartnerSpeaks() {
//   const [activeTab, setActiveTab] = useState('Partners');

//   // List of tabs and their associated content
//   const tabs = [
//     { label: 'Partners', content: 'Content for Partners' },
//     { label: 'Dairy Societies', content: 'Content for Dairy Societies' },
//     { label: 'Dairy Unions', content: 'Content for Dairy Unions' },
//     { label: 'Dairy Farmers', content: 'Content for Dairy Farmers' },
//   ];

//   // Handle click to update the active tab
//   const handleTabClick = (tabLabel) => {
//     setActiveTab(tabLabel);
//   };

//   return (
//     <div className="partner-speaks-container">
//       <div className="partner-speaks-nav">
//         <div className="nav-btn">Partners </div>
//         <div className="nav-btn">Dairy Societies</div>
//         <div className="nav-btn">Watch This Next</div>
//         <div className="nav-btn active-btn">Dairy Unions</div>
//         <div className="nav-btn">Dairy Farmers</div>
//       </div>
//     </div>
//   );
// }

// export default PartnerSpeaks;

import React, { useState } from 'react';
import './PartnerSpeaks.css';
import PartnerComponents from './PartnerSpeaksComponents/PartnerComponents';
import DairySocieties from './PartnerSpeaksComponents/DairySocieties';
import DairyUnion from './PartnerSpeaksComponents/DairyUnion';
import DairyFarmer from './PartnerSpeaksComponents/DairyFarmer';
import Carousel3D from '../../components/3DCarousel/Carousel3D';
import {
  PartnerSpeaksVideoSlider,
  DairySocityVideoSlider,
  DairyUnionVideoSlider,
  DairyFarmersVideoSlider,
} from '../../utils/constants';
import CustomePopup from '../../components/CustomePopup/CustomePopup';

function PartnerSpeaks() {
  const [activeTab, setActiveTab] = useState('Partners');
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [activePopupData, setActivePopupData] = useState();

  const handleClickOpen = (link, popupData) => {
    setActiveLink(link);
    setActivePopupData(popupData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sliderData = {
    Partners: PartnerSpeaksVideoSlider,
    'Dairy Societies': DairySocityVideoSlider,
    'Watch This Next': PartnerSpeaksVideoSlider, // Example, replace with actual slider data if needed
    'Dairy Unions': DairyUnionVideoSlider, // Example, replace with actual slider data if needed
    'Dairy Farmers': DairyFarmersVideoSlider, // Example, replace with actual slider data if needed
  };
  // List of tabs and their associated content
  const tabs = [
    { label: 'Partners', content: <PartnerComponents /> },
    { label: 'Dairy Societies', content: <DairySocieties /> },
    { label: 'Watch This Next', content: 'Content for Watch This Next' },
    { label: 'Dairy Unions', content: <DairyUnion /> },
    { label: 'Dairy Farmers', content: <DairyFarmer /> },
  ];

  // Handle click to update the active tab
  const handleTabClick = (tabLabel) => {
    if (tabLabel === 'Watch This Next') return; // Prevent click for "Watch This Next"
    setActiveTab(tabLabel);
  };

  return (
    <div className="partner-speaks-container">
      <div className="partner-speak-title">
        <div className="partner-speaks-1">PARTNER</div>
        <div className="partner-speaks-2">SPEAKS</div>
      </div>
      <div className="slider-box">
        <Carousel3D
          sliderData={sliderData[activeTab]}
          handleClickOpen={handleClickOpen}
          backgroundColor={'rgb(255 255 255)'}
        />
      </div>
      <div className="mob-WTN-text">Watch This Next</div>
      <div className="partner-speaks-nav">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`nav-btn ${activeTab === tab.label ? 'active-btn' : ''}`}
            onClick={() => handleTabClick(tab?.label)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="partner-speaks-content">{tabs.find((tab) => tab.label === activeTab)?.content}</div>
      {open && (
        <CustomePopup
          link={activeLink}
          onClose={handleClose}
          popupData={activePopupData?.popupData}
          isVisible={open}
          IsVideo={true}
        />
      )}
    </div>
  );
}

export default PartnerSpeaks;
