import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import VideoButton from '../VideoButton/VideoButton';
import { DialogVideo } from '../DialogVideo/DialogVideo';
import { useInView } from 'react-intersection-observer';
import './HomePageSlide.css';

const HomePageSlide = ({ HomePageSlide, animated, pause, play, watchNow }) => {
  const [mobileView, setMobileView] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const { ref } = useInView();
  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    play();
  };
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);

  return (
    <div className="banner-slide" id={`slide-${HomePageSlide.id}`}>
      <div className="home-banner-img ">
        <img src={HomePageSlide.img} alt="banner" />
        <div className="gradient-bg">
          <div className="banner-info">
            <div
              ref={ref}
              className={
                animated === HomePageSlide.id
                  ? 'new-banner-title animate__animated animate__fadeInDown animate__delay-1s'
                  : 'new-banner-title animate__animated animate__fadeOutLeft'
              }
            >
              {HomePageSlide.heading1}
            </div>
            {/* <div className="new-banner-title">{HomePageSlide.heading1}</div> */}
            <div
              className={
                animated === HomePageSlide.id
                  ? 'sub-title animate__animated animate__fadeInRight animate__delay-2s'
                  : 'sub-title animate__animated animate__fadeOutLeft'
              }
              dangerouslySetInnerHTML={{ __html: HomePageSlide.heading2 }}
            ></div>
            {/* <div className="sub-title">{HomePageSlide.heading2}</div> */}
            {/* <div className="dashed-line"></div> */}
            <div
              className={
                animated === HomePageSlide.id
                  ? 'dashed-line animate__animated animate__backInRight animate__delay-3s'
                  : 'dashed-line animate__animated animate__fadeOutLeft'
              }
              // dangerouslySetInnerHTML={{ __html: HomePageSlide.heading2 }}
            ></div>
          </div>
          <div
            className={
              animated === HomePageSlide.id
                ? 'banner-disc animate__animated animate__fadeInUpBig animate__delay-4s'
                : 'banner-disc animate__animated animate__fadeOutLeft'
            }
            dangerouslySetInnerHTML={{ __html: HomePageSlide.description }}
          ></div>
          {/* <div className="banner-disc">{HomePageSlide?.description}</div> */}
        </div>
        <Link
          className={
            animated === HomePageSlide.id
              ? 'know-more animate__animated animate__fadeInUp animate__delay-6s'
              : 'know-more animate__animated animate__fadeOutLeft'
          }
          to={HomePageSlide.path}
        >
          {' '}
          know more
        </Link>
      </div>

      {/* <div className="banner-desc"> */}
      {/* <div
          ref={ref}
          className={
            animated === HomePageSlide.id
              ? 'banner-title animate__animated animate__fadeInDown'
              : 'banner-title animate__animated animate__fadeOutLeft'
          }
        >
          {HomePageSlide.title}
        </div>

        <div className="banner-heading">
          <div
            className={
              animated === HomePageSlide.id
                ? 'head-1 animate__animated animate__fadeInLeft animate__delay-2s'
                : 'head-1 animate__animated animate__fadeOutLeft'
            }
            dangerouslySetInnerHTML={{ __html: HomePageSlide.heading1 }}
          ></div>

          <div
            className={
              animated === HomePageSlide.id
                ? 'head-2 animate__animated animate__fadeInRight animate__delay-4s'
                : 'head-2 animate__animated animate__fadeOutLeft'
            }
            dangerouslySetInnerHTML={{ __html: HomePageSlide.heading2 }}
          ></div>
        </div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'sub-title animate__animated animate__fadeInUp animate__delay-7s'
              : 'sub-title animate__animated animate__fadeOutLeft'
          }
          dangerouslySetInnerHTML={{ __html: HomePageSlide.description }}
        ></div> */}

      {/* <div className="know_and_watch">
          <Link
            className={
              animated === HomePageSlide.id
                ? 'know-more animate__animated animate__fadeInUp animate__delay-10s'
                : 'know-more animate__animated animate__fadeOutLeft'
            }
            to={HomePageSlide.path}
          >
            {' '}
            know more
          </Link>
          {watchNow && (
            <>
              <div
                className={
                  animated === HomePageSlide.id
                    ? 'u-tube-btn_slide animate__animated animate__fadeInUp animate__delay-10s'
                    : 'u-tube-btn_slide animate__animated animate__fadeOutLeft'
                }
                onClick={pause}
              >
                <VideoButton handleClickOpen={() => handleClickOpen(HomePageSlide.link)}></VideoButton>
              </div>
              {open && <DialogVideo title={'partner'} link={activeLink} handleClose={handleClose} />}
            </>
          )}
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default HomePageSlide;
