import React, { useEffect, useState } from 'react';
import grievanceRedressalImage from '../../../assets/images/grievance-redressal-image.png';
import Visit_us_verticle_line from '../../../assets/images/grievance-redressal-ver-line.png';
import ContactIcon from '../../../assets/images/contact-icon.png';
import EmailIdIcon from '../../../assets/images/email-Id-icon.png';
import grievanceRedressalTopLine from '../../../assets/images/grievance-redressal-top-line.svg';
import grievanceRedressalBottomLine from '../../../assets/images/grievance-redressal-bottom-line.svg';
import grievanceMobMiddleLine from '../../../assets/images/grievance-mob-middle-line.svg';
import grievanceMobBottomLine from '../../../assets/images/grievance-mon-bottom-line.svg';

import './GrievanceRedressal.css';

const GrievanceRedressal = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <div className="grievance-redressal-main-container page-content">
      <div className="grievance-redressal-info-section">
        <div className="grievance-redressal-title-box">
          <div className="grievance-redressal-heading">Grievance Redressal</div>
          <div className="grievance-redressal-sub-heading">
            If you are not satisfied with our services &<br></br> would like to submit a complaint, please<br></br>{' '}
            follow the procedure.
          </div>
        </div>
        <div className="grievance-redressal-image-box">
          {' '}
          <img src={grievanceRedressalImage} alt="banner-img" />
        </div>
      </div>

      {/* contact and mail section */}
      {mobileView ? (
        <>
          <div className="grievance-redressal-top-line">
            <img src={grievanceRedressalTopLine} alt="top line" />
          </div>

          <div className="grievance-redressal-social-section">
            <div className="grievance-redressal-contact-box">
              <div className="grievance-redressal-title-img">
                <div className="grievance-redressal-img">
                  <img src={ContactIcon} alt="contact" />
                </div>
                <div className="grievance-redressal-title">CONTACT</div>
              </div>
              <div className="grievance-redressal-info">+91 9646348348</div>
            </div>
            <div className="grievance-redressal-cin-line">
              <img src={Visit_us_verticle_line} alt="vertical-line" />
            </div>
            <div className="grievance-redressal-mail-box">
              <div className="grievance-redressal-title-img">
                <div className="grievance-redressal-img">
                  <img src={EmailIdIcon} alt="Email" />
                </div>
                <div className="grievance-redressal-title">MAIL ID</div>
              </div>
              <div className="grievance-redressal-info">
                Principal Nodal Officer : <a href="mailto:pno@dgv.in">pno@dgv.in</a>
              </div>
            </div>
          </div>
          <div className="grievance-redressal-bottom-line">
            <img src={grievanceRedressalBottomLine} alt="bottom line" />
          </div>
        </>
      ) : (
        <div className="grievance-mob-container">
          <div className="grievance-mob-contact">
            <div className="grievance-redressal-title-img">
              <div className="grievance-redressal-img">
                <img src={ContactIcon} alt="contact" />
              </div>
              <div className="grievance-redressal-title">CONTACT</div>
            </div>
            <div className="grievance-redressal-info">+91 9646348348</div>
          </div>
          <div className="grievance-mon-middle-line">
            <img src={grievanceMobMiddleLine} alt="middle-line"></img>
          </div>
          <div className="grievance-mob-email">
            <div className="grievance-redressal-title-img">
              <div className="grievance-redressal-img">
                <img src={EmailIdIcon} alt="Email" />
              </div>
              <div className="grievance-redressal-title">MAIL ID</div>
            </div>
            <div className="grievance-redressal-info">
              Principal Nodal Officer : <a href="mailto:pno@dgv.in">pno@dgv.in</a>
            </div>
          </div>
          <div className="grievance-mon-bottom-line">
            <img src={grievanceMobBottomLine} alt="bottom-line"></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default GrievanceRedressal;
