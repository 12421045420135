export const mahakumbh_24 = {
  silderImages: [
    { imagePath: require('../../assets/images/mahakumbh24_1.png') },
    { imagePath: require('../../assets/images/mahakumbh24_2.png') },
    { imagePath: require('../../assets/images/mahakumbh24_3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/mahakumbh24_1-mob.png') },
    { imagePath: require('../../assets/images/mahakumbh24_2-mob.png') },
    { imagePath: require('../../assets/images/mahakumbh24_3-mob.png') },
  ],
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/mahakumbh24_1.png') },
      { imagePath: require('../../assets/images/mahakumbh24_2.png') },
      { imagePath: require('../../assets/images/mahakumbh24_3.png') },
    ],
    heading: '',
    discription: 'Awarded the Aavishkaar Ubharta Sitara Award at the Startup Mahakumbh 2024',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/distributionMeet_24-1.png'),
        text: '',
      },
    ],
  },
  title: 'Awarded the Aavishkaar Ubharta Sitara Award at the Startup Mahakumbh 2024',
};
export const distributionMeet_24 = {
  silderImages: [
    { imagePath: require('../../assets/images/distributionMeet_24-2.png') },
    { imagePath: require('../../assets/images/distributionMeet_24-1.png') },
    { imagePath: require('../../assets/images/distributionMeet_24-3.png') },
    { imagePath: require('../../assets/images/distributionMeet_24-4.png') },
  ],
  title: 'DGV participates in The First IDF Regional Dairy Conference Asia Pacific 2024 in Kochi',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/distributionMeet_24-2.png') },
      { imagePath: require('../../assets/images/distributionMeet_24-1.png') },
      { imagePath: require('../../assets/images/distributionMeet_24-3.png') },
      { imagePath: require('../../assets/images/distributionMeet_24-4.png') },
    ],
    heading: '',
    discription: 'DGV participates in The First IDF Regional Dairy Conference Asia Pacific 2024 in Kochi',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/ConferenceKochi-2.png'),
        text: '',
      },
    ],
  },
};
export const ConferenceKochi_24 = {
  silderImages: [
    { imagePath: require('../../assets/images/ConferenceKochi-1.png') },
    { imagePath: require('../../assets/images/ConferenceKochi-2.png') },
    { imagePath: require('../../assets/images/ConferenceKochi-3.png') },
  ],
  title: 'DGV participates in The First IDF Regional Dairy Conference Asia Pacific 2024 in Kochi',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/ConferenceKochi-1.png') },
      { imagePath: require('../../assets/images/ConferenceKochi-2.png') },
      { imagePath: require('../../assets/images/ConferenceKochi-3.png') },
    ],
    heading: '',
    discription: 'DGV participates in The First IDF Regional Dairy Conference Asia Pacific 2024 in Kochi',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/forbesTop100DGV.png'),
        text: '',
      },
    ],
  },
};

export const forbesTop100DGV = {
  imageUrl: require('../../assets/images/forbesTop100DGV.png'),
  title: 'SEPTEMBER',
  title_2: '2024',
  subTitle: 'DGV’s achievements featured in Forbes’ Asia Top 100 Startups to Watch in September 2024',
  disc: '',
  color: `rgba(223, 255, 190, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  // readNowLink: 'https://www.forbes.com/sites/forbesasiateam/2024/08/26/forbes-asia-100-to-watch-2024/',
  donloadPDF: require('../../assets/documents/Forbes- Nov- 5 pages 1.pdf'),
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'DGV’s achievements featured in Forbes’ Asia Top 100 Startups to Watch in September 2024',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/omidyarFundingDGV.png'), text: '' }],
  },
};
export const omidyarFundingDGV = {
  imageUrl: require('../../assets/images/omidyarFundingDGV.png'),
  title: 'DECEMBER',
  title_2: '2023',
  subTitle:
    'Omidyar Network India leads INR 50 crores ($6 million) Series A funding round in dairy fintech platform DGV',
  disc: '',
  color: `rgba(255, 235, 156, 0.6)`,
  link: `https://www.youtube.com/embed/IDY6C3a8HJs`,
  width: '495px',
  height: '100%',
  showWatchNowBtn: true,
  showReadNowBtn: false,
  popupData: {
    link: `https://www.youtube.com/embed/IDY6C3a8HJs`,
    imagesSlideArry: [],
    heading: '',
    discription:
      'Omidyar Network India leads INR 50 crores ($6 million) Series A funding round in dairy fintech platform DGV',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/gff2023Showcase-2.png'), text: '' }],
  },
};
export const gff2023Showcase = {
  silderImages: [
    { imagePath: require('../../assets/images/gff2023Showcas-1.png') },
    { imagePath: require('../../assets/images/gff2023Showcase-2.png') },
    { imagePath: require('../../assets/images/gff2023Showcase-3.png') },
  ],
  title: 'Participated at GFF 2023 to showcase DGV Product Suite',
  titleWidth: '70%',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/gff2023Showcas-1.png') },
      { imagePath: require('../../assets/images/gff2023Showcase-2.png') },
      { imagePath: require('../../assets/images/gff2023Showcase-3.png') },
    ],
    heading: '',
    discription: 'Participated at GFF 2023 to showcase DGV Product Suite',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/rbiGovernorKPlecture-2.png'),
        text: '',
      },
    ],
  },
};
export const rbiGovernorKPlecture = {
  silderImages: [
    { imagePath: require('../../assets/images/rbiGovernorKPlecture-1.png') },
    { imagePath: require('../../assets/images/rbiGovernorKPlecture-2.png') },
    { imagePath: require('../../assets/images/rbiGovernorKPlecture-3.png') },
  ],
  title: 'Hon’ble RBI Governor, Mr. Shaktikanta Das at the 17th KP Hormis Memorable Lecture, Kochi',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/rbiGovernorKPlecture-1.png') },
      { imagePath: require('../../assets/images/rbiGovernorKPlecture-2.png') },
      { imagePath: require('../../assets/images/rbiGovernorKPlecture-3.png') },
    ],
    heading: '',
    discription: 'Hon’ble RBI Governor, Mr. Shaktikanta Das at the 17th KP Hormis Memorable Lecture, Kochi',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/investmentInDGV-1.png'),
        text: '',
      },
    ],
  },
};

export const investmentInDGV = {
  imageUrl: require('../../assets/images/investmentInDGV-1.png'),
  title: 'DECEMBER',
  title_2: '2023',
  subTitle: 'Improving financial health of dairy farmers and micro-enterprises: Why we invested in DGV',
  disc: '',
  color: `rgba(225, 244, 255, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://www.omidyarnetwork.in/blog/improving-financial-health-of-dairy-farmers-and-micro-enterprises-why-we-invested-in-dgv',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'Improving financial health of dairy farmers and micro-enterprises: Why we invested in DGV',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/dgvSeriesAFunding-1.png'), text: '' }],
  },
};
export const dgvSeriesAFunding = {
  imageUrl: require('../../assets/images/dgvSeriesAFunding-1.png'),
  title: 'DECEMBER',
  title_2: '2023',
  subTitle: 'Dairy fintech startup Digivriddhi raises $6 million in funding led by Omidyar Network India',
  disc: '',
  color: `rgba(223, 255, 190, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://economictimes.indiatimes.com/tech/funding/dairy-fintech-startup-digivriddhi-raises-6-million-in-funding-led-by-omidyar-network/articleshow/105746831.cms',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'Dairy fintech startup Digivriddhi raises $6 million in funding led by Omidyar Network India',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/dgvServiceGujarat-1.png'), text: '' }],
  },
};
export const dgvServiceGujarat = {
  imageUrl: require('../../assets/images/dgvServiceGujarat-1.png'),
  title: 'JUNE',
  title_2: '2021',
  subTitle: `DGV's service in Gujarat at Rajkot Dairy Union Anandpur`,
  disc: '',
  color: `rgba(255, 235, 156, 0.6)`,
  link: `https://www.youtube.com/embed/auN8NwS6xeo`,
  width: '495px',
  height: '100%',
  showWatchNowBtn: true,
  showReadNowBtn: false,
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: `DGV's service in Gujarat at Rajkot Dairy Union Anandpur`,
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/digitalBovineLoanJourneyRBI-2.png'), text: '' }],
  },
};
export const digitalBovineLoanJourneyRBI = {
  silderImages: [
    { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-1.png') },
    { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-2.png') },
    { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-1-mob.png') },
    { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-2-mob.png') },
    { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-3-mob.png') },
  ],
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-1.png') },
      { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-2.png') },
      { imagePath: require('../../assets/images/digitalBovineLoanJourneyRBI-3.png') },
    ],
    heading: '',
    discription: 'Showcasing Digital Bovine Loan Journey with our partner Banks to entire Board of RBI',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/omidyarSeriesAFunding-1.png'),
        text: '',
      },
    ],
  },
  title: 'Showcasing Digital Bovine Loan Journey with our partner Banks to entire Board of RBI',
};

export const omidyarSeriesAFunding = {
  imageUrl: require('../../assets/images/omidyarSeriesAFunding-1.png'),
  title: 'DECEMBER',
  title_2: '2023',
  subTitle: 'Omidyar Network India Leads Rs 50 Crore Series A Funding for Dairy Fintech Platform DGV',
  disc: '',
  color: `rgba(225, 244, 255, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://startupstorymedia.com/insights-omidyar-network-india-leads-rs-50-crore-series-a-funding-for-dairy-fintech-platform-dgv/',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'Omidyar Network India Leads Rs 50 Crore Series A Funding for Dairy Fintech Platform DGV',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/digivriddhiSeriesAFundUsage-1.png'), text: '' }],
  },
};
export const digivriddhiSeriesAFundUsage = {
  imageUrl: require('../../assets/images/digivriddhiSeriesAFundUsage-1.png'),
  title: 'DECEMBER',
  title_2: '2023',
  subTitle: 'Digivriddhi Technologies to utilise $6 million Series A fundraise for geographical expansion',
  disc: '',
  color: `rgba(223, 255, 190, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://www.cnbctv18.com/videos/startup/startup-funding-digivriddhi-technologies-dairy-fintech-18498701.htm',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'Digivriddhi Technologies to utilise $6 million Series A fundraise for geographical expansion',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/digitalDairyLoansAMCSIntegration-1.png'), text: '' }],
  },
};
export const digitalDairyLoansAMCSIntegration = {
  imageUrl: require('../../assets/images/digitalDairyLoansAMCSIntegration-1.png'),
  title: 'JULY',
  title_2: '2023',
  subTitle: `Enabling Digital Dairy Loans with milk suppliers AMCS data`,
  disc: '',
  color: `rgba(255, 235, 156, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://timesofindia.indiatimes.com/business/india-business/rbi-uses-milk-supply-data-for-dairy-farmer-loans/amp_articleshow/101841261.cms',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: `Enabling Digital Dairy Loans with milk suppliers AMCS data`,
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/idaDairyIndustryConference2023-2.png'), text: '' }],
  },
};
export const idaDairyIndustryConference2023 = {
  silderImages: [
    { imagePath: require('../../assets/images/idaDairyIndustryConference2023-1.png') },
    { imagePath: require('../../assets/images/idaDairyIndustryConference2023-2.png') },
    { imagePath: require('../../assets/images/idaDairyIndustryConference2023-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/idaDairyIndustryConference2023-1-mob.png') },
    { imagePath: require('../../assets/images/idaDairyIndustryConference2023-2-mob.png') },
    { imagePath: require('../../assets/images/idaDairyIndustryConference2023-3-mob.png') },
  ],
  title: '49th IDA Dairy Industry Conference from 16th -18th March 2023, Gandhinagar Gujarat',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/idaDairyIndustryConference2023-1.png') },
      { imagePath: require('../../assets/images/idaDairyIndustryConference2023-2.png') },
      { imagePath: require('../../assets/images/idaDairyIndustryConference2023-3.png') },
    ],
    heading: '',
    discription: '49th IDA Dairy Industry Conference from 16th -18th March 2023, Gandhinagar Gujarat',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/nasscomIndiaFintechAward2023-2.png'),
        text: '',
      },
    ],
  },
};
export const nasscomIndiaFintechAward2023 = {
  silderImages: [
    { imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-1.png') },
    {
      imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-2.png'),
      videoUrl: 'https://www.youtube.com/embed/xJNGXrry1tA?si=IDC1RBOjnfjyptBL',
    },
    { imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-1-mob.png') },
    {
      imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-2-mob.png'),
      videoUrl: 'https://www.youtube.com/embed/xJNGXrry1tA?si=IDC1RBOjnfjyptBL',
    },
    { imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-3-mob.png') },
  ],
  title: 'Winner of NASSCOM India Fintech Award 2023 in Payments Category',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-1.png') },
      {
        imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-2.png'),
        videoUrl: 'https://www.youtube.com/embed/xJNGXrry1tA?si=IDC1RBOjnfjyptBL',
      },
      { imagePath: require('../../assets/images/nasscomIndiaFintechAward2023-3.png') },
    ],
    heading: '',
    discription: 'Winner of NASSCOM India Fintech Award 2023 in Payments Category',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/dgvFirstInstitutionalFunding-1.png'),
        text: '',
      },
    ],
  },
};
export const dgvFirstInstitutionalFunding = {
  imageUrl: require('../../assets/images/dgvFirstInstitutionalFunding-1.png'),
  title: 'NOVEMBER',
  title_2: '2021',
  subTitle: 'Dairy-focused neobank DGV gets $3.1 million in first institutional funding',
  disc: '',
  color: `rgba(223, 255, 190, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://economictimes.indiatimes.com/tech/funding/dairy-focused-neobank-dgv-gets-3-1-million-in-first-institutional-funding/articleshow/87730311.cms?from=mdr',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'Dairy-focused neobank DGV gets $3.1 million in first institutional funding',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/rajkotDairyCashDoorstep-1.png'), text: '' }],
  },
};
export const rajkotDairyCashDoorstep = {
  imageUrl: require('../../assets/images/rajkotDairyCashDoorstep-1.png'),
  title: 'JUNE',
  title_2: '2021',
  subTitle: `Rajkot's dairy brings cash to the doorstep of milk producers`,
  disc: '',
  color: `rgba(255, 235, 156, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://www.thehindubusinessline.com/economy/agri-business/rajkot-dairy-brings-cash-to-the-doorstep-of-milk-producers/article34771231.ece',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: `Rajkot's dairy brings cash to the doorstep of milk producers`,
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/dgvPlatformLaunchSarhadDairy-2.png'), text: '' }],
  },
};
export const dgvPlatformLaunchSarhadDairy = {
  silderImages: [
    {
      imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-1.png'),
      videoUrl: 'https://www.youtube.com/embed/JPsySw3X9M8?si=2DbAFVvRePMPtiUA',
    },
    { imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-2.png') },
    { imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-3.jpg') },
  ],
  mobsilderImages: [
    {
      imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-1-mob.png'),
      videoUrl: 'https://www.youtube.com/embed/JPsySw3X9M8?si=2DbAFVvRePMPtiUA',
    },
    { imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-2-mob.png') },
    { imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-3-mob.png') },
  ],
  title: 'Launch of DGV Platform services at Sarhad Dairy (Kutch)',
  //   titleWidth: '70%',
  popupData: {
    imagesSlideArry: [
      {
        imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-1.png'),
        videoUrl: 'https://www.youtube.com/embed/JPsySw3X9M8?si=2DbAFVvRePMPtiUA',
      },
      { imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-2.png') },
      { imagePath: require('../../assets/images/dgvPlatformLaunchSarhadDairy-3-mob.png') },
    ],
    heading: '',
    discription: 'Launch of DGV Platform services at Sarhad Dairy (Kutch)',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-1.png'),
        text: '',
      },
    ],
  },
};
export const dgvAmulMicroATMLaunchSarvottamDairy = {
  silderImages: [
    { imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-1.png') },
    {
      imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-2.png'),
      videoUrl: 'https://www.youtube.com/embed/mx6xo0AlKYA?si=MEmhMnxMzILx--w_',
    },
    { imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-new-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-1-mob.png') },
    {
      imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-2-mob.png'),
      videoUrl: 'https://www.youtube.com/embed/mx6xo0AlKYA?si=MEmhMnxMzILx--w_',
    },
    { imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-3-mob.png') },
  ],
  title: 'Launch of DGV Amul Micro ATM at Sarvottam Dairy by MD, Chairman & Team of Bhavnagar Milk Union',
  //   titleWidth: '70%',
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-1.png') },
      {
        imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-2.png'),
        videoUrl: 'https://www.youtube.com/embed/mx6xo0AlKYA?si=MEmhMnxMzILx--w_',
      },
      { imagePath: require('../../assets/images/dgvAmulMicroATMLaunchSarvottamDairy-new-3.png') },
    ],
    heading: '',
    discription: 'Launch of DGV Amul Micro ATM at Sarvottam Dairy by MD, Chairman & Team of Bhavnagar Milk Union',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-2.png'),
        text: '',
      },
    ],
  },
};
export const ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022 = {
  silderImages: [
    { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-1.png') },
    { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-2.png') },
    { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-1-mob.png') },
    { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-2-mob.png') },
    { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-3-mob.png') },
  ],
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-1-mob.png') },
      { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-2-mob.png') },
      { imagePath: require('../../assets/images/ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022-3-mob.png') },
    ],
    heading: '',
    discription: 'Ragavan Venkatesan, Founder & CEO, DGV Group Speech at India International Dairy Expo 2022',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/innovationDairyExpo2022Day2-1.png'),
        text: '',
      },
    ],
  },
  title: 'Ragavan Venkatesan, Founder & CEO, DGV Group Speech at India International Dairy Expo 2022',
};
export const innovationDairyExpo2022Day2 = {
  imageUrl: require('../../assets/images/innovationDairyExpo2022Day2-1.png'),
  title: 'APRIL',
  title_2: '2022',
  subTitle: 'Innovation in Dairy Sector- India International Dairy Expo 2022 – Day 2',
  disc: '',
  color: `rgba(225, 244, 255, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://www.foodtechbiz.com/iide-2022/india-international-dairy-expo-iide-indias-most-comprehensive-dairy-and-dairy-technology-trade-fair-concludes-successfully-from-13-15-april-2022',

  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'Innovation in Dairy Sector- India International Dairy Expo 2022 – Day 2',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/IIDE2022DigivriddhiNeoBanking-1.png'), text: '' }],
  },
};
export const IIDE2022DigivriddhiNeoBanking = {
  imageUrl: require('../../assets/images/IIDE2022DigivriddhiNeoBanking-1.png'),
  title: 'APRIL',
  title_2: '2022',
  subTitle: 'IIDE 2022: Meet Digivriddhi Technologies, a Dairy-focused Neo-Banking',
  disc: '',
  color: `rgba(223, 255, 190, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink:
    'https://www.foodtechbiz.com/iide-2022/meet-digivriddhi-technologies-a-dairy-focused-neo-banking-startup-at-iide',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'IIDE 2022: Meet Digivriddhi Technologies, a Dairy-focused Neo-Banking',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/AmulMicroATMDigitalPayments-1.png'), text: '' }],
  },
};
export const AmulMicroATMDigitalPayments = {
  imageUrl: require('../../assets/images/AmulMicroATMDigitalPayments-1.png'),
  title: 'MARCH',
  title_2: '2020',
  subTitle: 'IIDE 2022: Meet Digivriddhi Technologies, a Dairy-focused Neo-Banking',
  disc: '',
  color: `rgba(255, 235, 156, 0.6)`,
  link: ``,
  width: '495px',
  height: '100%',
  showWatchNowBtn: false,
  showReadNowBtn: true,
  readNowLink: 'https://www.dairyindustries.com/news/37519/amul-offers-micro-atm-payment-for-dairy-farmers/',
  popupData: {
    link: ``,
    imagesSlideArry: [],
    heading: '',
    discription: 'IIDE 2022: Meet Digivriddhi Technologies, a Dairy-focused Neo-Banking',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [{ imageUrl: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-2.png'), text: '' }],
  },
};
export const DGVAmulMicroATMSursagarLimbdi = {
  silderImages: [
    { imagePath: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-1.png') },
    { imagePath: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-2.png') },
    { imagePath: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-3.png') },
  ],
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-1.png') },
      { imagePath: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-2.png') },
      { imagePath: require('../../assets/images/DGVAmulMicroATMSursagarLimbdi-3.png') },
    ],
    heading: '',
    discription: 'Launch of DGV Amul Micro ATM at Sursagar Dairy, Limbdi',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/DubaiExpo2022-1.png'),
        text: '',
      },
    ],
  },
  title: 'Launch of DGV Amul Micro ATM at Sursagar Dairy, Limbdi',
};
export const DubaiExpo2022 = {
  silderImages: [
    { imagePath: require('../../assets/images/DubaiExpo2022-1.png') },
    { imagePath: require('../../assets/images/DubaiExpo2022-2.png') },
    { imagePath: require('../../assets/images/DubaiExpo2022-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/DubaiExpo2022-1-mob.png') },
    { imagePath: require('../../assets/images/DubaiExpo2022-2-mob.png') },
    { imagePath: require('../../assets/images/DubaiExpo2022-3-mob.png') },
  ],
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/DubaiExpo2022-1.png') },
      { imagePath: require('../../assets/images/DubaiExpo2022-2.png') },
      { imagePath: require('../../assets/images/DubaiExpo2022-3.png') },
    ],
    heading: '',
    discription: 'Dubai Expo 2022',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/UnionMinisterRupalaDGV-1.png'),
        text: '',
      },
    ],
  },
  title: 'Dubai Expo 2022',
};
export const UnionMinisterRupalaDGV = {
  silderImages: [
    { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-1.png') },
    { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-2.png') },
    { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-3.png') },
  ],
  mobsilderImages: [
    { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-1-mob.png') },
    { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-2-mob.png') },
    { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-3-mob.png') },
  ],
  popupData: {
    imagesSlideArry: [
      { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-1.png') },
      { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-2.png') },
      { imagePath: require('../../assets/images/UnionMinisterRupalaDGV-3.png') },
    ],
    heading: '',
    discription:
      'Union Minister for Fisheries, Animal Husbandry and Dairying, Shri Parshottam Rupala @ DGV - Rajkot, Gujarat',
    showBtn: false,
    otherText: 'Other Events',
    otherInfoArry: [
      {
        imageUrl: require('../../assets/images/mahakumbh24_1.png'),
        text: '',
      },
    ],
  },
  titleWidth: '70%',
  title: 'Union Minister for Fisheries, Animal Husbandry and Dairying, Shri Parshottam Rupala @ DGV - Rajkot, Gujarat',
};
