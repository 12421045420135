import React from 'react';
import './MediaEventsMob.css';
import Component4x2 from '../../LifeAtDGVInside/Component4x2';
import {
  AmulMicroATMDigitalPayments,
  ConferenceKochi_24,
  dgvAmulMicroATMLaunchSarvottamDairy,
  DGVAmulMicroATMSursagarLimbdi,
  dgvFirstInstitutionalFunding,
  dgvPlatformLaunchSarhadDairy,
  dgvSeriesAFunding,
  dgvServiceGujarat,
  digitalBovineLoanJourneyRBI,
  digitalDairyLoansAMCSIntegration,
  digivriddhiSeriesAFundUsage,
  DubaiExpo2022,
  forbesTop100DGV,
  gff2023Showcase,
  idaDairyIndustryConference2023,
  IIDE2022DigivriddhiNeoBanking,
  innovationDairyExpo2022Day2,
  investmentInDGV,
  mahakumbh_24,
  nasscomIndiaFintechAward2023,
  omidyarFundingDGV,
  omidyarSeriesAFunding,
  ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022,
  rajkotDairyCashDoorstep,
  rbiGovernorKPlecture,
  UnionMinisterRupalaDGV,
} from '../MediaeventsData';
import WithWatchBtnComponent from '../../PartnerSpeaks/PartnerSpeaksComponents/WithWatchBtnComponent';

function MediaEventsMob() {
  return (
    <div className="events-desktop-container">
      <Component4x2
        sliderImagesData={mahakumbh_24?.mobsilderImages}
        title={mahakumbh_24?.title}
        popupData={mahakumbh_24?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={forbesTop100DGV} />
        </div>
        <div className="box-1x1">
          <WithWatchBtnComponent data={omidyarFundingDGV} />
        </div>
      </div>
      <Component4x2
        sliderImagesData={ConferenceKochi_24?.silderImages}
        title={ConferenceKochi_24?.title}
        popupData={ConferenceKochi_24?.popupData}
      />
      <Component4x2
        targetId={'mediaEvents-1'}
        data={gff2023Showcase}
        sliderImagesData={gff2023Showcase?.silderImages}
        title={gff2023Showcase?.title}
        popupData={gff2023Showcase?.popupData}
      />
      <Component4x2
        sliderImagesData={digitalBovineLoanJourneyRBI?.mobsilderImages}
        title={digitalBovineLoanJourneyRBI?.title}
        popupData={digitalBovineLoanJourneyRBI?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={investmentInDGV} />
        </div>
        <div className="box-1x1">
          <WithWatchBtnComponent data={dgvSeriesAFunding} />
        </div>
      </div>
      <Component4x2
        data={rbiGovernorKPlecture}
        sliderImagesData={rbiGovernorKPlecture?.silderImages}
        title={rbiGovernorKPlecture?.title}
        popupData={rbiGovernorKPlecture?.popupData}
      />
      <Component4x2
        sliderImagesData={idaDairyIndustryConference2023?.mobsilderImages}
        title={idaDairyIndustryConference2023?.title}
        popupData={idaDairyIndustryConference2023?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={omidyarSeriesAFunding} />
        </div>
        <div className="box-1x1">
          <WithWatchBtnComponent data={digivriddhiSeriesAFundUsage} />
        </div>
      </div>

      <Component4x2
        sliderImagesData={nasscomIndiaFintechAward2023?.mobsilderImages}
        title={nasscomIndiaFintechAward2023?.title}
        popupData={nasscomIndiaFintechAward2023?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={digitalDairyLoansAMCSIntegration} />
        </div>
        <div className="box-1x1">
          <WithWatchBtnComponent data={innovationDairyExpo2022Day2} />
        </div>
      </div>
      <Component4x2
        data={dgvPlatformLaunchSarhadDairy}
        sliderImagesData={dgvPlatformLaunchSarhadDairy?.mobsilderImages}
        title={dgvPlatformLaunchSarhadDairy?.title}
        popupData={dgvPlatformLaunchSarhadDairy?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={IIDE2022DigivriddhiNeoBanking} />
        </div>
        <div className="box-1x1">
          <WithWatchBtnComponent data={AmulMicroATMDigitalPayments} />
        </div>
      </div>
      <Component4x2
        data={dgvAmulMicroATMLaunchSarvottamDairy}
        sliderImagesData={dgvAmulMicroATMLaunchSarvottamDairy?.mobsilderImages}
        title={dgvAmulMicroATMLaunchSarvottamDairy?.title}
        popupData={dgvAmulMicroATMLaunchSarvottamDairy?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={rajkotDairyCashDoorstep} />
        </div>
        <div className="box-1x1">
          <WithWatchBtnComponent data={dgvFirstInstitutionalFunding} />
        </div>
      </div>
      <Component4x2
        sliderImagesData={ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022?.mobsilderImages}
        title={ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022?.title}
        popupData={ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022?.popupData}
      />
      <div className="container-1x1">
        <div className="box-1x1">
          <WithWatchBtnComponent data={dgvServiceGujarat} />
        </div>
        <div className="box-1x1">
          <Component4x2
            sliderImagesData={DGVAmulMicroATMSursagarLimbdi?.silderImages}
            title={DGVAmulMicroATMSursagarLimbdi?.title}
            popupData={DGVAmulMicroATMSursagarLimbdi?.popupData}
            slidesToScroll={1}
            slidesToShow={1}
            height={'230px'}
          />
        </div>
      </div>
      <Component4x2
        sliderImagesData={DubaiExpo2022?.mobsilderImages}
        title={DubaiExpo2022?.title}
        popupData={DubaiExpo2022?.popupData}
      />
      <Component4x2
        data={UnionMinisterRupalaDGV}
        sliderImagesData={UnionMinisterRupalaDGV?.mobsilderImages}
        title={UnionMinisterRupalaDGV?.title}
        popupData={UnionMinisterRupalaDGV?.popupData}
      />
    </div>
  );
}

export default MediaEventsMob;
