import React, { useEffect, useState } from 'react';
import './Component4x2.css';
import Slider from 'react-slick';
import CustomePopup from '../../components/CustomePopup/CustomePopup';
import { DialogVideo } from '../../components/DialogVideo/DialogVideo';

function Component4x2({
  targetId,
  data,
  sliderImagesData,
  title,
  setPopupVisible = () => {},
  isthirdSection = false,
  slidesToScroll = 2,
  slidesToShow = 2,
  popupData = {},
  className,
  bgcolor,
  height,
}) {
  const [desktopView, setDesktopView] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenVideo = (videoLink) => {
    setOpen(true);
    setIsVideo(true);
    setActiveVideoUrl(videoLink);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: false,
  };
  return (
    <div
      id={targetId}
      className={`component-4X2-section ${className}`}
      style={{ backgroundColor: bgcolor, height: height }}
    >
      <div className="component-4X2-wrapper">
        {desktopView ? (
          <>
            {sliderImagesData?.map((image) => (
              <div
                className="component-4X2-images"
                onClick={() => {
                  if (image?.videoUrl) {
                    handleClickOpenVideo(image?.videoUrl);
                  } else {
                    setPopupVisible(true);
                    handleClickOpen();
                    setIsVideo(false);
                  }
                }}
              >
                <img src={image?.imagePath} alt="image" />
              </div>
            ))}
          </>
        ) : (
          <Slider {...settings}>
            {sliderImagesData?.map((image) => (
              <div
                className="component-4X2-images"
                onClick={() => {
                  if (image?.videoUrl) {
                    handleClickOpenVideo(image?.videoUrl);
                  } else {
                    setPopupVisible(true);
                    handleClickOpen();
                    setIsVideo(false);
                  }
                }}
              >
                <img src={image?.imagePath} alt="image" />
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div className="line"></div>
      <div
        style={{ width: data?.titleWidth }}
        className={isthirdSection ? 'component-4X2-third-section-text' : 'component-4X2-text'}
      >
        {title}
      </div>
      {open && (
        <CustomePopup
          onClose={handleClose}
          link={activeVideoUrl}
          popupData={popupData}
          isVisible={open}
          IsVideo={isVideo}
        />
      )}
    </div>
  );
}

export default Component4x2;
