import React, { useState } from 'react';
import './WithoutWatchComponent.css';
import { DialogVideo } from '../../../components/DialogVideo/DialogVideo';
import CustomePopup from '../../../components/CustomePopup/CustomePopup';

function WithoutWatchComponent({ data, className = '' }) {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="without-watch-now-section-top">
      <div className="without-watch-now-image-section" style={{ backgroundColor: data?.bgcolor }}>
        <div className={`without-watch-now-image-box ${className}`} onClick={() => handleClickOpen(data?.link)}>
          <img src={data?.imageUrl} alt="partner_2" />
        </div>
      </div>
      <div className="middle-line"></div>
      <div className="without-watch-now-info-section">
        <div className={`without-watch-now-title-text ${data?.className}`}>{data?.title} </div>
        {data?.title_2 && <div className="without-watch-now-title-text">{data?.title_2} </div>}
        {data?.disc && <div className="without-watch-now-dics">{data?.disc}</div>}
      </div>
      {/* {open && <DialogVideo link={activeLink} handleClose={handleClose} />} */}
      {open && (
        <CustomePopup
          link={activeLink}
          onClose={handleClose}
          popupData={data?.popupData}
          isVisible={open}
          IsVideo={true}
        />
      )}
    </div>
  );
}

export default WithoutWatchComponent;
