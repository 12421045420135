// import React, { useState } from 'react';
// import './MediaEvents.css';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import MediaAndEvents from '../../components/MediaEvents/MediaAndEvents';
// import { MEDIA_EVENT_INFO, MEDIA_EVENT_SLIDER_IMAGES } from '../../utils/constants';
// import ProductInfoPlot from '../../components/ProductInfoPlot/ProductInfoPlot';

// const MediaEvents = () => {
//   const [isClickCeleb, setIsClickCeleb] = useState(false);
//   const [celebImg, setCelebImg] = useState([]);

//   const popup = {
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     variableWidth: true,
//     adaptiveHeight: true,
//   };
//   return (
//     <>
//       <div
//         className={isClickCeleb ? 'celebration-img-popup' : 'celeb-close'}
//         style={{ display: isClickCeleb ? 'block' : 'none' }}
//       >
//         <div
//           className="cancel-button"
//           onClick={() => {
//             setCelebImg([]);
//             setIsClickCeleb(false);

//             document.body.style.overflow = 'unset';
//           }}
//         >
//           <img className="cancel-popup" src={require('./images/cancel-popup.png')} alt="cancel popUp" />
//         </div>

//         <Slider className="popup-image" {...popup}>
//           {celebImg.map((item, index) => {
//             return (
//               <div className="slick-image-popup" key={index}>
//                 <img src={require(`../../components/MediaEvents/images/${item.img}`)} alt="DGV_image" />
//               </div>
//             );
//           })}
//         </Slider>
//       </div>

//       <div className="media-page-padding">
//         <ProductInfoPlot images={MEDIA_EVENT_SLIDER_IMAGES} info={MEDIA_EVENT_INFO} />
//       </div>

//       <div className="media-page-accordion">
//         <MediaAndEvents setIsClickCeleb={setIsClickCeleb} setCelebImg={setCelebImg} />
//       </div>
//     </>
//   );
// };

// export default MediaEvents;

import React, { useEffect, useState } from 'react';
import Carousel3D from '../../components/3DCarousel/Carousel3D';
import { mediaEventsSlider } from '../../utils/constants';
import MediaEventsDesk from './Desktop/MediaEventsDesk';
import MediaEventsMob from './Mobile/MediaEventsMob';
import CustomePopup from '../../components/CustomePopup/CustomePopup';

function MediaEvents() {
  const [desktopView, setDesktopView] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [activePopupData, setActivePopupData] = useState();

  const handleClickOpen = (link, popupData) => {
    setActiveLink(link);
    setActivePopupData(popupData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);
  // Image click handler to scroll dynamically
  const handleImageClick = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <div className="mediaEvent-main-container">
      <div className="partner-speak-title">
        <div className="partner-speaks-1">MEDIA &</div>
        <div className="partner-speaks-2">EVENTS</div>
      </div>
      <div className="slider-box">
        <Carousel3D
          sliderData={mediaEventsSlider}
          handleClickOpen={handleClickOpen}
          // backgroundColor={'rgb(255 255 255)'}
          handleImageClick={handleImageClick}
        />
      </div>
      <div className="watch-read-main-section">
        <div className="watch-more-title">Watch and Read</div>
        {desktopView ? <MediaEventsDesk /> : <MediaEventsMob />}
      </div>
      {open && (
        <CustomePopup
          link={activeLink}
          onClose={handleClose}
          popupData={activePopupData?.popupData}
          isVisible={open}
          IsVideo={true}
        />
      )}
    </div>
  );
}

export default MediaEvents;
