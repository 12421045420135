// import './LifeAtDGVInside.css';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { useState, useEffect } from 'react';
// import { Life_At_DGV_Data } from '../../utils/constants';

// const LifeAtDGVInside = () => {
//   const [isClickCeleb, setIsClickCeleb] = useState(false);
//   const [celebImg, setCelebImg] = useState([]);
//   const [indexValue, setIndexValue] = useState(0);

//   const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width:1024px)').matches);

//   useEffect(() => {
//     window.matchMedia('(min-width: 1024px)').addEventListener('change', (e) => setIsDesktop(e.matches));
//   }, []);

//   const popup = {
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     variableWidth: true,
//     adaptiveHeight: true,
//   };
//   const settings = {
//     dots: false,
//     infinite: true,
//     arrows: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     variableWidth: true,
//     adaptiveHeight: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           variableWidth: true,
//           adaptiveHeight: true,
//         },
//       },
//       {
//         breakpoint: 860,
//         settings: {
//           variableWidth: true,
//           adaptiveHeight: true,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           variableWidth: true,
//           adaptiveHeight: true,
//         },
//       },
//     ],
//   };

//   const popUpHandler = (allImg, index) => {
//     let startImg = [];
//     let allImgData = [];

//     for (let i = 0; i < allImg.length; i++) {
//       if (i < index + indexValue) {
//         startImg.push(allImg[i]);
//       } else {
//         allImgData.push(allImg[i]);
//       }
//     }
//     allImgData.push(...startImg);

//     setIsClickCeleb(true);
//     setCelebImg([...allImgData]);
//   };

//   return (
//     <div>
//       {/* -------------POPUP SLIDER------------ */}
//       <div
//         className={isClickCeleb ? 'celebration-img-popup' : 'celeb-close'}
//         style={{ display: isClickCeleb ? 'block' : 'none' }}
//       >
//         <div
//           className="cancel-button"
//           onClick={() => {
//             setCelebImg([]);
//             setIndexValue(1);
//             setIsClickCeleb(false);

//             document.body.style.overflow = 'unset';
//           }}
//         >
//           <img className="cancel-popup" src={require('./images/cancel-popup.png')} />
//         </div>

//         <Slider className="popup-image" {...popup}>
//           {celebImg.map((item, index) => {
//             return (
//               <div className="slick-image-popup" key={index}>
//                 <img src={require(`./images/${item.img}`)} alt="DGV_image" />
//               </div>
//             );
//           })}
//         </Slider>
//       </div>

//       {/* ---------------------------- */}

//       <div className="life-at-DGV-inside-section">
//         <div className="life-at-dgv-inside-wrapper">
//           <h1 className="life-at-dgv-inside-heading">Life at DGV</h1>
//         </div>

//         {Life_At_DGV_Data?.map((item, index) => {
//           return (
//             <div className="celebration-section" key={index} id={item.id}>
//               <div className="life-at-dgv-celebration-wrapper">
//                 <h2 className="celebration-section-title">{item.title}</h2>
//                 <div className="celebration-slider">
//                   <Slider {...settings}>
//                     {item.images.map((itemData, i) => {
//                       return (
//                         <div
//                           className="slick-image-carosal"
//                           key={i}
//                           onClick={() => {
//                             document.body.style.overflow = 'hidden';
//                             popUpHandler(item.images, i);
//                           }}
//                         >
//                           {isDesktop ? (
//                             <div className="lad-img-web">
//                               <img
//                                 className="life-at-dgv-inside-slick-image-web"
//                                 src={require(`./images/${itemData.img}`)}
//                                 alt="DGV_image"
//                               />
//                             </div>
//                           ) : (
//                             <div className="lad-img-mob">
//                               <img
//                                 className="life-at-dgv-inside-slick-image-mob"
//                                 src={require(`./images/${itemData.mobImg}`)}
//                                 alt="DGV_image"
//                               />
//                             </div>
//                           )}
//                         </div>
//                       );
//                     })}
//                   </Slider>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default LifeAtDGVInside;

import React, { useEffect, useState } from 'react';
import './LifeAtDGVInsideNew.css';
import dgv5thAnnivercery_1 from '../../assets/images/dgv5thAnnivercery-1.png';
import dgv5thAnnivercery_2 from '../../assets/images/dgv5thAnnivercery-2.png';
import dgv5thAnnivercery_3 from '../../assets/images/dgv5thAnnivercery-3.png';
import dgv5thAnnivercery_4 from '../../assets/images/dgv5thAnnivercery-4.png';
import dgv5thAnnivercery_5 from '../../assets/images/dgv5thAnnivercery-5.png';
import dgv5thAnnivercery_5new from '../../assets/images/fifthAnniversaaryCelebration-sliderImage.jpg';
import dassehra_1 from '../../assets/images/LifeAtDgv/dassehra-1.png';
import dassehra_2 from '../../assets/images/LifeAtDgv/dassehra-2.png';
import dassehra_3 from '../../assets/images/LifeAtDgv/dassehra-3.png';
import dassehra_4 from '../../assets/images/LifeAtDgv/dassehra-4.png';
import ganesh_1 from '../../assets/images/LifeAtDgv/Ganesh-1.png';
import ganesh_2 from '../../assets/images/LifeAtDgv/Ganesh-2.png';
import ganesh_3 from '../../assets/images/LifeAtDgv/Ganesh-3.png';
import ganesh_4 from '../../assets/images/LifeAtDgv/Ganesh-4.png';
import Office_1 from '../../assets/images/LifeAtDgv/Office-1.png';
import Office_2 from '../../assets/images/LifeAtDgv/Office-2.png';
import Office_3 from '../../assets/images/LifeAtDgv/Office-3.png';
import Office_4 from '../../assets/images/LifeAtDgv/Office-4.png';
import Celebration_1 from '../../assets/images/LifeAtDgv/Celebration_1.png';
import Celebration_2 from '../../assets/images/LifeAtDgv/Celebration_2.png';
import Celebration_3 from '../../assets/images/LifeAtDgv/Celebration_3.png';
import Celebration_4 from '../../assets/images/LifeAtDgv/Celebration_4.png';
import annualSale22_23_1 from '../../assets/images/LifeAtDgv/annual-sale-meet-22-23-1.png';
import annualSale22_23_2 from '../../assets/images/LifeAtDgv/annual-sale-meet-22-23-2.png';
import annualSale22_23_3 from '../../assets/images/LifeAtDgv/annual-sale-meet-22-23-3.png';
import holi22_1 from '../../assets/images/LifeAtDgv/holi22-1.png';
import holi22_2 from '../../assets/images/LifeAtDgv/holi22-2.png';
import holi22_3 from '../../assets/images/LifeAtDgv/holi22-3.png';
import holi24_1 from '../../assets/images/LifeAtDgv/holi2024-1.png';
import holi24_2 from '../../assets/images/LifeAtDgv/holi2024-2.png';
import holi24_3 from '../../assets/images/LifeAtDgv/holi2024-3.png';
import womensDay22_1 from '../../assets/images/LifeAtDgv/womensDay22-1.png';
import womensDay22_2 from '../../assets/images/LifeAtDgv/womensDay22-2.png';
import womensDay22_3 from '../../assets/images/LifeAtDgv/womensDay22-3.png';
import womensDay24_1 from '../../assets/images/LifeAtDgv/womensDay24-1.png';
import womensDay24_2 from '../../assets/images/LifeAtDgv/womensDay24-2.png';
import womensDay24_3 from '../../assets/images/LifeAtDgv/womensDay24-3.png';
import newjoinees_1 from '../../assets/images/LifeAtDgv/newJoinees-1.png';
import newjoinees_2 from '../../assets/images/LifeAtDgv/newJoinees-2.png';
import newjoinees_3 from '../../assets/images/LifeAtDgv/newJoinees-3.png';
import thirdAnniversary_1 from '../../assets/images/LifeAtDgv/thirdAnniversary-1.png';
import thirdAnniversary_2 from '../../assets/images/LifeAtDgv/thirdAnniversary-2.png';
import thirdAnniversary_3 from '../../assets/images/LifeAtDgv/thirdAnniversary-3.png';
import fouthAnniversory24_1 from '../../assets/images/LifeAtDgv/fouthAnniversory24-1.png';
import fouthAnniversory24_2 from '../../assets/images/LifeAtDgv/fouthAnniversory24-2.png';
import fouthAnniversory24_3 from '../../assets/images/LifeAtDgv/fouthAnniversory24-3.png';
import dellaAdventure_1 from '../../assets/images/LifeAtDgv/dellaAdventure_1.png';
import dellaAdventure_2 from '../../assets/images/LifeAtDgv/dellaAdventure_2.png';
import dellaAdventure_3 from '../../assets/images/LifeAtDgv/dellaAdventure_3.png';
import dellaAdventure_4 from '../../assets/images/LifeAtDgv/dellaAdventure_4.png';
import mansoonTreck_1 from '../../assets/images/LifeAtDgv/mansoonTreck-1.png';
import mansoonTreck_2 from '../../assets/images/LifeAtDgv/mansoonTreck-2.png';
import mansoonTreck_3 from '../../assets/images/LifeAtDgv/mansoonTreck-3.png';
import diwali2023_1 from '../../assets/images/LifeAtDgv/diwali2023-1.png';
import diwali2023_2 from '../../assets/images/LifeAtDgv/diwali2023-2.png';
import christmas_1 from '../../assets/images/LifeAtDgv/christmas-1.png';
import christmas_2 from '../../assets/images/LifeAtDgv/christmas-2.png';
import christmas_3 from '../../assets/images/LifeAtDgv/christmas-3.png';
import ganeshChaturthi_1 from '../../assets/images/LifeAtDgv/GaneshChaturthi-1.png';
import ganeshChaturthi_2 from '../../assets/images/LifeAtDgv/GaneshChaturthi-2.png';
import Component4x2 from './Component4x2';
import Component3x2 from './Component3x2';
import { LifeAtDgvSlider } from '../../utils/constants';
import Carousel3D from '../../components/3DCarousel/Carousel3D';
import { distributionMeet_24 } from '../MediaEvents/MediaeventsData';

function LifeAtDGVInside() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [desktopView, setDesktopView] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  const dassehraImages = [
    { imagePath: dassehra_1 },
    { imagePath: dassehra_2 },
    { imagePath: dassehra_3 },
    { imagePath: dassehra_4 },
  ];
  const dassehraPopupData = {
    imagesSlideArry: dassehraImages,
    heading: '',
    discription: 'Dussehra 2024 Fiesta and Celebration at the Corporate Office',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: ganesh_1, text: '' }],
  };
  const dgv5thAnnivercery = [
    { imagePath: dgv5thAnnivercery_1 },
    { imagePath: dgv5thAnnivercery_2 },
    { imagePath: dgv5thAnnivercery_3 },
    { imagePath: dgv5thAnnivercery_4 },
    // { imagePath: dgv5thAnnivercery_5, videoUrl: 'https://www.youtube.com/embed/xJNGXrry1tA?si=IDC1RBOjnfjyptBL' },
    { imagePath: dgv5thAnnivercery_5, videoUrl: require('../../assets/images/fifth-Anniversery-celebration.mp4') },
  ];
  const dgv5thAnniverceryPopupData = {
    imagesSlideArry: [
      { imagePath: dgv5thAnnivercery_1 },
      { imagePath: dgv5thAnnivercery_2 },
      { imagePath: dgv5thAnnivercery_3 },
      { imagePath: dgv5thAnnivercery_4 },
      // { imagePath: dgv5thAnnivercery_5, videoUrl: 'https://www.youtube.com/embed/xJNGXrry1tA?si=IDC1RBOjnfjyptBL' },
      {
        imagePath: dgv5thAnnivercery_5new,
        videoUrl: require('../../assets/images/fifth-Anniversery-celebration.mp4'),
        showPlayBtn: true,
      },
    ],
    heading: '',
    discription: 'Celebrating DGV’s 5th Anniversary and Offsite in Dapoli',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: dassehra_1, text: '' }],
  };
  const ganeshImages = [
    { imagePath: ganesh_1 },
    { imagePath: ganesh_2 },
    { imagePath: ganesh_3 },
    { imagePath: ganesh_4 },
  ];

  const ganeshPopupData = {
    imagesSlideArry: ganeshImages,
    heading: '',
    discription: 'Celebrating Ganesh Chaturthi 2024 at Corporate Office',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: dellaAdventure_1, text: '' }],
  };
  const dellaAdventure = {
    title: 'DELLA ADVENTURES',
    disc: 'Offsite team building activities in 2022',
    images: [
      { imagePath: dellaAdventure_1 },
      { imagePath: dellaAdventure_2 },
      { imagePath: dellaAdventure_3 },
      { imagePath: dellaAdventure_4 },
    ],
    bgColor: 'rgba(223, 255, 190, 0.6)',
  };
  const dellaAdventurePopupData = {
    imagesSlideArry: [
      { imagePath: dellaAdventure_1 },
      { imagePath: dellaAdventure_2 },
      { imagePath: dellaAdventure_3 },
      { imagePath: dellaAdventure_4 },
    ],
    heading: '',
    discription: 'DELLA ADVENTURES Offsite team building activities in 2022',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: holi24_1, text: '' }],
  };
  const holi2024 = {
    title: 'HOLI AT DGV',
    disc: 'DGV Team gets together to celebrate the festival of colours for Holi 2024',
    images: [{ imagePath: holi24_1 }, { imagePath: holi24_2 }, { imagePath: holi24_3 }],
    bgColor: 'rgba(223, 255, 190, 0.6)',
  };
  const holi2024PopupData = {
    imagesSlideArry: [{ imagePath: holi24_1 }, { imagePath: holi24_2 }, { imagePath: holi24_3 }],
    heading: '',
    discription: 'HOLI AT DGV DGV Team gets together to celebrate the festival of colours for Holi 2024',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: annualSale22_23_1, text: '' }],
  };
  const annualSale22_23 = {
    title: 'ANNUAL SALES MEET',
    disc: 'DGV Annual Sales Meet 2022-2023 Place: Chotila - Gujarat',
    images: [{ imagePath: annualSale22_23_1 }, { imagePath: annualSale22_23_2 }, { imagePath: annualSale22_23_3 }],
    bgColor: 'rgba(255, 235, 156, 0.6)',
  };
  const annualSale22_23PopupData = {
    imagesSlideArry: [
      { imagePath: annualSale22_23_1 },
      { imagePath: annualSale22_23_2 },
      { imagePath: annualSale22_23_3 },
    ],
    heading: '',
    discription: 'ANNUAL SALES MEET DGV Annual Sales Meet 2022-2023 Place: Chotila - Gujarat',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: holi22_1, text: '' }],
  };
  const holi22 = {
    title: 'HOLI 2022',
    disc: 'DGV Team gets together to celebrate the festival of colours for Holi 2022',
    images: [{ imagePath: holi22_1 }, { imagePath: holi22_2 }, { imagePath: holi22_3 }],
    bgColor: 'rgba(225, 244, 255, 0.6)',
  };

  const holi22PopupData = {
    imagesSlideArry: [{ imagePath: holi22_1 }, { imagePath: holi22_2 }, { imagePath: holi22_3 }],
    heading: '',
    discription: 'HOLI 2022 DGV Team gets together to celebrate the festival of colours for Holi 2022',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: womensDay22_1, text: '' }],
  };
  const womensDay22 = {
    title: 'WOMEN’S DAY 2022',
    disc: 'Getting together to celebrate Women’s Day 2022',
    images: [{ imagePath: womensDay22_1 }, { imagePath: womensDay22_2 }, { imagePath: womensDay22_3 }],
    bgColor: 'rgba(223, 255, 190, 0.6)',
  };
  const womensDay22PopupData = {
    imagesSlideArry: [{ imagePath: womensDay22_1 }, { imagePath: womensDay22_2 }, { imagePath: womensDay22_3 }],
    heading: '',
    discription: 'WOMEN’S DAY 2022 Getting together to celebrate Women’s Day 2022',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: christmas_2, text: '' }],
  };

  const christmas2023 = {
    title: 'CHRISTMAS 2023 ',
    disc: 'In-office celebrations and activities for the joyous occasion of Christmas 2023',
    images: [{ imagePath: christmas_1 }, { imagePath: christmas_2 }, { imagePath: christmas_3 }],
    bgColor: 'rgba(223, 255, 190, 0.6)',
  };
  const christmas2023PopupData = {
    imagesSlideArry: [{ imagePath: christmas_1 }, { imagePath: christmas_2 }, { imagePath: christmas_3 }],
    heading: '',
    discription: 'CHRISTMAS 2023 In-office celebrations and activities for the joyous occasion of Christmas 2023',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: diwali2023_1, text: '' }],
  };
  const diwali2023 = {
    title: 'DIWALI AT DGV',
    disc: 'DGV Team gets together to celebrate the festival of lights for Diwali 2023',
    images: [{ imagePath: diwali2023_1 }, { imagePath: diwali2023_2 }],
    bgColor: 'rgba(223, 255, 190, 0.6)',
    className: 'two-image-box',
  };
  const diwali2023PopupData = {
    imagesSlideArry: [{ imagePath: diwali2023_1 }, { imagePath: diwali2023_2 }],
    heading: '',
    discription: 'DGV Team gets together to celebrate the festival of lights for Diwali 2023',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: ganeshChaturthi_1, text: '' }],
  };
  const ganeshChaturthi = {
    title: 'GANESH CHATURTHI',
    disc: 'Getting together to celebrate Ganesh Chaturthi ',
    images: [{ imagePath: ganeshChaturthi_1 }, { imagePath: ganeshChaturthi_2 }],
    bgColor: 'rgba(223, 255, 190, 0.6)',
    className: 'two-image-box',
  };
  const ganeshChaturthiPopupData = {
    imagesSlideArry: [{ imagePath: ganeshChaturthi_1 }, { imagePath: ganeshChaturthi_2 }],
    heading: '',
    discription: 'GANESH CHATURTHI Getting together to celebrate Ganesh Chaturthi',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: Office_1, text: '' }],
  };
  const officeImages = [
    { imagePath: Office_1 },
    { imagePath: Office_2 },
    { imagePath: Office_3 },
    { imagePath: Office_4 },
  ];
  const officeImagesMob = {
    title: '',
    disc: 'Corporate Office Inauguration Navi Mumbai',
    images: officeImages,
    bgColor: 'rgba(223, 255, 190, 0.6)',
  };
  const officeImagesPopupData = {
    imagesSlideArry: officeImages,
    heading: '',
    discription: 'Corporate Office Inauguration Navi Mumbai',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: mansoonTreck_2, text: '' }],
  };

  const newJoineesImages = [{ imagePath: newjoinees_1 }, { imagePath: newjoinees_2 }, { imagePath: newjoinees_3 }];
  const newJoineesPopupData = {
    imagesSlideArry: newJoineesImages,
    heading: '',
    discription: 'Full Stack Training for software new joinees @LetsUpgrade',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: Office_2, text: '' }],
  };
  const mansoonTreckImages = [
    { imagePath: mansoonTreck_1 },
    { imagePath: mansoonTreck_2 },
    { imagePath: mansoonTreck_3 },
  ];
  const mansoonTreckPopupData = {
    imagesSlideArry: mansoonTreckImages,
    heading: '',
    discription: 'DGV’s Trek to Songadh in the Monsoon of 2023',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: ganeshChaturthi_1, text: '' }],
  };
  const thirdAnnivesaryImages = [
    { imagePath: thirdAnniversary_1 },
    { imagePath: thirdAnniversary_2 },
    { imagePath: thirdAnniversary_3 },
  ];
  const thirdAnnivesaryPopupData = {
    imagesSlideArry: thirdAnnivesaryImages,
    heading: '',
    discription: 'Celebrating DGV’s 3rd Anniversary at Navi Mumbai office',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: fouthAnniversory24_1, text: '' }],
  };
  const fouthAnniversoryImage = [
    { imagePath: fouthAnniversory24_1 },
    { imagePath: fouthAnniversory24_2 },
    { imagePath: fouthAnniversory24_3 },
  ];
  const fouthAnniversoryPopupData = {
    imagesSlideArry: fouthAnniversoryImage,
    heading: '',
    discription: 'Celebrating DGV’s 4th Anniversary and Offsite in Alibaug',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: womensDay24_2, text: '' }],
  };
  const womensDay24Images = [{ imagePath: womensDay24_1 }, { imagePath: womensDay24_2 }, { imagePath: womensDay24_3 }];
  const womensDay24PopupData = {
    imagesSlideArry: womensDay24Images,
    heading: '',
    discription: 'Women’s day 2024 lunch outing and in-office events',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: Celebration_2, text: '' }],
  };
  const CelebrationImages = [
    { imagePath: Celebration_1 },
    { imagePath: Celebration_2 },
    { imagePath: Celebration_3 },
    { imagePath: Celebration_4 },
  ];
  const CelebrationPopupData = {
    imagesSlideArry: CelebrationImages,
    heading: '',
    discription: '1st Anniversary celebration with the field staff',
    showBtn: false,
    otherText: 'From Other',
    otherInfoArry: [{ imageUrl: womensDay24_2, text: '' }],
  };

  // Image click handler to scroll dynamically
  const handleImageClick = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  return (
    <div className="dgv-life-main-container">
      {/* <button onClick={() => setPopupVisible(true)}>Show Popup</button> */}
      {/* <CustomePopup
        isVisible={isPopupVisible}
        onClose={() => setPopupVisible(false)}
        isSlider={true}
        popupData={activePopupData}
      /> */}
      <div className="dgv-life-section">
        <div className="dgv-gradient"></div>
        <div className="dgv-life-title">
          <div className="dgv-text-1">LIFE AT</div>
          <div className="dgv-text-2">DGV</div>
        </div>
        <Carousel3D
          sliderData={LifeAtDgvSlider}
          handleClickOpen={handleClickOpen}
          handleImageClick={handleImageClick}
        />
      </div>

      <div className="watch-more-section">
        <div className="watch-more-title">Watch More</div>
        {desktopView ? (
          <div className="watch-more-items">
            <Component4x2
              targetId={'section-2'}
              title={'Celebrating DGV’s 5th Anniversary and Offsite in Dapoli'}
              sliderImagesData={dgv5thAnnivercery}
              setPopupVisible={setPopupVisible}
              popupData={dgv5thAnniverceryPopupData}
            />
            <Component4x2
              targetId={'section-3'}
              sliderImagesData={dassehraImages}
              title={'Dussehra 2024 Fiesta and Celebration at the Corporate Office'}
              setPopupVisible={setPopupVisible}
              popupData={dassehraPopupData}
            />
            <Component4x2
              sliderImagesData={ganeshImages}
              title={'Celebrating Ganesh Chaturthi 2024 at Corporate Office'}
              setPopupVisible={setPopupVisible}
              popupData={ganeshPopupData}
            />
            <Component4x2
              targetId={'section-1'}
              sliderImagesData={distributionMeet_24?.silderImages}
              title={distributionMeet_24?.title}
              popupData={distributionMeet_24?.popupData}
            />
            {/* top third section */}
            <div className="fouth-last-section">
              <div className="fouth-right-box">
                <Component3x2 conponentData={holi2024} isFourImage={false} popupData={holi2024PopupData} />
              </div>
              <div className="fouth-left-box">
                <Component4x2
                  sliderImagesData={fouthAnniversoryImage}
                  title={'Celebrating DGV’s 4th Anniversary and Offsite in Alibaug'}
                  setPopupVisible={setPopupVisible}
                  isthirdSection={true}
                  popupData={fouthAnniversoryPopupData}
                />
                <Component4x2
                  sliderImagesData={womensDay24Images}
                  title={'Women’s day 2024 lunch outing and in-office events'}
                  setPopupVisible={setPopupVisible}
                  isthirdSection={true}
                  popupData={womensDay24PopupData}
                />
              </div>
            </div>

            {/* top fourth section - christmas 2023, diwali and ganesh chaturthi */}
            <div className="topFouth-section">
              <Component3x2 conponentData={christmas2023} istopFour={true} popupData={christmas2023PopupData} />
              <Component3x2
                conponentData={diwali2023}
                isTwoImage={true}
                istopFour={true}
                popupData={diwali2023PopupData}
              />
              <Component3x2
                conponentData={ganeshChaturthi}
                isTwoImage={true}
                istopFour={true}
                popupData={ganeshChaturthiPopupData}
              />
            </div>
            {/* treck section */}
            <Component4x2
              sliderImagesData={mansoonTreckImages}
              title={'DGV’s Trek to Songadh in the Monsoon of 2023'}
              setPopupVisible={setPopupVisible}
              popupData={mansoonTreckPopupData}
            />
            {/* fouth last section- della adventure, new joinees  and third Anniversary */}
            <div className="fouth-last-section">
              <div className="fouth-left-box">
                <Component4x2
                  data={{ titleWidth: '530px' }}
                  sliderImagesData={thirdAnnivesaryImages}
                  title={'Celebrating DGV’s 3rd Anniversary at Navi Mumbai office'}
                  setPopupVisible={setPopupVisible}
                  popupData={thirdAnnivesaryPopupData}
                />
                <Component4x2
                  data={{ titleWidth: '530px' }}
                  sliderImagesData={newJoineesImages}
                  title={'Full Stack Training for software new joinees @LetsUpgrade'}
                  setPopupVisible={setPopupVisible}
                  popupData={newJoineesPopupData}
                />
              </div>
              {desktopView && (
                <div className="fouth-right-box">
                  <Component3x2 conponentData={dellaAdventure} isFourImage={true} popupData={dellaAdventurePopupData} />
                </div>
              )}
            </div>
            {/* third last section - annual saes, holi 2022 and womens day 2022 */}
            {desktopView ? (
              <div className="third-last-section">
                <Component3x2 conponentData={annualSale22_23} popupData={annualSale22_23PopupData} />
                <Component3x2 conponentData={holi22} popupData={holi22PopupData} />
                <Component3x2 conponentData={womensDay22} popupData={womensDay22PopupData} />
              </div>
            ) : (
              <>
                <Component3x2 conponentData={annualSale22_23} popupData={annualSale22_23PopupData} />
                <div className="two-component-wrapper">
                  <Component3x2 conponentData={holi22} popupData={holi22PopupData} />
                  <Component3x2 conponentData={womensDay22} popupData={womensDay22PopupData} />
                </div>
              </>
            )}
            {/* corporate office Inauguration */}
            <Component4x2
              sliderImagesData={officeImages}
              title={'Corporate Office Inauguration Navi Mumbai'}
              setPopupVisible={setPopupVisible}
              popupData={officeImagesPopupData}
            />
            {/* 1st Anniversary */}
            <Component4x2
              sliderImagesData={CelebrationImages}
              title={'1st Anniversary celebration with the field staff'}
              setPopupVisible={setPopupVisible}
              popupData={CelebrationPopupData}
            />
          </div>
        ) : (
          <div className="watch-more-items">
            <Component4x2
              targetId={'section-2'}
              title={'Celebrating DGV’s 5th Anniversary and Offsite in Dapoli'}
              sliderImagesData={dgv5thAnnivercery}
              setPopupVisible={setPopupVisible}
              popupData={dgv5thAnniverceryPopupData}
            />
            <Component4x2
              targetId={'section-3'}
              sliderImagesData={dassehraImages}
              title={'Dussehra 2024 Fiesta and Celebration at the Corporate Office'}
              setPopupVisible={setPopupVisible}
              popupData={dassehraPopupData}
            />
            <Component4x2
              targetId={'section-1'}
              sliderImagesData={distributionMeet_24?.silderImages}
              title={distributionMeet_24?.title}
              popupData={distributionMeet_24?.popupData}
            />
            {desktopView ? (
              <Component4x2
                sliderImagesData={ganeshImages}
                title={'Celebrating Ganesh Chaturthi 2024 at Corporate Office'}
                setPopupVisible={setPopupVisible}
              />
            ) : (
              <div className="two-component-wrapper">
                <Component4x2
                  sliderImagesData={ganeshImages}
                  title={'Celebrating Ganesh Chaturthi 2024 at Corporate Office'}
                  setPopupVisible={setPopupVisible}
                  className={'widthHalf'}
                  slidesToScroll={1}
                  slidesToShow={1}
                  bgcolor={'#DFFFBE'}
                  hideExploreBtn={false}
                  popupData={ganeshPopupData}
                />
                <Component4x2
                  sliderImagesData={fouthAnniversoryImage}
                  title={'Celebrating DGV’s 4th Anniversary and Offsite in Alibaug'}
                  setPopupVisible={setPopupVisible}
                  isthirdSection={true}
                  className={'widthHalf'}
                  slidesToScroll={1}
                  slidesToShow={1}
                  bgcolor={'#FFEB9C'}
                  hideExploreBtn={false}
                  popupData={fouthAnniversoryPopupData}
                />
              </div>
            )}

            {/* top third section */}
            {desktopView ? (
              <div className="fouth-last-section">
                <div className="fouth-right-box">
                  <Component3x2 conponentData={holi2024} isFourImage={false} />
                </div>
                <div className="fouth-left-box">
                  <Component4x2
                    targetId={'section-2'}
                    p
                    sliderImagesData={fouthAnniversoryImage}
                    title={'Celebrating DGV’s 4th Anniversary and Offsite in Alibaug'}
                    setPopupVisible={setPopupVisible}
                    isthirdSection={true}
                  />
                  <Component4x2
                    sliderImagesData={womensDay24Images}
                    title={'Women’s day 2024 lunch outing and in-office events'}
                    setPopupVisible={setPopupVisible}
                    isthirdSection={true}
                    popupData={womensDay24PopupData}
                  />
                </div>
              </div>
            ) : (
              <>
                <Component4x2
                  sliderImagesData={womensDay24Images}
                  title={'Women’s day 2024 lunch outing and in-office events'}
                  setPopupVisible={setPopupVisible}
                  isthirdSection={true}
                  popupData={womensDay24PopupData}
                />
              </>
            )}

            {/* top fourth section - christmas 2023, diwali and ganesh chaturthi */}
            {desktopView ? (
              <div className="topFouth-section">
                <Component3x2 conponentData={christmas2023} istopFour={true} popupData={christmas2023PopupData} />
                <Component3x2
                  conponentData={diwali2023}
                  isTwoImage={true}
                  istopFour={true}
                  popupData={diwali2023PopupData}
                />
                <Component3x2
                  conponentData={ganeshChaturthi}
                  isTwoImage={true}
                  istopFour={true}
                  popupData={ganeshChaturthiPopupData}
                />
              </div>
            ) : (
              <>
                <div className="two-component-wrapper">
                  <Component3x2
                    conponentData={christmas2023}
                    istopFour={true}
                    bgcolor={'#E1F4FF'}
                    popupData={christmas2023PopupData}
                  />
                  <Component3x2
                    conponentData={diwali2023}
                    isTwoImage={false}
                    istopFour={true}
                    bgcolor={'#DFFFBE'}
                    popupData={diwali2023PopupData}
                  />
                </div>
                <Component3x2
                  conponentData={ganeshChaturthi}
                  isTwoImage={false}
                  istopFour={false}
                  hideExploreBtn={true}
                  className={'full-width'}
                  textAlignment={'center'}
                  popupData={ganeshChaturthiPopupData}
                />
              </>
            )}
            {/* treck section */}
            {desktopView ? (
              <Component4x2
                sliderImagesData={mansoonTreckImages}
                title={'DGV’s Trek to Songadh in the Monsoon of 2023'}
                setPopupVisible={setPopupVisible}
                popupData={mansoonTreckPopupData}
              />
            ) : (
              <div className="two-component-wrapper">
                <Component4x2
                  sliderImagesData={mansoonTreckImages}
                  title={'DGV’s Trek to Songadh in the Monsoon of 2023'}
                  setPopupVisible={setPopupVisible}
                  className={'widthHalf'}
                  slidesToScroll={1}
                  slidesToShow={1}
                  bgcolor={'#FFEB9C'}
                  popupData={mansoonTreckPopupData}
                />
                <Component4x2
                  sliderImagesData={thirdAnnivesaryImages}
                  title={'Celebrating DGV’s 3rd Anniversary at Navi Mumbai office'}
                  setPopupVisible={setPopupVisible}
                  className={'widthHalf'}
                  slidesToScroll={1}
                  slidesToShow={1}
                  bgcolor={'#E1F4FF'}
                  popupData={thirdAnnivesaryPopupData}
                />
              </div>
            )}

            {/* fouth last section- della adventure, new joinees  and third Anniversary */}
            {desktopView ? (
              <div className="fouth-last-section">
                <div className="fouth-left-box">
                  <Component4x2
                    sliderImagesData={thirdAnnivesaryImages}
                    title={'Celebrating DGV’s 3rd Anniversary at Navi Mumbai office'}
                    setPopupVisible={setPopupVisible}
                    popupData={thirdAnnivesaryPopupData}
                  />
                  <Component4x2
                    sliderImagesData={newJoineesImages}
                    title={'Full Stack Training for software new joinees @LetsUpgrade'}
                    setPopupVisible={setPopupVisible}
                    popupData={newJoineesPopupData}
                  />
                </div>
                <div className="fouth-right-box">
                  <Component3x2 conponentData={dellaAdventure} isFourImage={true} popupData={dellaAdventurePopupData} />
                </div>
              </div>
            ) : (
              <>
                <Component4x2
                  sliderImagesData={newJoineesImages}
                  title={'Full Stack Training for software new joinees @LetsUpgrade'}
                  setPopupVisible={setPopupVisible}
                  bgcolor={'#fff'}
                  popupData={newJoineesPopupData}
                />
                <div className="two-component-wrapper">
                  <Component3x2
                    conponentData={dellaAdventure}
                    isFourImage={desktopView ? true : false}
                    bgcolor={'#E1F4FF'}
                    popupData={dellaAdventurePopupData}
                  />
                  <Component3x2
                    conponentData={annualSale22_23}
                    bgcolor={'#FFEB9C'}
                    popupData={annualSale22_23PopupData}
                  />
                </div>
              </>
            )}
            {/* third last section - annual saes, holi 2022 and womens day 2022 */}
            {desktopView ? (
              <div className="third-last-section">
                <Component3x2 conponentData={annualSale22_23} popupData={annualSale22_23PopupData} />
                <Component3x2 conponentData={holi22} popupData={holi22PopupData} />
                <Component3x2 conponentData={womensDay22} popupData={womensDay22PopupData} />
              </div>
            ) : (
              <>
                <div className="two-component-wrapper">
                  <Component3x2
                    conponentData={holi22}
                    hideExploreBtn={true}
                    className={'full-width'}
                    textAlignment={'center'}
                    popupData={holi22PopupData}
                  />
                </div>
              </>
            )}
            {/* corporate office Inauguration */}
            <div className="two-block-container">
              <Component3x2 conponentData={womensDay22} bgcolor={'#E1F4FF'} popupData={womensDay22PopupData} />
              <Component3x2 conponentData={officeImagesMob} bgcolor={'#DFFFBE'} popupData={officeImagesPopupData} />
              {/* <Component4x2
                sliderImagesData={officeImages}
                title={'Corporate Office Inauguration Navi Mumbai'}
                setPopupVisible={setPopupVisible}
                slidesToShow={1}
                slidesToScroll={1}
                className={'mob-4x2-section'}
                bgcolor={'#DFFFBE'}
                popupData={officeImagesPopupData}
              /> */}
            </div>
            {/* 1st Anniversary */}
            <Component4x2
              sliderImagesData={CelebrationImages}
              title={'1st Anniversary celebration with the field staff'}
              setPopupVisible={setPopupVisible}
              popupData={CelebrationPopupData}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LifeAtDGVInside;
