import React, { useEffect, useState } from 'react';
import './DigitalBovineInsurance.css';
import DGV_Bovine_logo from '../../../../assets/images/Bovine-insurance-logo desk.svg';
import DGV_Bovine_Footprin_1 from '../../../../assets/images/bovine-footprint-1.png';
import DGV_Bovine_Footprin_2 from '../../../../assets/images/bovine-footprint-2.png';
import DGV_Bovine_Footprin_3 from '../../../../assets/images/bovine-footprint-3.png';
import DGV_bovine_Feature_1 from '../../../../assets/images/bovine-feature-1.png';
import DGV_bovine_Feature_2 from '../../../../assets/images/bovine-feature-2.png';
import DGV_bovine_Feature_3 from '../../../../assets/images/bovine-feature-3.png';
import DGV_bovine_Feature_4 from '../../../../assets/images/bovine-feature-4.png';
import DGV_bovine_Feature_5 from '../../../../assets/images/bovine-feature-5.png';
import DGV_bovine_Feature_text_1 from '../../../../assets/images/bovine-feature-text-1.svg';
import DGV_bovine_Feature_text_2 from '../../../../assets/images/bovine-feature-text-2.svg';
import DGV_bovine_Feature_text_3 from '../../../../assets/images/bovine-feature-text-3.svg';
import DGV_bovine_Feature_text_4 from '../../../../assets/images/bovine-feature-text-4.svg';
import DGV_bovine_Feature_text_5 from '../../../../assets/images/bovine-feature-text-5.svg';
import DGV_bovine_Feature_logo_1 from '../../../../assets/images/bovine-feature-logo-1.svg';
import DGV_bovine_Feature_logo_2 from '../../../../assets/images/bovine-feature-logo-2.svg';
import DGV_bovine_Feature_logo_3 from '../../../../assets/images/bovine-feature-logo-3.svg';
import DGV_bovine_Feature_logo_4 from '../../../../assets/images/bovine-feature-logo-4.svg';
import DGV_bovine_Feature_logo_5 from '../../../../assets/images/bovine-feature-logo-5.svg';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';

function DigitalBovineInsurance() {
  const [mobileView, setMobileView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.1, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  const eventSettings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: true,
    adaptiveHeight: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: 'linear',
    // initialSlide: 'slide',

    responsive: [
      {
        breakpoint: 580,
        settings: {
          adaptiveHeight: true,
          variableWidth: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="bovine-main-component">
      <div className="bovine-title-image-section">
        <div className="bovine-logo animate__animated animate__fadeIn">
          <img src={DGV_Bovine_logo} alt="bovine-logo" />
        </div>
        <div className="bovine-title animate__animated animate__fadeIn animate__delay-1s">First Digital footprint</div>
      </div>
      <div className="bovine-footprints-section">
        <div className="bovine-footprint-box animate__animated animate__fadeIn animate__delay-2s">
          <img src={DGV_Bovine_Footprin_1} alt="DGV_Bovine_Footprin_1" />
        </div>
        <div className="bovine-footprint-box animate__animated animate__fadeIn animate__delay-2s">
          <img src={DGV_Bovine_Footprin_2} alt="DGV_Bovine_Footprin_2" />
        </div>
        <div className="bovine-footprint-box animate__animated animate__fadeIn animate__delay-2s">
          <img src={DGV_Bovine_Footprin_3} alt="DGV_Bovine_Footprin_3" />
        </div>
      </div>
      <div ref={ref} className="bovine-feature-section">
        <div className="bovine-feature-text">Features</div>
        <div className="bovine-feature-wrapper">
          {mobileView ? (
            <>
              <div
                className={
                  inView
                    ? 'bovine-feature-info animate__animated animate__fadeInUp animate__delay-2s'
                    : 'bovine-feature-info'
                }
              >
                <img src={DGV_bovine_Feature_1} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_1} alt="DGV_bovine_Feature_text_1" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_1} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div
                className={
                  inView
                    ? 'bovine-feature-info animate__animated animate__fadeInUp animate__delay-1s'
                    : 'bovine-feature-info'
                }
              >
                <img src={DGV_bovine_Feature_2} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_2} alt="DGV_bovine_Feature_text_2" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_2} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div
                className={inView ? 'bovine-feature-info animate__animated animate__fadeInUp' : 'bovine-feature-info'}
              >
                <img src={DGV_bovine_Feature_3} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_3} alt="DGV_bovine_Feature_text_3" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_3} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div
                className={
                  inView
                    ? 'bovine-feature-info animate__animated animate__fadeInUp animate__delay-1s'
                    : 'bovine-feature-info'
                }
              >
                <img src={DGV_bovine_Feature_4} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_4} alt="DGV_bovine_Feature_text_3" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_4} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div
                className={
                  inView
                    ? 'bovine-feature-info animate__animated animate__fadeInUp animate__delay-2s'
                    : 'bovine-feature-info'
                }
              >
                <img src={DGV_bovine_Feature_5} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_5} alt="DGV_bovine_Feature_text_3" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_5} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
            </>
          ) : (
            <Slider {...eventSettings}>
              <div style={{ width: '239px' }} className="bovine-feature-info">
                <img src={DGV_bovine_Feature_1} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_1} alt="DGV_bovine_Feature_text_1" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_1} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div className="bovine-feature-info">
                <img src={DGV_bovine_Feature_2} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_2} alt="DGV_bovine_Feature_text_2" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_2} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div className="bovine-feature-info">
                <img src={DGV_bovine_Feature_3} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_3} alt="DGV_bovine_Feature_text_3" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_3} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div className="bovine-feature-info">
                <img src={DGV_bovine_Feature_4} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_4} alt="DGV_bovine_Feature_text_4" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_4} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
              <div className="bovine-feature-info">
                <img src={DGV_bovine_Feature_5} alt="DGV_bovine_Feature_2" />
                <div className="bovine-info-text">
                  <img src={DGV_bovine_Feature_text_5} alt="DGV_bovine_Feature_text_5" />
                </div>
                <div className="bovine-info-logo">
                  <img src={DGV_bovine_Feature_logo_5} alt="DGV_bovine_Feature_logo_1" />
                </div>
              </div>
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}

export default DigitalBovineInsurance;
