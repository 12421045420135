/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import Slider from 'react-slick';
import './CustomePopup.css'; // Include the styles below
import VideoButton from '../VideoButton/VideoButton';
import videoplayBTN from '../../assets/images/videoPlayBTN.png';

const CustomePopup = ({
  isVisible,
  link,
  onClose,
  popupData,
  IsVideo = false,
  showWatchBtn = true,
  showHeading = false,
}) => {
  const [playVideo, setPlayVideo] = useState(IsVideo);
  const [playVideoLink, setPlayVideoLink] = useState(link);
  if (!isVisible) return null;
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
        {'<'}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <div className="popup-main-content">
          {IsVideo || playVideo ? (
            // eslint-disable-next-line jsx-a11y/iframe-has-title
            <iframe
              // width="352"
              // height="198"
              src={playVideoLink}
              // title="Launch of DGV Platform at Sarhad Dairy (Kutch)"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <div className="popupImageSlider-box">
              <Slider {...sliderSettings}>
                {popupData?.imagesSlideArry?.map((image, i) => (
                  <>
                    <img
                      src={image?.imagePath}
                      alt="Event"
                      className="popup-image"
                      onClick={() => {
                        if (image?.videoUrl) {
                          setPlayVideo(true);
                          setPlayVideoLink(image?.videoUrl);
                        }
                      }}
                    />
                    {image?.videoUrl !== undefined && image?.showPlayBtn && (
                      <div className="playBTN" id={i}>
                        <img
                          src={videoplayBTN}
                          alt="Event"
                          className="popup-image"
                          onClick={() => {
                            if (image?.videoUrl) {
                              setPlayVideo(true);
                              setPlayVideoLink(image?.videoUrl);
                            }
                          }}
                        />
                      </div>
                    )}
                  </>
                ))}
              </Slider>
            </div>
          )}
          {popupData?.heading && (
            <div className="popup-header">
              <div className="popup-header-text">{popupData?.heading}</div>
              <div class="line-with-dot"></div>
            </div>
          )}
          <div className="popup-description">
            <p>
              {popupData?.discription}
              {/* Dr. R S Sodhi, Former MD of GCMMF (AMUL) & President of Indian Dairy Association, talks about DGV platform
              in providing dairy farmer’s access to institutional credit, last mile banking services with an objective
              to ease and digitalize the dairy value chain. */}
            </p>
          </div>
          {popupData?.popupData && (
            <div className="with-watch-now-btn">
              <VideoButton></VideoButton>
              {/* <VideoButton handleClickOpen={() => handleClickOpen(data?.link)}></VideoButton> */}
            </div>
          )}
        </div>
        {popupData?.otherInfoArry?.length > 0 && (
          <div className="popup-other-events">
            <div className="other-event-text">{popupData?.otherText}</div>
            <div className="other-event-wrapper">
              {/* {event?.otherEvents?.map((otherEvent, index) => (
              <div key={index} className="slider-item">
                <img src={otherEvent?.imgPath} alt={otherEvent?.title} className="slider-image" />
                <p>{otherEvent?.title}</p>
              </div>
            ))} */}
              {popupData?.otherInfoArry?.map((otherData, i) => (
                <div className="slider-item">
                  <img src={otherData?.imageUrl} alt={'image'} className="slider-image" />
                  <div className="slide-text">{otherData?.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomePopup;
