import React, { useEffect, useState } from 'react';
import ReusablePartnersComponent from './ReusablePartnersComponent';
import daily_society_mob_1 from '../../../assets/images/daily-society-mob-1.png';
import daily_society_mob_2 from '../../../assets/images/daily-society-mob-2.png';
import daily_society_desk_1 from '../../../assets/images/Dairy-society-desk-1.png';
import daily_society_desk_2 from '../../../assets/images/Dairy-society-desk-2.png';
import WithWatchBtnComponent from './WithWatchBtnComponent';
import './DairySocieties.css';

function DairySocieties() {
  const [desktopView, setDesktopView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  const reusableCompoentData = [
    {
      imageUrl: daily_society_mob_1,
      title: 'Pravin Dhirubhai Parmar, Secretary',
      dics: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease...',
      link: `https://www.youtube.com/embed/Dt14l02U3ZI`,
      bgcolor: '#DFFFBE',
      popupData: {
        link: `https://www.youtube.com/embed/Dt14l02U3ZI`,
        imagesSlideArry: [],
        heading: '',
        discription:
          'Pravin Dhirubhai Parmar, Secretary talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [{ imageUrl: '/static/media/dairySocieties-2.38c0dbeec91b78cb1e17.png', text: '' }],
      },
    },
    {
      imageUrl: daily_society_mob_2,
      title: 'Talaji Mafaji, Secretary',
      dics: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease...',
      link: `https://www.youtube.com/embed/LgLCZQg2Cn4`,
      bgcolor: '#FFEB9C',
      popupData: {
        link: `https://www.youtube.com/embed/LgLCZQg2Cn4`,
        imagesSlideArry: [],
        heading: '',
        discription:
          'Talaji Mafaji, Secretary talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.  ',
        showBtn: false,
        otherText: 'From Other',
        otherInfoArry: [{ imageUrl: '/static/media/dairySocieties-1.72a765184b8578b7b6e7.png', text: '' }],
      },
    },
  ];

  const withWatchBtnComponentData = {
    imageUrl: daily_society_desk_1,
    title: 'DAIRY',
    title_2: 'SOCIETIES',
    subTitle: 'Pravin Dhirubhai Parmar, Secretary',
    disc: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
    color: `rgba(223, 255, 190, 0.6)`,
    link: `https://www.youtube.com/embed/Dt14l02U3ZI`,
    width: '440px',
    popupData: {
      link: `https://www.youtube.com/embed/Dt14l02U3ZI`,
      imagesSlideArry: [],
      heading: '',
      discription:
        'Pravin Dhirubhai Parmar, Secretary talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [{ imageUrl: '/static/media/dairySocieties-2.38c0dbeec91b78cb1e17.png', text: '' }],
    },
  };
  const withWatchBtnComponentData2 = {
    imageUrl: daily_society_desk_2,
    title: 'DAIRY',
    title_2: 'SOCIETIES',
    subTitle: 'Talaji Mafaji, Secretary',
    disc: 'talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.',
    color: `#FFEB9C`,
    link: `https://www.youtube.com/embed/LgLCZQg2Cn4`,
    width: '440px',
    popupData: {
      link: `https://www.youtube.com/embed/LgLCZQg2Cn4`,
      imagesSlideArry: [],
      heading: '',
      discription:
        'Talaji Mafaji, Secretary talks about how DGV provides dairy farmer’s access to institutional credit, last mile banking services with an objective to ease and digitalize the dairy value chain.  ',
      showBtn: false,
      otherText: 'From Other',
      otherInfoArry: [{ imageUrl: '/static/media/dairySocieties-1.72a765184b8578b7b6e7.png', text: '' }],
    },
  };
  return desktopView ? (
    <div className="daily-societies-desk-wrappwer">
      <WithWatchBtnComponent data={withWatchBtnComponentData} />
      <WithWatchBtnComponent data={withWatchBtnComponentData2} />
    </div>
  ) : (
    <div className="daily-societies-mob-wrappwer">
      {reusableCompoentData?.map((data) => (
        <ReusablePartnersComponent data={data} />
      ))}
    </div>
  );
}

export default DairySocieties;
