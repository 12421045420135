import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './NewHomePageSlide3.css';
import DGVMoneyTitle from '../../assets/images/new-money-logo.png';
import MobileCheckBox from '../../assets/images/slide2-checkbox.png';
import DigitalDairyLoan from '../../assets/images/digital-dairy-loan.png';
import Disbusment from '../../assets/images/disbusment.png';
import LandApplication from '../../assets/images/Landing-Application.png';
import LoanForBovineEqu from '../../assets/images/Loan-for-bovine.png';
import PeopleImg from '../../assets/images/money-people.png';
import PeopleImgMob from '../../assets/images/money-people-mob.png';
// import MobileImg from '../../assets/images/monney-mobile.png';
// import MobileImg from '../../assets/images/monney-mobile-new.png';
import { Link } from 'react-router-dom';

const NewHomePageSlide3 = ({ HomePageSlide, animated, info, pause, play }) => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [mobileView, setMobileView] = useState(false);

  const { ref } = useInView();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    play();
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);

  return (
    <div className="banner-slide" id={`slide-${HomePageSlide.id}`}>
      {/* <div className="banner-title">
        <img src={DGVMoneyTitle} alt="logo" />
      </div> */}
      <div
        ref={ref}
        className={
          animated === HomePageSlide.id
            ? 'banner-title animate__animated animate__fadeInDown'
            : 'banner-title animate__animated animate__fadeOutLeft'
        }
      >
        <img src={DGVMoneyTitle} alt="logo" />
      </div>

      <div className="home-banner-img ">
        <img src={HomePageSlide.img} alt="banner" />
        {/* isMobile true showed desktop view */}
        {mobileView ? (
          <>
            <div className="slide-col-first">
              {/* <div className="slide-col-block">
                <img src={DigitalDairyLoan} alt="digital-dairy-loan" />
              </div> */}
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'slide-col-block animate__animated animate__fadeInLeft animate__delay-2s'
                    : 'slide-col-block animate__animated animate__fadeOutLeft'
                }
              >
                <img src={DigitalDairyLoan} alt="logo" />
              </div>
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'slide-col-block animate__animated animate__fadeInLeft animate__delay-4s'
                    : 'slide-col-block animate__animated animate__fadeOutLeft'
                }
              >
                <img src={Disbusment} alt="logo" />
              </div>

              {/* <div className="slide-col-block">
                <img src={Disbusment} alt="digital-dairy-loan" />
              </div> */}
            </div>
            <div className="slide-col-second">
              {/* <div className="slide-col-block">
                <img src={LandApplication} alt="digital-dairy-loan" />
              </div>
              <div className="slide-col-block">
                <img src={LoanForBovineEqu} alt="digital-dairy-loan" />
              </div> */}

              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'slide-col-block animate__animated animate__fadeInRight animate__delay-2s'
                    : 'slide-col-block animate__animated animate__fadeOutLeft'
                }
              >
                <img src={LandApplication} alt="logo" />
              </div>
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'slide-col-block animate__animated animate__fadeInRight animate__delay-4s'
                    : 'slide-col-block animate__animated animate__fadeOutLeft'
                }
              >
                <img src={LoanForBovineEqu} alt="logo" />
              </div>
            </div>
            <div className="mobile-inner-checkbox-container">
              {/* <div className="mobile-inner-checkbox">
                <img src={MobileCheckBox} alt="check" />
              </div> */}
              {/* <div className="checkbox-title">Loan Approved</div> */}
            </div>
            <div className="people-img">{/* <img src={PeopleImg} alt="people-img" /> */}</div>
            <Link
              className={
                animated === HomePageSlide.id
                  ? 'money-know-more animate__animated animate__fadeInUp '
                  : 'money-know-more animate__animated animate__fadeOutLeft'
              }
              to={HomePageSlide.path}
            >
              {' '}
              know more
            </Link>
            {/* <div
              // ref={ref}
              className={
                animated === HomePageSlide.id
                  ? 'people-img animate__animated animate__heartBeat animate__delay-3s'
                  : 'people-img '
                // : 'people-img animate__animated animate__fadeOutLeft'
              }
            >
              <img src={PeopleImg} alt="logo" />
            </div> */}
          </>
        ) : (
          // <div className="money-mobile-ui">
          <>
            {/* <div className="monney-mobile-img">
              <img src={'MobileImg'} alt="mobile-icon" />
            </div> */}
            <div className="money-options-wrapper">
              {/* <div className="Money-options">
                <img src={DigitalDairyLoan} alt="digital-dairy-loan" />
              </div> */}
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'Money-options animate__animated animate__fadeInLeft animate__delay-2s'
                    : 'Money-options animate__animated animate__fadeOutLeft'
                }
              >
                <img src={DigitalDairyLoan} alt="logo" />
              </div>

              {/* <div className="Money-options">
                <img src={Disbusment} alt="digital-dairy-loan" />
              </div> */}
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'Money-options animate__animated animate__fadeInRight animate__delay-2s'
                    : 'Money-options animate__animated animate__fadeOutLeft'
                }
              >
                <img src={Disbusment} alt="logo" />
              </div>
              {/* <div className="Money-options">
                <img src={LandApplication} alt="digital-dairy-loan" />
              </div> */}
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'Money-options animate__animated animate__fadeInLeft animate__delay-3s'
                    : 'Money-options animate__animated animate__fadeOutLeft'
                }
              >
                <img src={LandApplication} alt="logo" />
              </div>
              <div
                // ref={ref}
                className={
                  animated === HomePageSlide.id
                    ? 'Money-options animate__animated animate__fadeInRight animate__delay-3s'
                    : 'Money-options animate__animated animate__fadeOutLeft'
                }
              >
                <img src={LoanForBovineEqu} alt="logo" />
              </div>
              {/* <div className="Money-options">
                <img src={LoanForBovineEqu} alt="digital-dairy-loan" />
              </div> */}
            </div>
            {/* <div className="people-img">
              <img src={PeopleImgMob} alt="people-img" />
            </div>
            <div className="mobile-inner-checkbox-container">
              <div className="mobile-inner-checkbox">
                <img src={MobileCheckBox} alt="check" />
              </div>
              <div className="checkbox-title">Loan Approved</div>
            </div> */}
            <Link
              className={
                animated === HomePageSlide.id
                  ? 'money-know-more animate__animated animate__fadeInUp '
                  : 'money-know-more animate__animated animate__fadeOutLeft'
              }
              to={HomePageSlide.path}
            >
              {' '}
              know more
            </Link>
          </>
          // </div>
        )}
      </div>

      {/* <div  
        // ref={ref}
        // className={
        //   animated === HomePageSlide.id
        //     ? 'animate__animated animate__fadeInDown'
        //     : 'animate__animated animate__fadeOutLeft'
        // }
        style={{ width: '300px' }}
      >
        {HomePageSlide.title}
        <img src={DGVConnectTitle} alt="DGV Connect" />
      </div> */}

      {/* <div className="banner-desc-slide3">
        <div
          ref={ref}
          className={
            animated === HomePageSlide.id
              ? 'bottom-mr banner-title animate__animated animate__fadeInDown'
              : 'bottom-mr  banner-title animate__animated animate__fadeOutLeft'
          }
        >
          {HomePageSlide.title}
        </div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'sub-title mob-title animate__animated animate__fadeInUp animate__delay-2s'
              : 'sub-title mob-title animate__animated animate__fadeOutLeft'
          }
          dangerouslySetInnerHTML={{ __html: HomePageSlide.description }}
        ></div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'know-more-slide3 animate__animated animate__fadeInUp animate__delay-3s'
              : 'know-more-slide3 animate__animated animate__fadeOutLeft'
          }
        >
          {' '}
        </div>
        <div
          className={
            animated === HomePageSlide.id
              ? 'u-tube-btn animate__animated animate__fadeInUp animate__delay-4s'
              : 'u-tube-btn animate__animated animate__fadeOutLeft'
          }
          onClick={pause}
        >
          <VideoButton handleClickOpen={() => handleClickOpen(HomePageSlide.link)}></VideoButton>
        </div>
        {open && <DialogVideo title={'partner'} link={activeLink} handleClose={handleClose} />}
      </div> */}
    </div>
  );
};

export default NewHomePageSlide3;
