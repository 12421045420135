import React, { useEffect, useState } from 'react';
import './ForMoreDetails.css';
import mailIcon from '../../assets/images/email-Id-icon.png';
// import forMoreDetailLine from '../../assets/images/forMoreDetailBottomLine.png';
import forMoreDetailLine from '../../assets/images/for-more-details-top-line.svg';
import forMoreDetailBottomLineMob from '../../assets/images/for-more-details-Bottom-line-mob.svg';

function ForMoreDetails({ mailId }) {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <div className="forMoreDetail-container">
      <div className="forMoreDetail-heading">For More Details,</div>
      <div className="for-more-detail-top-line">
        <img src={mobileView ? forMoreDetailLine : forMoreDetailBottomLineMob} alt="top-line"></img>
      </div>
      <div className="details">
        <div className="mail-icon-box">
          <div className="email-icon">
            <img src={mailIcon} alt="mail" />
          </div>
          <div className="mail-id">EMAIL ID</div>
        </div>
        <div className="mail-info">
          {/* <a href="#">{mailId}</a> */}
          <a href={`mailto:${mailId}`}>{mailId}</a>
        </div>
        <div className="for-more-info-bottom-line">
          <img src={mobileView ? forMoreDetailLine : forMoreDetailBottomLineMob} alt="line"></img>
        </div>
      </div>
    </div>
  );
}

export default ForMoreDetails;
