import React, { useEffect, useState } from 'react';
import { ABOUT_US_SLIDER_IMAGES, WHO_WE_ARE } from '../../../utils/constants';
import MeetOurCEO from '../../../components/MeetOurCEO/MeetOurCEO';
import InvestorPartner from '../../../components/InvestorPartner/InvestorPartner';
import CoreManagementTeam from '../../../components/CoreManagementTeam/CoreManagementTeam';
import BoardOfDirector from '../../../components/BoardOfDirector/BoardOfDirector';
import ProductInfoPlot from '../../../components/ProductInfoPlot/ProductInfoPlot';
import './AboutUs.css';
import desktopSepuratioLine from '../../../assets/images/Who we are images/desktop-sepuration-line.svg';
import mobileSepuratioLine from '../../../assets/images/Who we are images/mobile-separation-line.svg';

import certificate_1 from '../../../assets/images/Who we are images/certificate-1.png';
import certificate_2 from '../../../assets/images/Who we are images/certificate-2.png';
import certificate_3 from '../../../assets/images/Who we are images/certificate-3.png';

import Our_vision_logo from '../../../assets/images/Who we are images/Our-vision-logo.png';
import Our_mission_logo from '../../../assets/images/Who we are images/Our-mission-logo.png';

import vision_mission_middle_line from '../../../assets/images/Who we are images/vision-mission-middle-line.png';
import { useInView } from 'react-intersection-observer';

const AboutUs = () => {
  const [desktopView, setDesktopView] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);
  return (
    <div className="aboutUs-main-container">
      <div className="AboutUs_page-padding" id="who-we-are">
        <ProductInfoPlot info={WHO_WE_ARE} images={ABOUT_US_SLIDER_IMAGES} />
      </div>
      <div className="sepuration-line" style={{ marginTop: '20px' }}>
        <img src={desktopView ? desktopSepuratioLine : mobileSepuratioLine} alt="line" />
      </div>
      <div className="meet-our-CEO-section" id="ceo-desk">
        <MeetOurCEO />
      </div>
      <div className="sepuration-line">
        <img src={desktopView ? desktopSepuratioLine : mobileSepuratioLine} alt="line" />
      </div>

      {/* <div className="vision-mission-section" id="vision-mission">
        <VisionMission />
      </div> */}

      <div className="investor-partner" id="investor">
        <InvestorPartner />
      </div>
      <div className="sepuration-line">
        <img src={desktopView ? desktopSepuratioLine : mobileSepuratioLine} alt="line" />
      </div>

      <div className="Board-of-director" id="board-of-director">
        <BoardOfDirector />
      </div>

      <div className="sepuration-line">
        <img src={desktopView ? desktopSepuratioLine : mobileSepuratioLine} alt="line" />
      </div>

      <div className="core-management-team" id="management-team">
        <CoreManagementTeam />
      </div>
      <div className="sepuration-line">
        <img src={desktopView ? desktopSepuratioLine : mobileSepuratioLine} alt="line" />
      </div>
      <div className="certificates-container" ref={ref}>
        <div className="certificate-text">Certifications</div>
        {desktopView ? (
          <div className="certificate-images-box">
            <div className={inView ? 'certicate-box animate__animated animate__fadeInLeft' : 'certicate-box'}>
              <img src={certificate_1} alt="certificate_1" />
            </div>
            <div
              className={
                inView ? 'certicate-box animate__animated animate__bounceInUp animate__delay-2s' : 'certicate-box'
              }
            >
              <img src={certificate_2} alt="certificate_2" />
            </div>
            <div className={inView ? 'certicate-box animate__animated animate__fadeInRight ' : 'certicate-box'}>
              <img src={certificate_3} alt="certificate_3" />
            </div>
          </div>
        ) : (
          <div className="certificate-images-box">
            <div className={inView ? 'certicate-box animate__animated animate__bounceInLeft ' : 'certicate-box'}>
              <img src={certificate_1} alt="certificate_1" />
            </div>
            <div className={inView ? 'certicate-box animate__animated animate__bounceInRight ' : 'certicate-box'}>
              <img src={certificate_3} alt="certificate_3" />
            </div>
            <div
              className={
                inView ? 'certicate-box animate__animated animate__bounceInUp animate__delay-2s' : 'certicate-box'
              }
            >
              <img src={certificate_2} alt="certificate_2" />
            </div>
          </div>
        )}
      </div>
      <div className="sepuration-line">
        <img src={desktopView ? desktopSepuratioLine : mobileSepuratioLine} alt="line" />
      </div>

      <div className="vision-mission">
        <div className="vision-mission-wrapper">
          <div className="vision-section">
            <div className="visio-logo">
              <img src={Our_vision_logo} alt="" />
            </div>
            <div className="visio-desc">
              Bringing world-class financial services, Insurance Technology & integrated marketplace at the doorstep of
              every Indian Dairy Farmer & Dairy Eco System.
            </div>
          </div>
          {desktopView && (
            <div className="middle-line-section">
              <img src={vision_mission_middle_line} alt="vision_mission_middle_line" />
            </div>
          )}
          <div className="mission-section">
            <div className="mission-logo">
              <img src={Our_mission_logo} alt="" />
            </div>
            <div className="mission-desc">
              We aim to play an active role in multiplying the dairy farmers income and support them with bespoke
              Banking, Financial Service and Insurance products who contributes 25% of the agri GDP of our country.
            </div>
          </div>
        </div>
      </div>

      {/* <div className="life-at-dgv" id="life-at-dgv">
        <LifeAtDGV />
      </div> */}
    </div>
  );
};

export default AboutUs;
