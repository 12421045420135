import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './NewHomePageSlide4.css';
import DGVConnectTitle from '../../assets/images/new-connect-logo.png';
import ConnectOprtion1 from '../../assets/images/connect-option-1.png';
import ConnectOprtion2 from '../../assets/images/connect-option-2.png';
import ConnectOprtion3 from '../../assets/images/connect-option-3.png';
import ConnectOprtion4 from '../../assets/images/connect-option-4.png';
import { Link } from 'react-router-dom';
import ButtonWithIcon from './ButtonWithIcon';

const NewHomePageSlide4 = ({ HomePageSlide, animated, info, pause, play }) => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [mobileView, setMobileView] = useState(false);

  const { ref } = useInView();

  const handleClickOpen = (link) => {
    setActiveLink(link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    play();
  };

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);

  return (
    <div className="banner-slide" id={`slide-${HomePageSlide.id}`}>
      {/* <div className="banner-title">
        <img src={DGVConnectTitle} alt="logo" />
      </div> */}
      <div
        ref={ref}
        className={
          animated === HomePageSlide.id
            ? 'banner-title animate__animated animate__fadeInDown'
            : 'banner-title animate__animated animate__fadeOutLeft'
        }
      >
        <img src={DGVConnectTitle} alt="logo" />
      </div>

      <div className="home-banner-img ">
        <img src={HomePageSlide.img} alt="banner" />
        <div className="connect-option-wrpper">
          {/* <div className="connect-option">
            <img src={ConnectOprtion1} alt="connect-rectangle" />
          </div> */}
          <div
            // ref={ref}
            className={
              animated === HomePageSlide.id
                ? 'connect-option animate__animated animate__fadeInLeft animate__delay-2s'
                : 'connect-option animate__animated animate__fadeOutLeft'
            }
          >
            {mobileView ? (
              <div style={{ paddingTop: '30px' }}>
                <ButtonWithIcon name="Explore Premium Bovine" />
              </div>
            ) : (
              <img src={ConnectOprtion1} alt="logo" />
            )}
          </div>
          <div
            // ref={ref}
            className={
              animated === HomePageSlide.id
                ? 'connect-option animate__animated animate__fadeInLeft animate__delay-3s'
                : 'connect-option animate__animated animate__fadeOutLeft'
            }
          >
            {mobileView ? (
              <div style={{ paddingTop: '30px' }}>
                <ButtonWithIcon name="purchase dairy equipments" />
              </div>
            ) : (
              <img src={ConnectOprtion2} alt="logo" />
            )}
          </div>
          <div
            // ref={ref}
            className={
              animated === HomePageSlide.id
                ? 'connect-option animate__animated animate__fadeInLeft animate__delay-4s'
                : 'connect-option animate__animated animate__fadeOutLeft'
            }
          >
            {mobileView ? (
              <div style={{ paddingTop: '30px' }}>
                <ButtonWithIcon name="digital health certificate" />
              </div>
            ) : (
              <img src={ConnectOprtion3} alt="logo" />
            )}
          </div>
          <div
            // ref={ref}
            className={
              animated === HomePageSlide.id
                ? 'connect-option animate__animated animate__fadeInLeft animate__delay-5s'
                : 'connect-option animate__animated animate__fadeOutLeft'
            }
          >
            {mobileView ? (
              <div style={{ paddingTop: '30px' }}>
                <ButtonWithIcon name="embedded financing" />
              </div>
            ) : (
              <img src={ConnectOprtion4} alt="logo" />
            )}
          </div>
          {/* <div className="connect-option">
            <img src={ConnectOprtion2} alt="connect-rectangle" />
          </div>
          <div className="connect-option">
            <img src={ConnectOprtion3} alt="connect-rectangle" />
          </div>
          <div className="connect-option">
            <img src={ConnectOprtion4} alt="connect-rectangle" />
          </div> */}
        </div>
        <Link
          className={
            animated === HomePageSlide.id
              ? 'money-know-more animate__animated animate__fadeInUp '
              : 'money-know-more animate__animated animate__fadeOutLeft'
          }
          to={HomePageSlide.path}
        >
          {' '}
          know more
        </Link>
      </div>

      {/* <div  
        // ref={ref}
        // className={
        //   animated === HomePageSlide.id
        //     ? 'animate__animated animate__fadeInDown'
        //     : 'animate__animated animate__fadeOutLeft'
        // }
        style={{ width: '300px' }}
      >
        {HomePageSlide.title}
        <img src={DGVConnectTitle} alt="DGV Connect" />
      </div> */}

      {/* <div className="banner-desc-slide3">
        <div
          ref={ref}
          className={
            animated === HomePageSlide.id
              ? 'bottom-mr banner-title animate__animated animate__fadeInDown'
              : 'bottom-mr  banner-title animate__animated animate__fadeOutLeft'
          }
        >
          {HomePageSlide.title}
        </div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'sub-title mob-title animate__animated animate__fadeInUp animate__delay-2s'
              : 'sub-title mob-title animate__animated animate__fadeOutLeft'
          }
          dangerouslySetInnerHTML={{ __html: HomePageSlide.description }}
        ></div>

        <div
          className={
            animated === HomePageSlide.id
              ? 'know-more-slide3 animate__animated animate__fadeInUp animate__delay-3s'
              : 'know-more-slide3 animate__animated animate__fadeOutLeft'
          }
        >
          {' '}
        </div>
        <div
          className={
            animated === HomePageSlide.id
              ? 'u-tube-btn animate__animated animate__fadeInUp animate__delay-4s'
              : 'u-tube-btn animate__animated animate__fadeOutLeft'
          }
          onClick={pause}
        >
          <VideoButton handleClickOpen={() => handleClickOpen(HomePageSlide.link)}></VideoButton>
        </div>
        {open && <DialogVideo title={'partner'} link={activeLink} handleClose={handleClose} />}
      </div> */}
    </div>
  );
};

export default NewHomePageSlide4;
