// import React, { useEffect, useState } from 'react';
// import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
// import Header from '../../../components/Header';
// import './DGVSaas.css';
// import DGVSaasImage from '../../../assets/images/DGV-SaaS-image.png';
// import DGVSaasOpenImage from '../../../assets/images/saas-mob-open-btn.png';
// import DGVSaasCloseImage from '../../../assets/images/saas-mob-close-btn.png';
// import DGVMoneyLos from './DGVMoneyLos/DGVMoneyLos';
// import DGVQualify from './DGVQualify/DGVQualify';
// import DGVDairyBRE from './DGVDairyBRE/DGVDairyBRE';
// import DigitalBovineInsurance from './DigitalBovineInsurance/DigitalBovineInsurance';
// import ForMoreDetails from '../../../components/ForMoreDetails/ForMoreDetails';
// function DGVSaas() {
//   const [active, setActive] = useState('quality'); // Initialize active state with a default value
//   const [desktopView, setDesktopView] = useState(false);

//   const handleClick = (key) => {
//     setActive(key); // Update the active state with the clicked button's key
//   };

//   useEffect(() => {
//     if (window.innerWidth >= 1112) {
//       setDesktopView(true);
//     }
//   }, [desktopView]);
//   return (
//     <div className="main-div desktop-view ">
//       <Header />
//       <BreadCrumb />
//       <div className="saas-main-container page-container page-content">
//         <div className="saas-info-section">
//           <div className="saas-title-box">
//             <div className="saas-heading-title">Digital Lending Suite For Dairy Eco System</div>
//             <div className="saas-heading-sub-title">
//               Experience a fresh way of lending in the Dairy Eco System with our Ready-to-use SaaS solution. Our
//               Cloud-based Loan Origination System cuts out paperwork, making loan extensions quicker than ever. Smart
//               lending decisions are made with our unique Dairy Business Rules Engine (BRE), and get added protection
//               through seamless integration with Digital Bovine Insurance.
//             </div>
//           </div>
//           {/* <div className="saas-image-box">
//             <img src={DGVSaasImage} alt="saas-image" />
//           </div> */}
//           <div className={`saas-image-box animate__animated animate__flip`}>
//             <img src={DGVSaasImage} alt="DGV Pay Graphic" />
//           </div>
//         </div>
//         {desktopView ? (
//           <div className="saas-multiple-page-section">
//             <div className="saas-option-bar">
//               <div
//                 className={`saas-dgv-navigation-btn ${active === 'quality' ? 'active' : ''}`}
//                 onClick={() => handleClick('quality')}
//               >
//                 DGV Qualify
//               </div>
//               <div
//                 className={`saas-dgv-navigation-btn ${active === 'money' ? 'active' : ''}`}
//                 onClick={() => handleClick('money')}
//               >
//                 DGV Money LOS
//               </div>
//               <div
//                 className={`saas-dgv-navigation-btn ${active === 'dairy' ? 'active' : ''}`}
//                 onClick={() => handleClick('dairy')}
//               >
//                 DGV Dairy BRE
//               </div>
//               <div
//                 className={`saas-dgv-navigation-btn ${active === 'bovine' ? 'active' : ''}`}
//                 onClick={() => handleClick('bovine')}
//               >
//                 Digital Bovine Insurance
//               </div>
//             </div>
//             <div className="saas-content">
//               {/* Render dynamic content based on active state */}
//               {active === 'quality' && <DGVQualify />}
//               {active === 'money' && <DGVMoneyLos />}
//               {active === 'dairy' && <DGVDairyBRE />}
//               {active === 'bovine' && <DigitalBovineInsurance />}
//             </div>
//           </div>
//         ) : (
//           <div className="saas-mob-multiple-page-section">
//             {[
//               { key: 'quality', name: 'DGV Qualify' },
//               { key: 'money', name: 'DGV Money LOS' },
//               { key: 'dairy', name: 'DGV Dairy BRE' },
//               { key: 'bovine', name: 'Digital Bovine Insurance' },
//             ].map(({ key, name }) => (
//               <div key={key} className="saas-mob-nav">
//                 <div className="saas-mob-nav-btn" onClick={() => handleClick(key)}>
//                   <div className="saas-mob-btn-name">{name}</div>
//                   <div className="saas-mob-btn-img">
//                     <img src={active === key ? DGVSaasOpenImage : DGVSaasCloseImage} alt="btn" />
//                   </div>
//                 </div>
//                 {active === key && (
//                   <div className="saas-content">
//                     {key === 'quality' && <DGVQualify />}
//                     {key === 'money' && <DGVMoneyLos />}
//                     {key === 'dairy' && <DGVDairyBRE />}
//                     {key === 'bovine' && <DigitalBovineInsurance />}
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//         )}
//         {/* {!desktopView && (
//           <div className="saas-mob-multiple-page-section">
//             <div className="saas-mob-nav-btn" onClick={() => handleClick('quality')}>
//               <div className="saas-mob-btn-name">DGV Qualify</div>
//               <div className="saas-mob-btn-img">
//                 <img src={active === 'quality' ? DGVSaasOpenImage : DGVSaasCloseImage} alt="btn" />
//               </div>
//             </div>
//             <div className="saas-mob-nav-btn" onClick={() => handleClick('money')}>
//               <div className="saas-mob-btn-name">DGV Money LOS</div>
//               <div className="saas-mob-btn-img">
//                 <img src={active === 'money' ? DGVSaasOpenImage : DGVSaasCloseImage} alt="btn" />
//               </div>
//             </div>
//             <div className="saas-mob-nav-btn" onClick={() => handleClick('dairy')}>
//               <div className="saas-mob-btn-name">DGV Dairy BRE</div>
//               <div className="saas-mob-btn-img">
//                 <img src={active === 'dairy' ? DGVSaasOpenImage : DGVSaasCloseImage} alt="btn" />
//               </div>
//             </div>
//             <div className="saas-mob-nav-btn" onClick={() => handleClick('bovine')}>
//               <div className="saas-mob-btn-name">Digital Bovine Insurance</div>
//               <div className="saas-mob-btn-img">
//                 <img src={active === 'bovine' ? DGVSaasOpenImage : DGVSaasCloseImage} alt="btn" />
//               </div>
//             </div>
//           </div>
//         )} */}
//       </div>
//       <ForMoreDetails mailId={'saas@dgv.in'} />
//     </div>
//   );
// }

// export default DGVSaas;

import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Header from '../../../components/Header';
import './DGVSaas.css';
import DGVSaasImage from '../../../assets/images/DGV-SaaS-image.png';
import DGVSaasOpenImage from '../../../assets/images/saas-mob-open-btn.png';
import DGVSaasCloseImage from '../../../assets/images/saas-mob-close-btn.png';
import DGVMoneyLos from './DGVMoneyLos/DGVMoneyLos';
import DGVQualify from './DGVQualify/DGVQualify';
import DGVDairyBRE from './DGVDairyBRE/DGVDairyBRE';
import DigitalBovineInsurance from './DigitalBovineInsurance/DigitalBovineInsurance';
import ForMoreDetails from '../../../components/ForMoreDetails/ForMoreDetails';
import Footer from '../../../components/Footer';

const DGVSaas = () => {
  const [active, setActive] = useState('quality');
  const [isDesktop, setIsDesktop] = useState(false);

  const saasOptions = [
    { key: 'quality', label: 'DGV Qualify', component: <DGVQualify /> },
    { key: 'money', label: 'DGV Money LOS', component: <DGVMoneyLos /> },
    { key: 'dairy', label: 'DGV Dairy BRE', component: <DGVDairyBRE /> },
    { key: 'bovine', label: 'Digital Bovine Insurance', component: <DigitalBovineInsurance /> },
  ];

  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 1112);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="main-div desktop-view">
      <Header />
      <BreadCrumb />
      <div className="saas-main-container page-container page-content">
        <div className="saas-info-section">
          <div className="saas-title-box">
            <div className="saas-heading-title">Digital Lending Suite For Dairy Ecosystem</div>
            <div className="saas-heading-sub-title">
              Experience a fresh way of lending in the Dairy Ecosystem with our Ready-to-use SaaS solution. Our
              Cloud-based Loan Origination System cuts out paperwork, making loan extensions quicker than ever. Smart
              lending decisions are made with our unique Dairy Business Rules Engine (BRE), and get added protection
              through seamless integration with Digital Bovine Insurance.
            </div>
          </div>
          {/* <div className="saas-image-box animate__animated animate__flip" style={{ animationDuration: '0.2s' }}> */}
          <div className="saas-image-box animate__animated animate__rotateIn animate__delay-1s">
            <img src={DGVSaasImage} alt="DGV Pay Graphic" />
          </div>
        </div>

        <div className={`saas-multiple-page-section ${isDesktop ? 'desktop' : 'mobile'}`}>
          {isDesktop ? (
            <>
              <div className="saas-option-bar">
                {saasOptions.map(({ key, label }) => (
                  <div
                    key={key}
                    className={`saas-dgv-navigation-btn ${active === key ? 'active' : ''}`}
                    onClick={() => setActive(key)}
                  >
                    {label}
                  </div>
                ))}
              </div>
              <div className="saas-content">{saasOptions.find(({ key }) => key === active)?.component}</div>
            </>
          ) : (
            saasOptions.map(({ key, label, component }) => (
              <div key={key} className="saas-mob-nav">
                <div className="saas-mob-nav-btn" onClick={() => setActive(key)}>
                  <div className="saas-mob-btn-name">{label}</div>
                  <div className="saas-mob-btn-img">
                    <img src={active === key ? DGVSaasOpenImage : DGVSaasCloseImage} alt="Toggle Button" />
                  </div>
                </div>
                {active === key && <div className="saas-content">{component}</div>}
              </div>
            ))
          )}
        </div>
      </div>
      <ForMoreDetails mailId="saas@dgv.in" />
      <Footer />
    </div>
  );
};

export default DGVSaas;
