import React, { useState, useEffect } from 'react';
import Visit_us_verticle_line from '../../assets/images/visit-cin-ver-line.png';
import Contact_us_hori_line from '../../assets/images/hori-line.png';
import Location_Icon from '../../assets/images/Location_Icon.png';
import visitUs_line from '../../assets/images/visitus-line.svg';
import { OfficeAddress } from '../../utils/constants';
import './VisitUs.css';

const VisitUs = () => {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <>
      <div className="visit-cin-box">
        <div className="visit-cin-tech">
          <div className="visit-us_title">Digivriddhi Technologies Private Limited</div>
          <div className="visit-us_cin">CIN: U74999KA2019PTC130707</div>
        </div>
        <div className="visit-cin-line">
          <img src={mobileView ? Visit_us_verticle_line : Contact_us_hori_line} alt="vertical-line" />
        </div>
        <div className="visit-cin-connect">
          <div className="visit-us_title">DGV Connect Private Limited</div>
          <div className="visit-us_cin">CIN: U62099KA2024PTC185528</div>
        </div>
      </div>
      <div className="visit-us">
        <div className="visit-us-header">
          <div className="visit-us-head">Visit Us</div>
        </div>
        <div className="visit-us-wrapper">
          <div className="offices">
            <div className="office-section">
              <div className="office-type">Corporate Office</div>
              <div className="office-addr corporate">
                J-3030, Akshar Business Park, Plot No-03, Sector 25, Vashi, Navi Mumbai, Maharashtra - 400703
              </div>
              <div className="location-contain">
                <div className="Location-icon">
                  <img src={Location_Icon} alt="location-img" />
                </div>
                <div className="viwe-on-map">
                  <a
                    href={
                      'https://www.google.com/maps/search/3rd+Floor,+J+wing,+Plot+No.+3,+Akshar+Business+Park,+Sector+25,+Vashi,+Navi+Mumbai,+Maharashtra-400703/@19.0790213,73.0109658,17z/data=!3m1!4b1'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    View On Map
                  </a>
                </div>
              </div>
            </div>

            {mobileView && (
              <div className="verticle-line">
                <img src={visitUs_line} alt="visitUs_line" />
              </div>
            )}

            <div className="office-section">
              <div className="office-type">Registered Office</div>
              <div className="office-addr registered">
                No.562, 19th Cross, 1st Block, R.T.Nagar, Bengaluru,<br></br> Karnataka - 560032
              </div>
              <div className="location-contain">
                <div className="Location-icon">
                  <img src={Location_Icon} alt="location-img" />
                </div>
                <div className="viwe-on-map">
                  <a
                    href={
                      'https://www.google.com/maps/place/562,+19th+Cross+Rd,+1st+Cross,+Thimaiah+Garden,+Devara+Jeevanahalli,+Bengaluru,+Karnataka+560032/@13.016671,77.5944796,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae17ad929f0f03:0x87fafb5696e970cc!8m2!3d13.0166658!4d77.5970545!16s%2Fg%2F11lmps0g_6?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    View On Map
                  </a>
                </div>
              </div>
            </div>

            {mobileView && (
              <div className="verticle-line">
                <img src={visitUs_line} alt="visitUs_line" />
              </div>
            )}

            <div className="office-section">
              <div className="office-type">State Head Office</div>
              <div className="office-addr state-head">
                Vasudev Building, Office No 305/306, 3rd Floor, Near Sardar Patel Statue, Vallabh Vidya Nagar, Gujarat-
                388120
              </div>
              <div className="location-contain">
                <div className="Location-icon">
                  <img src={Location_Icon} alt="location-img" />
                </div>
                <div className="viwe-on-map">
                  <a
                    href={
                      'https://www.google.com/maps/search/Vasudev+Building,+Office+No+305%2F306,+3rd+Floor,+Near+Sardar+Patel+Statue,+Vallabh+Vidya+Nagar,+Gujarat-+388120/@22.5458856,72.9273561,17.25z?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D'
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    View On Map
                  </a>
                </div>
              </div>
            </div>

            {/* {OfficeAddress.map((add, i) => {
              return (
                <div key={add.id} className="office-address-info">
                  <div className="Office-header">{add.officeType}</div>
                  <div className={add.className} dangerouslySetInnerHTML={{ __html: add.address }}></div>
                  <div className="location-contain">
                    <div className="Location-icon">
                      <img src={add.locationIcon} alt="location-img" />
                    </div>
                    <div className="viwe-on-map">
                      <a href={add.mapLink} target="_blank" rel="noreferrer">
                        {add.viewOnMap}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })} */}
          </div>
          <div className="Contact_us_hori_line">
            <img src={Contact_us_hori_line} alt="line" />
          </div>
        </div>
      </div>
    </>
  );
};

export default VisitUs;
