import React, { useEffect, useState } from 'react';
import './style/custom-carousel.css';
import VideoButton from '../VideoButton/VideoButton';
import { DialogVideo } from '../DialogVideo/DialogVideo';
import CustomePopup from '../CustomePopup/CustomePopup';
import VideoButtonSmall from '../VideoButton/VideoButtonSmall';

const CarouselItem = ({
  data,
  thumbnail,
  link,
  title,
  showBtn = false,
  handleClickOpenPopup,
  showRedMoreBtn = false,
  backgroundColor,
  targetId,
  handleImageClick,
}) => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  const handleClickOpen = (link) => {
    setActiveLink(link);
    handleClickOpenPopup(link, data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className=""
        style={{
          width: '100%',
          maxHeight: '80%',
          background: backgroundColor,
        }}
      >
        <div className="" onClick={() => handleImageClick(targetId)}>
          <img style={{ aspectRatio: '16/9', borderRadius: '1rem' }} src={thumbnail} alt="gg" />
        </div>
        <div className="image-title">{title}</div>
        {showBtn && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {mobileView ? (
              <VideoButton titles={''} handleClickOpen={() => handleClickOpen(link)}></VideoButton>
            ) : (
              <VideoButtonSmall titles={''} handleClickOpen={() => handleClickOpen(link)}></VideoButtonSmall>
            )}
          </div>
        )}
        {showRedMoreBtn && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {data?.readNowLink ? (
              <div className="read-more-btn">
                <a href={data?.readNowLink} target="_blank">
                  Read Now
                </a>
              </div>
            ) : data?.donloadPDF ? (
              <div className="read-more-btn" onClick={() => window.open(data?.donloadPDF)}>
                Read Now
              </div>
            ) : (
              <div className="read-more-btn" onClick={() => handleImageClick(targetId)}>
                {/* <a href={data?.readNowLink} target="_blank"> */}
                Read Now
                {/* </a> */}
              </div>
            )}
            {/* <VideoButton titles={''} text={'Read More'} handleClickOpen={() => handleClickOpen(link)}></VideoButton> */}
          </div>
        )}
      </div>

      {/* {showBtn && open && <DialogVideo title={title} link={activeLink} handleClose={handleClose} />} */}
    </>
  );
};

export default CarouselItem;
