import React from 'react';
import './DGVDairyBRE.css';
import Dairy_Bre_Image from '../../../../assets/images/dairy-bre-image.png';
import Dairy_Bre_Feature_1 from '../../../../assets/images/dairy-bre-feature-1.png';
import Dairy_Bre_Feature_2 from '../../../../assets/images/dairy-bre-feature-2.png';
import Dairy_Bre_Feature_3 from '../../../../assets/images/dairy-bre-feature-3.png';
import { useInView } from 'react-intersection-observer';

function DGVDairyBRE() {
  const [ref, inView] = useInView({
    threshold: 0.1, // Trigger when 50% of the element is visible
    triggerOnce: true, // Trigger the animation only once
  });
  return (
    <div ref={ref} className="dairy-bre-main-container">
      <div className="dairy-bre-info-section">
        <div className={inView ? 'dairy-bre-title animate__animated animate__fadeIn' : 'dairy-bre-title'}>
          Dairy Business Rule Engine(BRE)
        </div>
        <div
          className={inView ? 'dairy-bre-image animate__animated animate__fadeIn animate__delay-1s' : 'dairy-bre-image'}
        >
          <img src={Dairy_Bre_Image} alt="Dairy_Bre_Image" />
        </div>
      </div>
      <div className="dairy-bre-feature-section">
        <div className="dairy-bre-feature-title">Features</div>
        <div className="dairy-bre-feature-list">
          <div className={inView ? 'dairy-bre-feature animate__animated animate__fadeInUp' : 'dairy-bre-feature'}>
            <img src={Dairy_Bre_Feature_1} alt="Dairy_Bre_Feature_1" />
          </div>
          <div
            className={
              inView ? 'dairy-bre-feature animate__animated animate__fadeInUp animate__delay-1s' : 'dairy-bre-feature'
            }
          >
            <img src={Dairy_Bre_Feature_2} alt="Dairy_Bre_Feature_2" />
          </div>
          <div
            className={
              inView ? 'dairy-bre-feature animate__animated animate__fadeInUp animate__delay-2s' : 'dairy-bre-feature'
            }
          >
            <img src={Dairy_Bre_Feature_3} alt="Dairy_Bre_Feature_3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DGVDairyBRE;
