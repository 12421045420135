import React, { useEffect, useState } from 'react';
import './Component3x2.css';
import Slider from 'react-slick';
import CustomePopup from '../../components/CustomePopup/CustomePopup';

function Component3x2({
  conponentData,
  hideExploreBtn = false,
  isTwoImage = false,
  isFourImage = false,
  istopFour = false,
  className,
  bgcolor = '#fff',
  textAlignment,
  popupData,
}) {
  const [desktopView, setDesktopView] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
  };
  return (
    <div
      className={istopFour ? 'c-3x2-main-container two-image-box' : `c-3x2-main-container ${className}`}
      style={{ backgroundColor: desktopView ? conponentData?.bgColor : bgcolor }}
    >
      {!isFourImage && !isTwoImage && (
        <div className="c-3x2-image-section">
          {desktopView && (
            <div
              className="c-3x2-single"
              onClick={() => {
                handleClickOpen();
              }}
            >
              <img src={conponentData?.images[0]?.imagePath} alt="image" />
            </div>
          )}
          {conponentData?.images?.map((image, index) => {
            return (
              desktopView &&
              index > 0 && (
                <div className="c-3x2-multiple">
                  <div
                    className="image-box"
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <img src={image?.imagePath} alt="image" />
                  </div>
                </div>
              )
            );
          })}
          {/* for mobile view */}
          {!desktopView && (
            <Slider {...settings}>
              {conponentData?.images?.map((image, index) => {
                return (
                  <div className="c-3x2-multiple">
                    <div
                      className="image-box"
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      <img src={image?.imagePath} alt="image" />
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      )}
      {isTwoImage && (
        <div className="c-3x2-two-image-section">
          {conponentData?.images?.map((image, index) => {
            return (
              <div className="c-3x2-two-multiple">
                <div
                  className="image-two-box"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  <img src={image?.imagePath} alt="image" />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {isFourImage && (
        <div className="c-3x2-four-image-section">
          {conponentData?.images?.map((image, index) => {
            return (
              <div className="c-3x2-four-multiple">
                <div
                  className="image-four-box"
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  <img src={image?.imagePath} alt="image" />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="c-3x2-info-section">
        <div className={'c-3x2-disc'} style={{ textAlign: textAlignment }}>
          {conponentData?.disc}
        </div>
        {desktopView && <div className={isFourImage ? 'c-3x2-four-title' : 'c-3x2-title'}>{conponentData?.title}</div>}
        {!hideExploreBtn && (
          <div
            className="c-3x2-expore-btn"
            onClick={() => {
              handleClickOpen();
            }}
          >
            Explore More
          </div>
        )}
      </div>
      {open && <CustomePopup onClose={handleClose} popupData={popupData} isVisible={open} IsVideo={false} />}
    </div>
  );
}

export default Component3x2;
