import React, { useEffect, useState } from 'react';
// import { OUR_NETWORK, NETWORK_SLIDER_IMAGES } from '../../utils/constants';
// import StrategicPartners from '../../components/StratergicPartners/StrategicPartners';
// import DataPresence from '../../components/DataPresence/DataPresence';
import CardOverview from '../../components/DairyOverview/Overview';
// import ProductInfoPlot from '../../components/ProductInfoPlot/ProductInfoPlot';
import deskLine from '../../assets/images/ourNetworkLine.svg';

import ournetworkLinemob from '../../assets/images/ournetworkLinemob.svg';

import bankingPartner_1 from '../../assets/images/bankingPartner-1.png';
import bankingPartner_2 from '../../assets/images/bankingPartner-2.png';
import bankingPartner_3 from '../../assets/images/bankingPartner-3.png';
import bankingPartner_4 from '../../assets/images/bankingPartner-4.png';
import bankingPartner_5 from '../../assets/images/bankingPartner-5.png';

import dairyPartner_1 from '../../assets/images/dairy_logo-1.png';
import dairyPartner_2 from '../../assets/images/dairy_logo-2.png';
import dairyPartner_3 from '../../assets/images/dairy_logo-3.png';
import dairyPartner_4 from '../../assets/images/dairy_logo-4.png';
import dairyPartner_5 from '../../assets/images/NDDB-logo.png';
import dairyPartner_6 from '../../assets/images/Paayas-logo.png';
import dairyPartner_7 from '../../assets/images/Harit_Pradesh-logo.png';
import dairyPartner_8 from '../../assets/images/Maalav-logo.png';
import dairyPartner_9 from '../../assets/images/Akshayakalpa-logo.png';
import dairyPartner_10 from '../../assets/images/Osam-logo.png';
import mapIncon_1 from '../../assets/images/map-info-logo-1.png';
import mapIncon_2 from '../../assets/images/map-info-logo-2.png';
import mapIncon_3 from '../../assets/images/map-info-logo-3.png';
import GujratStateMap from '../../assets/images/GujratStateMap.png';
import AP_StateMap from '../../assets/images/AP-StateMap.png';
import MP_StateMap from '../../assets/images/MP-StateMap.png';
import Karnataka_StateMap from '../../assets/images/Karnataka-StateMap.svg';
// import Karnataka_StateMap from '../../assets/images/Karnataka-StateMap.png';
import Telangana_StateMap from '../../assets/images/Telangana-StateMap.png';

import './OurNetwork.css';
import StateMap from './MapCustom/StateMap';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from '../../utils/api';

const OurNetwork = () => {
  const [desktopView, setDesktopView] = useState(false);
  const [networkData, setNetworkData] = useState({
    data: {
      ourPresence: [
        {
          id: 13,
          stateName: 'Gujarat',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '7.03 Crores',
          listedCattleCount: '3800+',
          noOfFarmers: 100000,
          districtCount: 28,
          msmeCount: 1000,
          isActive: true,
          status: 'Active',
        },
        {
          id: 14,
          stateName: 'Maharashtra',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '7.03 Crores',
          listedCattleCount: '3800+',
          noOfFarmers: 100000,
          districtCount: 12,
          msmeCount: 500,
          isActive: false,
          status: 'Q3',
        },
        {
          id: 17,
          stateName: 'Rajasthan',
          noOfSociety: '1000+',
          dairyUnionCount: '20',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 100000,
          districtCount: 2,
          msmeCount: 1000,
          isActive: false,
          status: 'Q4',
        },
        {
          id: 18,
          stateName: 'Madya Pradesh',
          noOfSociety: '1000+',
          dairyUnionCount: '20',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 100000,
          districtCount: 2,
          msmeCount: 1000,
          isActive: true,
          status: 'Active',
        },
        {
          id: 19,
          stateName: 'karnataka',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 10000,
          districtCount: 10,
          msmeCount: 1212,
          isActive: true,
          status: 'Active',
        },
        {
          id: 20,
          stateName: 'Tamilnadu',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 10000,
          districtCount: 10,
          msmeCount: 1212,
          isActive: false,
          status: 'Q4',
        },
        {
          id: 21,
          stateName: 'Andra Pradesh',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 10000,
          districtCount: 10,
          msmeCount: 1212,
          isActive: false,
          status: 'Q3',
        },
        {
          id: 22,
          stateName: 'Telangana',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 10000,
          districtCount: 10,
          msmeCount: 1212,
          isActive: false,
          status: 'Q3',
        },
        {
          id: 23,
          stateName: 'Uttar Pradesh',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 10000,
          districtCount: 10,
          msmeCount: 1212,
          isActive: false,
          status: 'Q4',
        },
        {
          id: 24,
          stateName: 'Jharkhand',
          noOfSociety: '1000+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '5.5 Crores',
          listedCattleCount: '2550+',
          noOfFarmers: 10000,
          districtCount: 10,
          msmeCount: 1212,
          isActive: false,
          status: 'Q4',
        },
        {
          id: 25,
          stateName: 'Bihar',
          noOfSociety: '1100+',
          dairyUnionCount: '14',
          totalDairyUnion: '18',
          loanDisbursedAmount: '6.7 Crores',
          listedCattleCount: '3900+',
          noOfFarmers: 100000,
          districtCount: 25,
          msmeCount: 1050,
          isActive: false,
          status: 'Q4',
        },
        {
          id: 26,
          stateName: 'Uttarakhand',
          noOfSociety: '700+',
          dairyUnionCount: '6',
          totalDairyUnion: '10',
          loanDisbursedAmount: '3.5 Crores',
          listedCattleCount: '2200+',
          noOfFarmers: 60000,
          districtCount: 13,
          msmeCount: 500,
          isActive: false,
          status: 'Q4',
        },
      ],
      numberOfStates: '12',
      totalMsmes: '3212',
      totalNumberOfFarmers: '210000',
    },
    message: 'Successful',
    status: 'OK',
  });
  // const [networkData, setNetworkData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();
  const activeState = searchParams.get('activeState');
  const [activeStateData, setActiveStateData] = useState(null);

  useEffect(() => {
    if (activeState) {
      const stateData = networkData?.ourPresence?.find(
        (state) => state?.stateName?.toLowerCase() === activeState?.toLowerCase()
      );
      if (stateData) {
        setActiveStateData({
          msmeCount: stateData?.msmeCount,
          noOfFarmers: stateData?.noOfFarmers,
          districtCount: stateData?.districtCount,
          activeState: stateData?.stateName,
        });
      }
    }
  }, [activeState, networkData]);

  useEffect(() => {
    // Fetch data from the API
    const fetchNetworkData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${BASE_URL}ourNetworkGet`);

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setNetworkData(data.data); // Update state with API data
      } catch (err) {
        setError(err.message); // Update state with error
      } finally {
        setLoading(false); // Turn off loading
      }
    };

    fetchNetworkData();
  }, []);
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setDesktopView(true);
    }
  }, [desktopView]);
  // const stateNames = networkData?.ourPresence?.map((state) => state.stateName);
  const stateDetails = networkData?.ourPresence?.map((state) => ({
    stateName: state?.stateName.toLowerCase(),
    isActive: state?.isActive,
    status: state?.status,
  }));
  const stateMaps = {
    gujarat: GujratStateMap,
    'Madhya Pradesh': MP_StateMap,
    'Andhra Pradesh': AP_StateMap,
    Karnataka: Karnataka_StateMap,
    Telangana: Telangana_StateMap,
  };
  const normalizeStateName = (state, validStates) => {
    const normalizedState = validStates?.find((key) => key?.toLowerCase() === state?.toLowerCase());
    return normalizedState || null;
  };
  const normalizedState = normalizeStateName(activeState, Object.keys(stateMaps));

  return (
    <div className="network-main-container">
      <div className="network-map-section">
        <div className="map-title">Our Presence</div>
        <div className="map-info-wrapper">
          <div className={activeState ? 'map-box' : 'country-map-box'}>
            {activeState ? (
              <div className={`state-map ${activeState}`}>
                {/* <img src={GujratStateMap} alt="state image" /> */}
                <img
                  src={stateMaps[normalizedState]} // Fallback to Gujarat if the state is not found
                  alt={`${activeState} map`}
                />
              </div>
            ) : (
              <StateMap activestateByApi={stateDetails} />
            )}
            {/* <StateMap activestateByApi={networkData?.stateName || stateNames} /> */}
          </div>
          <div className={activeState ? 'state-map-info' : 'map-info'}>
            <div className="info-box">
              <div className="info-logo">
                <img src={mapIncon_1} alt="mapIncon_1" />
              </div>
              <div className="info-name">No. of {activeState ? 'Districts' : 'States'}</div>
              <div className="info-count">
                {activeState ? activeStateData?.districtCount : networkData?.numberOfStates}
              </div>
            </div>
            <div className="info-box">
              <div className="info-logo">
                <img src={mapIncon_2} alt="mapIncon_1" />
              </div>
              <div className="info-name">Dairy Msme’s</div>
              <div className="info-count">{activeState ? activeStateData?.msmeCount : networkData?.totalMsmes}</div>
              {/* <div className="info-count">{networkData?.listedCattleCount ? networkData?.listedCattleCount : '--'}</div> */}
            </div>
            <div className="info-box">
              <div className="info-logo">
                <img src={mapIncon_3} alt="mapIncon_1" />
              </div>
              <div className="info-name">Dairy Farmers across DGV Platform </div>
              <div className="info-count">
                {activeState ? activeStateData?.noOfFarmers : networkData?.totalNumberOfFarmers}
              </div>
              {/* <div className="info-count">{networkData?.noOfFarmers ? networkData?.noOfFarmers : '--'}</div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="our-network-line">
        <img src={desktopView ? deskLine : ournetworkLinemob} alt="line" />
      </div>
      <div className="partners-components">
        <div className="banking-partners">
          <div className="partner-type-title">Banking Partners</div>
          <div className="partner-type-sub-title">Backed by cutting edge innovators</div>
          <div className="partners-logo">
            <div className="log-box">
              <img src={bankingPartner_1} alt="bankingPartner_1" />
            </div>
            <div className="log-box">
              <img src={bankingPartner_2} alt="bankingPartner_1" />
            </div>
            <div className="log-box">
              <img src={bankingPartner_3} alt="bankingPartner_1" />
            </div>
            <div className="log-box">
              <img src={bankingPartner_4} alt="bankingPartner_1" />
            </div>
            <div className="log-box">
              <img src={bankingPartner_5} alt="bankingPartner_1" />
            </div>
          </div>
        </div>
        <div className="our-network-line">
          <img src={desktopView ? deskLine : ournetworkLinemob} alt="line" />
        </div>
        <div className="dairy-partners">
          <div className="partner-type-title">Dairy Partners </div>
          <div className="partner-type-sub-title">Collaborating with leading Dairy Brands</div>
          {desktopView ? (
            <div className="partners-logo">
              <div className="logo-section">
                <div className="log-box">
                  <img src={dairyPartner_1} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_5} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_6} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_7} alt="dairyPartner_1" />
                </div>
              </div>
              <div className="logo-section">
                <div className="log-box">
                  <img src={dairyPartner_3} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_8} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_4} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_9} alt="dairyPartner_1" />
                </div>
              </div>
              <div className="logo-section">
                <div className="log-box">
                  <img src={dairyPartner_2} alt="dairyPartner_1" />
                </div>
                <div className="log-box">
                  <img src={dairyPartner_10} alt="dairyPartner_1" />
                </div>
              </div>
            </div>
          ) : (
            <div className="partners-logo">
              <div className="log-box">
                <img src={dairyPartner_1} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_5} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_6} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_7} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_3} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_8} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_4} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_9} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_2} alt="dairyPartner_1" />
              </div>
              <div className="log-box">
                <img src={dairyPartner_10} alt="dairyPartner_1" />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="CardOverview-section">
        <CardOverview />
      </div>
    </div>
  );
};

export default OurNetwork;
