import React from 'react';
import './MediaEventsDesk.css';
import Component4x2 from '../../LifeAtDGVInside/Component4x2';
import {
  mahakumbh_24,
  distributionMeet_24,
  ConferenceKochi_24,
  forbesTop100DGV,
  omidyarFundingDGV,
  rbiGovernorKPlecture,
  gff2023Showcase,
  dgvServiceGujarat,
  investmentInDGV,
  dgvSeriesAFunding,
  digitalBovineLoanJourneyRBI,
  digitalDairyLoansAMCSIntegration,
  digivriddhiSeriesAFundUsage,
  omidyarSeriesAFunding,
  idaDairyIndustryConference2023,
  nasscomIndiaFintechAward2023,
  dgvFirstInstitutionalFunding,
  rajkotDairyCashDoorstep,
  dgvPlatformLaunchSarhadDairy,
  dgvAmulMicroATMLaunchSarvottamDairy,
  ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022,
  innovationDairyExpo2022Day2,
  IIDE2022DigivriddhiNeoBanking,
  AmulMicroATMDigitalPayments,
  DGVAmulMicroATMSursagarLimbdi,
  DubaiExpo2022,
  UnionMinisterRupalaDGV,
} from '../../MediaEvents/MediaeventsData';
import WithWatchBtnComponent from '../../PartnerSpeaks/PartnerSpeaksComponents/WithWatchBtnComponent';

function MediaEventsDesk() {
  return (
    <div className="events-desktop-container">
      {/* <Component4x2
        sliderImagesData={mahakumbh_24?.silderImages}
        title={mahakumbh_24?.title}
        popupData={mahakumbh_24?.popupData}
      /> */}
      <div className="container-2x1">
        <div className="container-60">
          <Component4x2
            sliderImagesData={mahakumbh_24?.silderImages}
            title={mahakumbh_24?.title}
            popupData={mahakumbh_24?.popupData}
          />
          {/* <Component4x2
            sliderImagesData={distributionMeet_24?.silderImages}
            title={distributionMeet_24?.title}
            popupData={distributionMeet_24?.popupData}
          /> */}
          <Component4x2
            sliderImagesData={ConferenceKochi_24?.silderImages}
            title={ConferenceKochi_24?.title}
            popupData={ConferenceKochi_24?.popupData}
          />
        </div>
        <div className="container-40">
          <WithWatchBtnComponent data={forbesTop100DGV} />
        </div>
      </div>
      <div className="container-2x1">
        <div className="container-40">
          <WithWatchBtnComponent data={omidyarFundingDGV} />
        </div>
        <div className="container-60">
          <Component4x2
            targetId={'mediaEvents-1'}
            data={gff2023Showcase}
            sliderImagesData={gff2023Showcase?.silderImages}
            title={gff2023Showcase?.title}
            popupData={gff2023Showcase?.popupData}
          />
          <Component4x2
            data={rbiGovernorKPlecture}
            sliderImagesData={rbiGovernorKPlecture?.silderImages}
            title={rbiGovernorKPlecture?.title}
            popupData={rbiGovernorKPlecture?.popupData}
          />
        </div>
      </div>
      <div className="container-1x1">
        <WithWatchBtnComponent data={investmentInDGV} />
        <WithWatchBtnComponent data={dgvSeriesAFunding} />
        <WithWatchBtnComponent data={dgvServiceGujarat} />
      </div>
      <Component4x2
        sliderImagesData={digitalBovineLoanJourneyRBI?.silderImages}
        title={digitalBovineLoanJourneyRBI?.title}
        popupData={digitalBovineLoanJourneyRBI?.popupData}
      />
      <div className="container-1x1">
        <WithWatchBtnComponent data={omidyarSeriesAFunding} />
        <WithWatchBtnComponent data={digivriddhiSeriesAFundUsage} />
        <WithWatchBtnComponent data={digitalDairyLoansAMCSIntegration} />
      </div>
      <div className="container-2x1">
        <div className="container-60">
          <Component4x2
            sliderImagesData={idaDairyIndustryConference2023?.silderImages}
            title={idaDairyIndustryConference2023?.title}
            popupData={idaDairyIndustryConference2023?.popupData}
          />
          <Component4x2
            sliderImagesData={nasscomIndiaFintechAward2023?.silderImages}
            title={nasscomIndiaFintechAward2023?.title}
            popupData={nasscomIndiaFintechAward2023?.popupData}
          />
        </div>
        <div className="container-40">
          <WithWatchBtnComponent data={dgvFirstInstitutionalFunding} />
        </div>
      </div>
      <div className="container-2x1">
        <div className="container-40">
          <WithWatchBtnComponent data={rajkotDairyCashDoorstep} />
        </div>
        <div className="container-60">
          <Component4x2
            data={dgvPlatformLaunchSarhadDairy}
            sliderImagesData={dgvPlatformLaunchSarhadDairy?.silderImages}
            title={dgvPlatformLaunchSarhadDairy?.title}
            popupData={dgvPlatformLaunchSarhadDairy?.popupData}
          />
          <Component4x2
            data={dgvAmulMicroATMLaunchSarvottamDairy}
            sliderImagesData={dgvAmulMicroATMLaunchSarvottamDairy?.silderImages}
            title={dgvAmulMicroATMLaunchSarvottamDairy?.title}
            popupData={dgvAmulMicroATMLaunchSarvottamDairy?.popupData}
          />
        </div>
      </div>
      <Component4x2
        sliderImagesData={ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022?.silderImages}
        title={ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022?.title}
        popupData={ragavanVenkatesanSpeechIndiaInternationalDairyExpo2022?.popupData}
      />
      <div className="container-1x1">
        <WithWatchBtnComponent data={innovationDairyExpo2022Day2} />
        <WithWatchBtnComponent data={IIDE2022DigivriddhiNeoBanking} />
        <WithWatchBtnComponent data={AmulMicroATMDigitalPayments} />
      </div>
      <Component4x2
        sliderImagesData={DGVAmulMicroATMSursagarLimbdi?.silderImages}
        title={DGVAmulMicroATMSursagarLimbdi?.title}
        popupData={DGVAmulMicroATMSursagarLimbdi?.popupData}
      />
      <Component4x2
        sliderImagesData={DubaiExpo2022?.silderImages}
        title={DubaiExpo2022?.title}
        popupData={DubaiExpo2022?.popupData}
      />
      <Component4x2
        data={UnionMinisterRupalaDGV}
        sliderImagesData={UnionMinisterRupalaDGV?.silderImages}
        title={UnionMinisterRupalaDGV?.title}
        popupData={UnionMinisterRupalaDGV?.popupData}
      />
    </div>
  );
}

export default MediaEventsDesk;
