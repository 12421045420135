import React, { useState, useEffect } from 'react';
import './DGVGroup.css';
import connectBg from '../../assets/images/Group-BG.png';
import connectBgMob from '../../assets/images/DGV-Group-Mob.png';
import GroupConnectIcon from '../../assets/images/Group-connect-icon.png';
import GroupConnectIconMob from '../../assets/images/Group-connect.png';
import GroupTechnologyIcon from '../../assets/images/Group-technology-icon.png';
import GroupTechnologyIconMob from '../../assets/images/Group-technology.png';
import GroupInsuranceIcon from '../../assets/images/Group-insurance-icon.png';
import GroupInsuranceIconMob from '../../assets/images/Group-insurance.png';
import GroupTitleImage from '../../assets/images/group-title-img.png';
import GroupTitleImageMob from '../../assets/images/DGV-Group-text-mob.png';
import GroupHistory2019 from '../../assets/images/GroupHistory2019-new.png';
import GroupHistory2020 from '../../assets/images/GroupHistory2020-new.png';
import GroupHistory2021_New from '../../assets/images/GroupHistory2021-new.png';
import GroupHistory2022 from '../../assets/images/GroupHistory2022-new.png';
import GroupHistory2022_2 from '../../assets/images/GroupHistory2022-2-new.png';
import GroupHistory2023 from '../../assets/images/GroupHistory2023-new.png';
import GroupHistory2023_2 from '../../assets/images/GroupHistory2023-2-new.png';
import GroupHistory2024 from '../../assets/images/GroupHistory2024-new.png';
import GroupHistory2024_2 from '../../assets/images/GroupHistory2024-2-new.png';
import DGV_group_Roadmap_Mob from '../../assets/images/DGV-group-Roadmap-Mob.svg';
import { ROUTES_PATH } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

const DGVGroup = () => {
  const [mobileView, setMobileView] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });
  useEffect(() => {
    if (window.innerWidth >= 1112) {
      setMobileView(true);
    }
  }, [mobileView]);
  return (
    <div className="main-div desktop-view">
      <div className={mobileView && 'page-container page-content'}>
        {mobileView ? (
          <div className="group-bg-box animate__animated animate__fadeIn">
            <img src={connectBg} alt="connect-bg" />
            <div className="group-title animate__animated animate__pulse">
              <img src={mobileView ? GroupTitleImage : GroupTitleImageMob} alt="group-title" />
            </div>
            <div className="group-box-wrapper">
              <div className="group-info-box animate__animated animate__fadeInLeft">
                <img src={mobileView ? GroupConnectIcon : GroupConnectIconMob} alt="group-icon-desk" />
                <Link to={ROUTES_PATH?.CONNECT}>Pvt Ltd.</Link>
                <div className="group-year">2024</div>
              </div>
              <div className="group-info-box dgv-riddhi-img animate__animated animate__fadeInDown">
                <img src={GroupTechnologyIcon} alt="technogies-icon-desk" />
                <Link to={ROUTES_PATH?.HOME}>
                  Technologies <br /> Pvt Ltd.
                </Link>
                <div className="group-year">Since 2019</div>
              </div>
              <div className="group-info-box insurance-img animate__animated animate__fadeInRight">
                <img src={GroupInsuranceIcon} alt="insurance-icon-desk" />
                <Link to={ROUTES_PATH?.INSURANCE}>Pvt Ltd.</Link>
                <div className="group-year">2024</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="group-bg-box animate__animated animate__fadeIn">
            <img src={connectBgMob} alt="connect-bg" />
            <div className="group-title animate__animated animate__pulse">
              <img src={GroupTitleImageMob} alt="group-title" />
            </div>
            <div className="group-box-wrapper">
              <div className="group-info-box animate__animated animate__fadeInLeft">
                <Link to={ROUTES_PATH?.CONNECT}>
                  <img src={mobileView ? GroupConnectIcon : GroupConnectIconMob} alt="group-icon-desk" />
                </Link>
                {/* <Link to={ROUTES_PATH?.CONNECT}>Pvt Ltd.</Link>
                <div className="group-year">2024</div> */}
              </div>
              <div className="group-info-box dgv-riddhi-img animate__animated animate__fadeInDown">
                <Link to={ROUTES_PATH?.HOME}>
                  <img src={GroupTechnologyIconMob} alt="technogies-icon-desk" />
                </Link>
                {/* <Link to={ROUTES_PATH?.HOME}>
                  Technologies <br /> Pvt Ltd.
                </Link>
                <div className="group-year">Since 2019</div> */}
              </div>
              <div className="group-info-box insurance-img animate__animated animate__fadeInRight">
                <Link to={ROUTES_PATH?.INSURANCE}>
                  <img src={GroupInsuranceIconMob} alt="insurance-icon-desk" />
                </Link>
                {/* <Link to={ROUTES_PATH?.INSURANCE}>Pvt Ltd.</Link>
                <div className="group-year">2024</div> */}
              </div>
            </div>
          </div>
        )}

        {mobileView ? (
          <div ref={ref} className="group-history-container">
            <div className="group-history-img-top-wrapper">
              <div
                className={
                  inView ? 'group-history-img-top animate__animated animate__fadeInDown' : 'group-history-img-top'
                }
              >
                <img src={GroupHistory2019} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-top animate__animated animate__fadeInDown' : 'group-history-img-top'
                }
              >
                <img src={GroupHistory2021_New} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-top animate__animated animate__fadeInDown' : 'group-history-img-top'
                }
              >
                <img src={GroupHistory2022} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-top animate__animated animate__fadeInDown' : 'group-history-img-top'
                }
              >
                <img src={GroupHistory2023} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-top animate__animated animate__fadeInDown' : 'group-history-img-top'
                }
              >
                <img src={GroupHistory2024} alt="2019-image" />
              </div>
            </div>
            <div className={inView ? 'Group-year-list animate__animated animate__fadeIn' : 'Group-year-list'}>
              <div className="list-box">2019</div>
              <div className="list-box">2020</div>
              <div className="list-box">2021</div>
              <div className="list-box">2022</div>
              <div className="list-box">2023</div>
              <div className="list-box">2024</div>
            </div>
            <div className="group-history-img-bottom-wrapper">
              <div
                className={
                  inView ? 'group-history-img-bottom animate__animated animate__fadeInUp' : 'group-history-img-bottom'
                }
              >
                <img src={GroupHistory2020} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-bottom animate__animated animate__fadeInUp' : 'group-history-img-bottom'
                }
              >
                <img src={GroupHistory2022_2} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-bottom animate__animated animate__fadeInUp' : 'group-history-img-bottom'
                }
              >
                <img src={GroupHistory2023_2} alt="2019-image" />
              </div>
              <div
                className={
                  inView ? 'group-history-img-bottom animate__animated animate__fadeInUp' : 'group-history-img-bottom'
                }
              >
                <img src={GroupHistory2024_2} alt="2019-image" />
              </div>
            </div>
          </div>
        ) : (
          <div className="group-history-container">
            <div ref={ref} className={inView ? 'dgv-roadmap-mob animate__animated animate__fadeIn' : 'dgv-roadmap-mob'}>
              <img src={DGV_group_Roadmap_Mob} alt="DGV_group_Roadmap_Mob" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DGVGroup;
